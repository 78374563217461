import React, { Dispatch, PropsWithChildren, ReactNode, SetStateAction, useMemo } from 'react';
import {
  DefaultPageProps as Props,
  MagnoliaNode,
  MagnoliaSite,
  MagnoliaVideos,
  Props as PageProps
} from '@/types/cms/magnolia';
import { ApiProductsCacheData, ApiProductsCacheResponse, ApiProductWithReviews } from '@ibe/api';
import { ApiProduct, ApiVideoContent } from '../../api/model';
import Keys from '@/Translations/generated/da/product.json.keys';
import Introduction from '@/components/introduction/Introduction';
import AccommodationList from '@/components/accommodations/AccommodationList';
import { PRODUCT_AVAILABILITY_STATE, PRODUCT_SORT_ORDER, THEMES } from '@/Util/globals';
import SingleHotel from '@/components/singleHotel/SingleHotel';
import Excursions from '@/components/excursions/Excursions';
import ImageGallery from '@/components/imageGallery/ImageGallery';
import VideoGallery from '@/components/videoGallery/VideoGallery';
import ExtensionTeaser from '@/components/extensions/ExtensionTeaser';
import Itinerary from '@/components/itinerary/Itinerary';
import ContentModuleDynamic from '@/components/contentModule/ContentModuleDynamic';
import ProductsDatesPrices from '@/components/ProductsDatesPrices/ProductsDatesPrices';
import TravelInformation from '@/components/travelInformation/TravelInformation';
import { ContentNavigationType } from '@/components/contentNavigation/ContentNavigation';
import { DEPARTURE_DATES_ID } from '@/templates/ProductPageInner';
import PriceInformation from '@/components/priceInformation/PriceInformation';
import { TFunction } from 'i18next';
import { AspectRatio } from '@/Util/imageLoader';

export type ProductPageInnerProps = PropsWithChildren<
  Props & {
    text: string;
    tel: string;
    mail: string;
    themeContactInformation: string;
    showContactBar: boolean;
    checkoutPageNode: MagnoliaNode;
    productPackages: ApiProductsCacheResponse[];
    product?: ApiProduct;
    originalProduct?: ApiProduct;
    siteConfig?: MagnoliaSite;
    isVariant?: boolean;
    selectedProductPackage?: ApiProductsCacheResponse;
    reviews?: ApiProductWithReviews;
    reviewsComponent: ReactNode;
    pageProps?: PageProps;
    cheapestCacheProduct?: ApiProductsCacheData;
    productsAvailabilityState: PRODUCT_AVAILABILITY_STATE;
  }
>;

const getProductPageInnerElements = (
  productPackages: ApiProductsCacheResponse[] = [],
  checkoutPageUrl: string,
  t: TFunction,
  setIsLoading: Dispatch<SetStateAction<boolean>>,
  reviewsComponent: ReactNode,
  productsAvailabilityState: PRODUCT_AVAILABILITY_STATE,
  isVariant?: boolean,
  product?: ApiProduct,
  originalProduct?: ApiProduct,
  siteConfig?: MagnoliaSite,
  selectedPackage?: ApiProductsCacheResponse,
  reviews?: ApiProductWithReviews,
  pageProps?: PageProps,
  cheapestCacheProduct?: ApiProductsCacheData
): ContentNavigationType => {
  const {
    imageGallery,
    productVideos,
    themeImageGallery,
    introductionTitle,
    newProduct,
    tripDescription,
    map,
    aspectRatio,
    highlights,
    itinerary,
    priceInformation,
    productInformation,
    informationGroup,
    visum,
    vaccination,
    currency,
    extensions,
    singleAccommodations,
    multipleAccommodations,
    factSheet,
    travelTypes,
    excursions,
    themeExtensionTeaser,
    themeHotel,
    themeAccommodation,
    themeContentArea,
    themeExcursions,
    themeItinerary,
    themeTravelInformation,
    themePriceInformation,
    themeIntroduction,
    themeHighlights,
    themeDatesAndPrices,
    sortOrder,
    reviewsCode,
    childPrice,
    roomUpgrade,
    priceInfoExcursions,
    priceIncludes,
    priceDoesNotInclude
  } = product || {};
  let scrollY = 0;

  const onClick = () => {
    scrollY = window.scrollY;
    scrollTo(0, 0);
    setTimeout(function () {
      window.print();
    }, 1500);
    scrollTo(0, scrollY);
  };

  return [
    !!introductionTitle ||
    !!tripDescription ||
    !!map ||
    (highlights && highlights?.length > 0) ||
    !!factSheet?.language ||
    !!factSheet?.transportation ||
    !!factSheet?.startingLocation
      ? {
          title: t(Keys.introduction),
          id: 'introduction',
          section: (
            <Introduction
              cacheProduct={selectedPackage?.products?.[0]}
              newProduct={newProduct}
              theme={themeIntroduction}
              themeHighlights={themeHighlights}
              factSheet={factSheet}
              map={map}
              tripDescription={tripDescription}
              introductionTitle={introductionTitle}
              highlights={highlights}
              departureDatesAnchor={DEPARTURE_DATES_ID}
              aspectRatio={aspectRatio as AspectRatio}
              reviews={reviews}
              themeReviews={product?.themeReviews}
              image={imageGallery?.[0]}
              productsAvailabilityState={productsAvailabilityState}
              pageProps={pageProps}
            />
          )
        }
      : null,
    !!singleAccommodations &&
    singleAccommodations.length > 0 &&
    sortOrder === PRODUCT_SORT_ORDER.accommodation
      ? {
          id: 'accommodation',
          hideInNavbar: true,
          section: (
            <div className="singleHotel">
              <div className={`singleHotel__background ${themeHotel || THEMES.sand}`} />
              <SingleHotel
                accommodations={singleAccommodations}
                theme={themeHotel || THEMES.sand}
                pageProps={pageProps}
              />
            </div>
          )
        }
      : null,
    !!multipleAccommodations &&
    multipleAccommodations.length > 0 &&
    sortOrder === PRODUCT_SORT_ORDER.accommodation
      ? {
          id: 'accommodations',
          hideInNavbar: true,
          section: (
            <AccommodationList
              accommodations={multipleAccommodations}
              theme={themeAccommodation}
              pageProps={pageProps}
            />
          )
        }
      : null,
    !!imageGallery && imageGallery?.length > 0
      ? {
          title: t(Keys.images),
          id: 'images',
          section: (
            <ImageGallery
              images={imageGallery}
              theme={themeImageGallery || THEMES.darkGreen}
              headline={t(Keys.gallery)}
              h2Headline
              pageProps={pageProps}
            />
          )
        }
      : null,
    itinerary && itinerary?.length > 0
      ? {
          title: t(Keys.itinerary),
          id: 'itinerary',
          section: (
            <Itinerary
              itinerary={itinerary}
              theme={themeItinerary || THEMES.mediumGreen}
              onClick={() => onClick()}
              product={product}
              pageProps={pageProps}
            />
          )
        }
      : null,
    productsAvailabilityState !== PRODUCT_AVAILABILITY_STATE.pastTripNoFutureAvail
      ? {
          title: t(Keys.departureDates),
          id: DEPARTURE_DATES_ID,
          section: (
            <ProductsDatesPrices
              productPackages={productPackages}
              selectedPackage={selectedPackage}
              checkoutPageUrl={checkoutPageUrl}
              siteConfig={siteConfig}
              isVariant={isVariant}
              product={product}
              originalProduct={originalProduct}
              setIsLoading={setIsLoading}
              theme={themeDatesAndPrices}
            />
          )
        }
      : null,
    (!!priceInformation && priceInformation?.length > 0) ||
    !!cheapestCacheProduct ||
    !!childPrice ||
    !!roomUpgrade ||
    !!priceInfoExcursions ||
    !!priceIncludes ||
    !!priceDoesNotInclude
      ? {
          title: t(Keys.priceInformation),
          id: 'price-information',
          section: (
            <PriceInformation
              priceInformation={priceInformation}
              theme={themePriceInformation || THEMES.sand}
              headline={t(Keys.priceInformationHeadline)}
              product={product}
              selectedProduct={cheapestCacheProduct}
            />
          )
        }
      : null,
    !!singleAccommodations &&
    singleAccommodations.length > 0 &&
    sortOrder === PRODUCT_SORT_ORDER.default
      ? {
          id: 'accommodation',
          hideInNavbar: true,
          section: (
            <div className="singleHotel">
              <div className={`singleHotel__background ${themeHotel || THEMES.sand}`} />
              <SingleHotel
                accommodations={singleAccommodations}
                theme={themeHotel || THEMES.sand}
                pageProps={pageProps}
              />
            </div>
          )
        }
      : null,
    !!multipleAccommodations &&
    multipleAccommodations.length > 0 &&
    sortOrder === PRODUCT_SORT_ORDER.default
      ? {
          id: 'accommodations',
          hideInNavbar: true,
          section: (
            <AccommodationList
              accommodations={multipleAccommodations}
              theme={themeAccommodation}
              pageProps={pageProps}
            />
          )
        }
      : null,
    productInformation &&
    productInformation.contentAreas &&
    productInformation.contentAreas?.length > 0
      ? {
          id: 'contentArea',
          hideInNavbar: true,
          section: (
            <ContentModuleDynamic
              theme={themeContentArea || THEMES.blue}
              contentRow={productInformation.contentAreas}
              pageProps={pageProps}
            />
          )
        }
      : null,
    !!excursions && excursions.length > 0
      ? {
          id: 'excursions',
          hideInNavbar: true,
          section: (
            <Excursions excursions={excursions} theme={themeExcursions} pageProps={pageProps} />
          )
        }
      : null,
    !!extensions && extensions.length > 0
      ? {
          title: t(Keys.extensionTeaser),
          id: 'extensionTeaser',
          section: (
            <ExtensionTeaser
              extensionTeaser={extensions}
              theme={themeExtensionTeaser || THEMES.darkGreen}
              pageProps={pageProps}
            />
          )
        }
      : null,
    sortOrder === PRODUCT_SORT_ORDER.accommodation && reviewsCode
      ? { title: '', id: 'reviews', section: reviewsComponent, hideInNavbar: true }
      : null,
    {
      title: t(Keys.travelInformation),
      id: 'travel-information',
      section: (
        <TravelInformation
          factSheet={factSheet}
          informationGroup={informationGroup}
          visum={visum}
          vaccination={vaccination}
          currency={currency}
          theme={themeTravelInformation}
          travelType={travelTypes}
        />
      )
    },
    sortOrder === PRODUCT_SORT_ORDER.default && !!reviewsCode
      ? { title: '', id: 'reviews', section: reviewsComponent, hideInNavbar: true }
      : null,
    !!productVideos && productVideos?.length > 0
      ? {
          id: 'videos',
          hideInNavbar: true,
          section: <VideoGallery videos={productVideos as MagnoliaVideos | ApiVideoContent[]} />
        }
      : null
  ].filter(Boolean) as ContentNavigationType;
};

export default getProductPageInnerElements;
