import { ApiProductsCacheResponse } from '@ibe/api';
import dayjs, { Dayjs } from 'dayjs';
import { VARIANT_QUERY_PROP, VARIANT_QUERY_PROP_DELIMITER } from '@/Util/globals';
import { ApiProduct } from '../../../api/model';
import { SortedCacheProductsByDate } from '@/components/SearchForResults/helperFns';

export const formatDate = (dateUnformatted: string): Dayjs => {
  const year = dateUnformatted.substring(0, 4);
  const month = dateUnformatted.substring(4, 6);
  const day = dateUnformatted.substring(6);
  return dayjs(`${year}-${month}-${day}`);
};

export const getEarliestPackage = (
  sortedProductPackages: SortedCacheProductsByDate,
  variants?: ApiProduct[]
): ApiProductsCacheResponse => {
  return (
    Object.entries(sortedProductPackages)
      .flatMap(([, value]) => value)
      .reduce((total, current) => {
        return ((!!current.products?.[0]?.travelStartDate &&
          !!total?.products?.[0]?.travelStartDate &&
          dayjs(current.products?.[0]?.travelStartDate).unix() <
            dayjs(total.products?.[0]?.travelStartDate).unix() &&
          current.products?.some(product => (product.availability || 0) > 0)) ||
          (current.products?.some(product => (product.availability || 0) > 0) &&
            !total?.products?.some(product => (product.availability || 0) > 0)) ||
          !total?.products?.[0]?.travelStartDate) &&
          (!variants ||
            (variants?.length > 0 &&
              !variants.some(variant => variant.name === current.packageCode)))
          ? current
          : total;
      }, undefined as ApiProductsCacheResponse | undefined) ||
    Object.entries(sortedProductPackages)?.[0]?.[1]?.[0]
  );
};

const getPackageForDate = (
  sortedProductPackages: SortedCacheProductsByDate,
  dateFormatted: Dayjs,
  productCode?: string
) => {
  return Object.entries(sortedProductPackages)
    .flatMap(([, value]) => value)
    .find(productPackage => {
      const packageDate = dayjs(productPackage.products?.[0].travelStartDate);
      return (
        dateFormatted.isValid() &&
        packageDate.isValid() &&
        dateFormatted.isSame(packageDate, 'day') &&
        (!productCode || (!!productCode && productCode === productPackage.packageCode))
      );
    });
};

const getSelectedPackageAndEnrichedProduct = (
  searchParams: Record<string, string | undefined>,
  sortedProductPackages: SortedCacheProductsByDate,
  product?: ApiProduct
): {
  selectedProductPackage: ApiProductsCacheResponse | undefined;
  isVariant: boolean;
  variant: ApiProduct | undefined;
} => {
  const variantQuery = searchParams[VARIANT_QUERY_PROP];
  let selectedProductPackage: ApiProductsCacheResponse | undefined;
  let isVariant = false;
  let foundVariantIndex = -1;
  let foundVariant: ApiProduct | undefined = undefined;
  const { variants } = product || {};

  if (!!searchParams[VARIANT_QUERY_PROP]) {
    const variantQuerySplit = variantQuery?.split(VARIANT_QUERY_PROP_DELIMITER);
    // has product code and departure date
    if (!!variantQuerySplit && variantQuerySplit.length === 2 && !!variants) {
      const identifier = variantQuerySplit[0];
      selectedProductPackage = getPackageForDate(
        sortedProductPackages,
        formatDate(variantQuerySplit[1]),
        identifier
      );
      foundVariantIndex = variants.findIndex(variant => {
        return (
          !!product &&
          product.name !== selectedProductPackage?.products?.[0]?.packageCode &&
          !!identifier &&
          identifier.toLowerCase().trim() === variant.name?.toLowerCase()?.trim()
        );
      });
      foundVariant = variants[foundVariantIndex];
      if (!!foundVariant && !!product) {
        isVariant = true;
        // replace product properties with variant properties
        Object.entries(foundVariant).forEach(([key, value]) => {
          if (
            !!value &&
            (typeof value === 'string' ||
              (Array.isArray(value) && value.length > 0) ||
              (typeof value === 'object' && Object.keys(value).length > 0)) &&
            !!product
          ) {
            product = { ...product, [key]: value };
          }
        });
      }
    } else if (!!variantQuerySplit && variantQuerySplit.length === 1) {
      selectedProductPackage = getPackageForDate(
        sortedProductPackages,
        formatDate(variantQuerySplit[0])
      );
    } else {
      selectedProductPackage = getEarliestPackage(sortedProductPackages, variants);
    }
  } else {
    selectedProductPackage = getEarliestPackage(sortedProductPackages, variants);
  }

  return { selectedProductPackage, isVariant, variant: foundVariant };
};

export default getSelectedPackageAndEnrichedProduct;
