'use client';

import Keys from '@/Translations/generated/da/country.json.keys';
import React, { JSX, ReactElement } from 'react';
import sanitize from '@/Util/sanitize';
import { useTranslation } from '@/app/i18n/client';
import AccordionElementList from '@/components/accordion/AccordionElementList';
import { THEMES } from '@/Util/globals';
import { ApiCountry } from '../../../api/model';
import Intro from '@/components/intro/Intro';
import GridContainer from '@/Layouts/GridContainer';
import Breadcrumb from '@/components/breadcrumb/Breadcrumb';
import { URL } from 'next/dist/compiled/@edge-runtime/primitives';
import { getResolvedUrl } from '@/Util/magnoliaData/getRequestConfig';
import { useGlobalMGLProps } from '@/Util/GlobalMGLPropsContext';
import { usePathname } from 'next/navigation';

const CountryEntry = ({
  country,
  breadcrumb
}: {
  country?: ApiCountry;
  breadcrumb?: ReactElement;
}): JSX.Element => {
  const { introTitleEntry, name, infoSectionsEntry, themeIntroEntry } = country || {};
  const { t } = useTranslation('country');

  return (
    <>
      <Intro
        theme={themeIntroEntry || THEMES.blue}
        headline={introTitleEntry ? introTitleEntry : `${t(Keys.headlineEntry)} ${name}`}
        breadcrumb={breadcrumb}
        singleColumn={
          <div className="intro__container__sub-headline">
            {t(Keys.subHeadlineEntry)} {name}
          </div>
        }
      />
      {infoSectionsEntry && (
        <GridContainer>
          <AccordionElementList
            items={infoSectionsEntry}
            bodyCb={item => (
              <div className="accordion-element__section__container">
                <div
                  className="accordion-element__section__text"
                  dangerouslySetInnerHTML={{ __html: sanitize(item.description, true) }}
                />
              </div>
            )}
            headerCb={item => (
              <div className="intro__container__accordionHeadline">{item.headline}</div>
            )}
            indexerProp="description"
          />
        </GridContainer>
      )}
    </>
  );
};

export default CountryEntry;
