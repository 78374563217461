import React, { FC, useEffect, useState } from 'react';
import GridContainer from '@/Layouts/GridContainer';
import { observer } from 'mobx-react';
import CheckoutStore, {
  CHECKOUT_LOCAL_STORAGE_KEY,
  CheckoutLocalStorage
} from '@/templates/checkout/CheckoutStore';
import {
  ApiBooking,
  ApiFlightItem,
  ApiItemType,
  ApiProductsCacheData,
  ApiProductsCacheDataFromJSON
} from '@ibe/api';
import { useSearchParams } from 'next/navigation';
import { CHECKOUT_BOOKING_ID_QUERY_PARAM, logger } from '@/Util/globals';
import { ExternalLinks } from '@/components/checkout/TermsAndConditions';
import Upper from '@/components/checkout/checkoutStepConfirmation/Upper';
import Lower from '@/components/checkout/checkoutStepConfirmation/Lower';
import { SessionPersistence } from '@ibe/services';
import { ApiAirlineLogo } from '../../../../api/model';
import { useCheckoutContext } from '@/templates/checkout/CheckoutContextProvider';
import { useWindow } from '@ibe/components';
import { CHECKOUT_CUSTOM_EVENT } from '@/templates/checkout/useCheckoutPageHelpers';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { useModelApiClient } from '@/Util/api';
import { broadcastEvent } from '@/Tracking/trackingSubscriptions';
import { CheckoutStepName, EventType } from '@/Tracking/types';
import useConfig from '@/Hooks/useConfig';
import { useCurrentLanguage } from '@/Util/CurrentLanguageProvider';
import { Props } from '@/types/cms/magnolia';

dayjs.extend(utc);

export type BookingIdAfterInvoiceBooking = {
  bookingId: string | undefined | null;
  status: string | undefined | null;
};

let timer: ReturnType<typeof setTimeout> | null = null;

const CheckoutStepConfirmation: FC<{
  index: number;
  bookingIdAfterInvoiceBooking: BookingIdAfterInvoiceBooking;
  checkoutStore: CheckoutStore;
  externalLinks: ExternalLinks;
  remarksFieldIsFilled: boolean;
  activeIndex: number;
  airlineLogosFromServer?: ApiAirlineLogo[];
  pageProps?: Props;
}> = observer(function CheckoutStepConfirmation({
  bookingIdAfterInvoiceBooking,
  checkoutStore,
  externalLinks,
  remarksFieldIsFilled,
  activeIndex,
  index,
  airlineLogosFromServer,
  pageProps
}): JSX.Element {
  const modelApiClient = useModelApiClient();
  const config = useConfig();
  const locale = useCurrentLanguage();
  const window = useWindow();
  const [booking, setBooking] = useState<ApiBooking | undefined>(undefined);
  const [productName, setProductName] = useState<string | undefined>(undefined);
  const [airlineLogos, setAirlineLogos] = useState<ApiAirlineLogo[] | undefined>(
    airlineLogosFromServer
  );
  const searchParams = useSearchParams();
  const { setClientHeroImage } = useCheckoutContext();

  useEffect(() => {
    if (index === activeIndex && !!booking && !!checkoutStore.selectedPacificProduct) {
      broadcastEvent(EventType.CHECKOUT_PROGRESS, config, locale, {
        data: {
          booking,
          cacheDataProduct: checkoutStore.selectedPacificProduct,
          product: checkoutStore.product
        },
        checkout: { step: index + 1, stepName: CheckoutStepName.BOOKING }
      });
    }

    return () => {
      if (!!timer) {
        clearTimeout(timer);
      }
    };
  }, [index, activeIndex, booking, checkoutStore.selectedPacificProduct, checkoutStore.product]);

  useEffect(() => {
    (async () => {
      if (index !== activeIndex) return;

      const bookingId =
        searchParams.get(CHECKOUT_BOOKING_ID_QUERY_PARAM) || bookingIdAfterInvoiceBooking.bookingId;
      const storage = new SessionPersistence<CheckoutLocalStorage>(CHECKOUT_LOCAL_STORAGE_KEY);
      const { productName, productCode, productTeaserImage } = storage.get() || {};

      try {
        checkoutStore.isLoading = true;
        const bookingResponse = !bookingIdAfterInvoiceBooking.bookingId
          ? await checkoutStore.fetchBookingFromExternalId(bookingId || '')
          : await checkoutStore.fetchBooking();
        if (!!bookingResponse) {
          setBooking(bookingResponse);

          if (!airlineLogosFromServer && !bookingIdAfterInvoiceBooking.bookingId) {
            const flights = bookingResponse.items.filter(
              item => item.itemType === ApiItemType.FLIGHT
            ) as ApiFlightItem[];
            const airlineCarriers = flights.reduce((all: string[], current) => {
              return [
                ...all,
                current.segment[0].operatingCarrier.code ||
                  current.segment[0].marketingCarrier.code,
                current.segment[current.segment.length - 1].operatingCarrier.code ||
                  current.segment[current.segment.length - 1].marketingCarrier.code
              ];
            }, []);
            if (airlineCarriers.length > 0 && !checkoutStore.hasInvalidFlights(flights)) {
              try {
                const airlineLogos = await modelApiClient.getAirlineLogosByCode(airlineCarriers);
                if (!!airlineLogos) {
                  setAirlineLogos(airlineLogos);
                }
              } catch (err) {
                logger('error')('Unable to fetch airline logos: ', err);
              }
            }
          }
          broadcastEvent(EventType.PURCHASE, config, locale, {
            data: {
              booking: bookingResponse,
              bookingPackageName: productName,
              bookingPackageCode: productCode
            }
          });

          const customEvent = new CustomEvent<{
            pacificProduct: ApiProductsCacheData;
            productName: string;
          }>(CHECKOUT_CUSTOM_EVENT, {
            detail: {
              pacificProduct: ApiProductsCacheDataFromJSON({
                duration: dayjs
                  .utc(bookingResponse.travelEndDate)
                  .diff(dayjs.utc(bookingResponse.travelStartDate), 'day'),
                maxOccupancy: bookingResponse.travelers.length,
                travelStartDate: bookingResponse.travelStartDate,
                startingPriceCurrency: bookingResponse.price.currencyCode,
                startingPriceAmount: bookingResponse.price.finalPrice
              }),
              productName
            }
          });
          timer = setTimeout(() => {
            window?.document?.dispatchEvent(customEvent);
          }, 1000);
        }
        if (!!productTeaserImage) {
          setClientHeroImage(productTeaserImage);
        }
        if (!!productName) {
          setProductName(productName);
        }
      } catch (err) {
        logger('error')('Unable to fetch booking: ', err);
      } finally {
        checkoutStore.isLoading = false;
        storage.clear();
      }
    })();
  }, [bookingIdAfterInvoiceBooking, searchParams, index, activeIndex]);

  return (
    <GridContainer>
      {!!booking && (
        <div className="confirmation">
          <Upper
            booking={booking}
            externalLinks={externalLinks}
            remarksFieldIsFilled={remarksFieldIsFilled}
            bookingIdAfterInvoiceBooking={bookingIdAfterInvoiceBooking}
          />
          <Lower
            booking={booking}
            checkoutStore={checkoutStore}
            airlineLogos={airlineLogos}
            productName={checkoutStore?.product?.productTitle || productName}
            pageProps={pageProps}
          />
        </div>
      )}
    </GridContainer>
  );
});

export default CheckoutStepConfirmation;
