'use client';

import { Carousel } from 'react-responsive-carousel';
import { Next, Previous } from '@/Theme/SVG/Svgs';
import Image from '@/components/image/Image';
import React, { useState } from 'react';
import { NavButton } from '@/components/imageGallery/NavButton';
import { ApiImage } from '../../../api/model';
import { EditableAreaOrPageChild } from '@/types/cms/magnolia';
import { useIsComponentMounted } from '@/Hooks/useIsComponentMounted';

export interface ImageSliderData extends Partial<EditableAreaOrPageChild> {
  images: Array<ApiImage>;
}

const ImageSlider = (props: ImageSliderData) => {
  const { images, pageProps } = props;
  const isMounted = useIsComponentMounted();
  const [activeIndex, setActiveIndex] = useState<number>(0);

  return (
    <div className="image-slider">
      <Carousel
        showThumbs={false}
        showStatus={false}
        showIndicators={images?.length > 1}
        infiniteLoop={false}
        onChange={index => setActiveIndex(index)}
        selectedItem={activeIndex}
        renderArrowNext={clickHandler => (
          <NavButton
            id="Carousel-NavBtn-next"
            className="image-slider__button image-slider__button--next"
            onClick={clickHandler}
            style={{
              display:
                images && activeIndex !== undefined && activeIndex === images.length - 1
                  ? 'none'
                  : 'block'
            }}
          >
            <Next />
          </NavButton>
        )}
        renderArrowPrev={clickHandler => (
          <NavButton
            id="Carousel-NavBtn-previous"
            onClick={clickHandler}
            className="image-slider__button image-slider__button--previous"
            style={{
              display: images && activeIndex !== undefined && activeIndex === 0 ? 'none' : 'block'
            }}
          >
            <Previous />
          </NavButton>
        )}
      >
        {isMounted && images.length > 0
          ? images.map((image, imgIndex) => (
              <div key={imgIndex} className="image-slider__image">
                <Image
                  className="expanded-gallery__image"
                  src={image?.imageLink || ''}
                  alt={image?.title || ''}
                  width={image?.width}
                  height={image?.height}
                  key={imgIndex}
                  serverSideContext={pageProps?.albConfig.context}
                />
              </div>
            ))
          : []}
      </Carousel>
    </div>
  );
};
export default ImageSlider;
