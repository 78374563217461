'use client';

import React, { FC, PropsWithChildren, useMemo, JSX, ReactElement } from 'react';
import { DefaultPageProps } from '@/types/cms/magnolia';
import { useGlobalMGLProps } from '@/Util/GlobalMGLPropsContext';
import ContactBar from '@/components/contactBar/ContactBar';
import EditableArea from '@/components/magnoliaPage/EditableArea/EditableArea';
import { THEMES } from '@/Util/globals';
import Keys from '@/Translations/generated/da/city.json.keys';
import { useTranslation } from '@/app/i18n/client';
import ContentModuleDynamic from '@/components/contentModule/ContentModuleDynamic';
import { ContentNavigationType } from '@/components/contentNavigation/ContentNavigation';
import { PageComponentProps } from '@/components/magnoliaPage/componentHelper';
import CityMain from '@/components/CityIntros/CityMain';
import Navigation from '@/components/navigation/Navigation';

export const CityPageInner: FC<
  PropsWithChildren<
    DefaultPageProps &
      PageComponentProps & {
        text: string;
        tel: string;
        mail: string;
        themeContactInformation: string;
        showContactBar: boolean;
        breadcrumb?: ReactElement;
      }
  >
> = (props): JSX.Element => {
  const { t } = useTranslation('city');
  const {
    header,
    themeContactInformation,
    showContactBar,
    children,
    upperPageContent,
    breadcrumb,
    pageProps
  } = props;

  const { city } = useGlobalMGLProps() ?? {};
  const {
    name,
    mainPage,
    subPages,
    contentAreaTitle,
    contentArea,
    themeIntroMain,
    themeContentArea
  } = city || {};

  const navigationItems = useMemo(
    () =>
      [
        contentArea && contentArea.length > 0
          ? {
              title: t(Keys.itinerary),
              id: 'itinerary',
              section: (
                <div>
                  <ContentModuleDynamic
                    theme={themeContentArea}
                    headline={contentAreaTitle ? contentAreaTitle : name}
                    contentRow={contentArea}
                    pageProps={pageProps}
                  />
                </div>
              )
            }
          : null
      ].filter(Boolean) as ContentNavigationType,
    [contentArea, t]
  );

  return (
    <div className="city-page">
      <div className="page__top">
        {header && <EditableArea className="page__top__header" content={header} />}
        {showContactBar && <ContactBar theme={themeContactInformation || THEMES.darkGreen} />}
      </div>
      <div className="page__center">
        {upperPageContent}
        {!!mainPage && !!subPages && (
          <Navigation
            className={themeIntroMain || THEMES.blue}
            items={[mainPage, ...subPages]}
            disableButton
          />
        )}
        <CityMain city={city} breadcrumb={breadcrumb} />
        {navigationItems.map(navigationItem => (
          <div key={navigationItem.id} id={navigationItem.id}>
            {navigationItem.section}
          </div>
        ))}
        {children}
      </div>
    </div>
  );
};

export default CityPageInner;
