'use client';

import React, { Dispatch, ReactElement, SetStateAction, useEffect } from 'react';
import { facCross } from '@/Theme/SVG/Icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useWindow } from '@ibe/components';
import { PAGE_TOP_CONTENT_CONTAINER, ROOT_PORTAL_CONTENT_CONTAINER } from '@/Util/globals';

const MobileOverlay = ({
  overlayOpen,
  setOverlayOpen,
  content,
  headerContent,
  onCloseIcon,
  className,
  ignoreNoZIndex
}: {
  overlayOpen: boolean;
  setOverlayOpen: Dispatch<SetStateAction<boolean>>;
  content: ReactElement;
  headerContent?: ReactElement;
  onCloseIcon?: ReactElement;
  className?: string;
  ignoreNoZIndex?: boolean;
}): ReactElement => {
  const window = useWindow();

  useEffect(() => {
    if (!ignoreNoZIndex) {
      if (overlayOpen) {
        window?.document?.body?.classList.add('no-scroll');
        window?.document?.querySelector(PAGE_TOP_CONTENT_CONTAINER)?.classList?.add('no-z-index');
      } else {
        window?.document?.body?.classList.remove('no-scroll');
        window?.document
          ?.querySelector(PAGE_TOP_CONTENT_CONTAINER)
          ?.classList?.remove('no-z-index');
      }
    }

    return (): void => {
      window?.document?.body?.classList.remove('no-scroll');
    };
  }, [overlayOpen]);

  return (
    <div
      className={`mobile-overlay${!!className ? ` ${className}` : ''}${
        overlayOpen ? ' mobile-overlay--open' : ''
      }`}
    >
      <div className="mobile-overlay__header">
        {!!headerContent && headerContent}
        <div
          className="mobile-overlay__menu-icon"
          onClick={() => {
            setOverlayOpen(!overlayOpen);
          }}
        >
          {overlayOpen && (
            <FontAwesomeIcon className="mobile-overlay__header__close" icon={facCross} />
          )}
          {!overlayOpen && !!onCloseIcon ? onCloseIcon : <></>}
        </div>
      </div>
      <div className="mobile-overlay__content">{content}</div>
    </div>
  );
};

export default MobileOverlay;
