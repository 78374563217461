import React, { FC, JSX } from 'react';
import Keys from '@/Translations/generated/da/search.json.keys';
import { useTranslation } from '@/app/i18n/client';
import { SearchProps } from '@/components/Search/SearchDesktopOverlay';
import CheckboxResults from '@/components/Search/CheckboxResults';
import { ApiTravelType } from '../../../api/model';
import { Action } from '@/components/Search/useCheckboxStateReducer';
import classNames from 'classnames';
import Button from '@/components/Button';
import { Spinner } from '@ibe/components';

const TravelTypesSearchTab: FC<
  Pick<
    SearchProps,
    | 'travelTypes'
    | 'getCountriesResults'
    | 'getDatesResults'
    | 'checkboxDispatch'
    | 'checkboxState'
    | 'productsTotalResults'
    | 'isLoading'
  > & {
    fullSearchView?: boolean;
    productsTotalTravelType: number;
    closeCollapse?: () => void;
    calculatedProductsTotalResults?: number;
  }
> = ({
  travelTypes,
  getCountriesResults,
  checkboxDispatch,
  checkboxState,
  fullSearchView,
  // productsTotalTravelType,
  productsTotalResults,
  closeCollapse,
  getDatesResults,
  isLoading,
  calculatedProductsTotalResults
}): JSX.Element => {
  const { t } = useTranslation('search');

  // const checkAllTravelTypes = async (): Promise<void> => {
  //   checkboxDispatch({
  //     type: Action.ADD_OR_REMOVE_ALL_PER_TYPE,
  //     payload: {
  //       id: '',
  //       type: 'travelType',
  //       name: '',
  //       items: travelTypes
  //         .filter(travelType => !!travelType?.products && travelType.products > 0)
  //         .map(travelType => ({
  //           id: travelType.id || '',
  //           name: travelType.name || ''
  //         })),
  //       callbacks: [getCountriesResults, getDatesResults]
  //     }
  //   });
  // };

  const checkSingleTravelType = async (travelType: ApiTravelType): Promise<void> => {
    checkboxDispatch({
      type: Action.ADD_OR_REMOVE_SINGLE,
      payload: {
        id: travelType.id || '',
        name: travelType.name || '',
        type: 'travelType',
        callbacks: [getCountriesResults, getDatesResults]
      }
    });
  };

  return (
    <>
      <div
        className={classNames('search__content search__content--checkboxes', {
          'search__content--checkboxes-expanded': fullSearchView
        })}
      >
        {/* maybe later... */}
        {/*<CheckboxResults*/}
        {/*  key="all"*/}
        {/*  label={t(Keys.all)}*/}
        {/*  checked={*/}
        {/*    Object.entries(checkboxState.items).filter(([, item]) => item.type === 'travelType')*/}
        {/*      .length === travelTypes.filter(travelType => (travelType?.products || 0) > 0).length*/}
        {/*  }*/}
        {/*  onChange={checkAllTravelTypes}*/}
        {/*  results={productsTotalTravelType}*/}
        {/*  isLoading={isLoading}*/}
        {/*/>*/}
        {travelTypes
          .filter(travelType => (travelType.products || 0) > 0)
          .map(travelType => (
            <CheckboxResults
              key={travelType.id}
              label={travelType.name}
              checked={!!travelType?.id && !!checkboxState.items[travelType.id]}
              onChange={(): Promise<void> => checkSingleTravelType(travelType)}
              results={travelType.products || 0}
              isLoading={isLoading}
            />
          ))}
      </div>
      {fullSearchView && (
        <div className="search__content__submit">
          <Button color="primary" onClick={closeCollapse} disabled={isLoading}>
            <span>{`${t(Keys.apply)} (${
              calculatedProductsTotalResults !== undefined
                ? calculatedProductsTotalResults
                : productsTotalResults
            })`}</span>
            {isLoading && <Spinner />}
          </Button>
        </div>
      )}
    </>
  );
};

export default TravelTypesSearchTab;
