import React, { FC, Fragment, JSX } from 'react';
import dayjs from 'dayjs';
import Keys from '@/Translations/generated/da/product.json.keys';
import { useTranslation } from '@/app/i18n/client';
import { TFunction } from 'i18next';
import localeData from 'dayjs/plugin/localeData';
import TableRow, { DesktopContentProps } from '@/components/ProductsDatesPrices/TableRow';
import {
  SortedCacheProductsByDate,
  YEAR_MONTH_DELIMITER
} from '@/components/SearchForResults/helperFns';
import { ApiProductsCacheResponse } from '@ibe/api';
import { VARIANT_QUERY_PROP } from '@/Util/globals';
import { MagnoliaSite } from '@/types/cms/magnolia';
import { ApiProduct } from '../../../api/model';
import { getProductByOccupancy } from '@/components/Search/helpersProduct';
import { getVariantParam } from '@/components/ProductsDatesPrices/getVariantParam';
import { setLocale } from 'yup';

dayjs.extend(localeData);

const getTableHead = (t: TFunction) => {
  return [
    t(Keys.departure),
    t(Keys.duration),
    t(Keys.price),
    t(Keys.departureFrom),
    t(Keys.note),
    ''
  ];
};

export const monthsShort = dayjs.monthsShort().map(value => value.replace(/[.]/g, ''));

export const isVariantActive = (
  productPackage: ApiProductsCacheResponse,
  product?: ApiProduct
): boolean => {
  return product?.name !== productPackage.packageCode;
};

export const getVariantUrl = (
  productPackage: ApiProductsCacheResponse,
  originalProduct?: ApiProduct,
  product?: ApiProduct,
  siteConfig?: MagnoliaSite
): string => {
  const { displayUrl, variants } = product || {};
  const { productPage } = siteConfig?.parameters || {};
  const productUrl = !!productPage
    ? `/${productPage.split('/')[productPage.split('/').length - 1]}/${displayUrl || ''}`
    : '';
  const travelStartDate = getProductByOccupancy(productPackage)?.travelStartDate || '';
  const variantParam = getVariantParam(
    travelStartDate,
    variants,
    productPackage.packageCode,
    originalProduct?.name
  );
  return `${productUrl}${!!variantParam ? `?${VARIANT_QUERY_PROP}=${variantParam}` : ''}`;
};

const packageHasFutureDates = (productPackage: ApiProductsCacheResponse): boolean | undefined => {
  return productPackage.products?.some(
    product =>
      dayjs(product.travelStartDate).isSame(dayjs(), 'day') ||
      dayjs(product.travelStartDate).isAfter(dayjs(), 'day')
  );
};

const DesktopContent: FC<
  Omit<DesktopContentProps, 'productPackage' | 'variantUrl'> & {
    sortedProducts: SortedCacheProductsByDate;
  }
> = ({
  sortedProducts,
  siteConfig,
  containerRef,
  goToCheckout,
  isDesktop,
  isVariant,
  product,
  selectedPackage,
  originalProduct,
  setLoading
}): JSX.Element => {
  const { t } = useTranslation('product');

  return (
    <div className="dates-prices--desktop">
      <table>
        <thead>
          <tr>
            {getTableHead(t).map((label, idx) => (
              <th key={label || idx}>{label}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {Object.entries(sortedProducts).map(([year_month, productPackages]) => {
            const year = year_month.split(YEAR_MONTH_DELIMITER)[0];
            const month = year_month.split(YEAR_MONTH_DELIMITER)[1];
            return productPackages.some(singleProductPackage =>
              packageHasFutureDates(singleProductPackage)
            ) ? (
              <Fragment key={year_month}>
                <tr className="dates-prices__month">
                  <td colSpan={getTableHead(t).length}>
                    <hr />
                    <h4>{`${monthsShort[parseInt(month, 10)]} ${year}`}</h4>
                  </td>
                </tr>
                {productPackages
                  .filter(productPackage => packageHasFutureDates(productPackage))
                  .map(productPackage => (
                    <TableRow
                      key={`${productPackage.packageCode}-${productPackage.products?.[0]?.travelStartDate}`}
                      productPackage={productPackage}
                      isVariant={isVariant}
                      isDesktop={isDesktop}
                      containerRef={containerRef}
                      goToCheckout={goToCheckout}
                      product={product}
                      selectedPackage={selectedPackage}
                      originalProduct={originalProduct}
                      setLoading={setLoading}
                      variantUrl={getVariantUrl(
                        productPackage,
                        originalProduct,
                        product,
                        siteConfig
                      )}
                    />
                  ))}
              </Fragment>
            ) : (
              <Fragment key={year_month} />
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default DesktopContent;
