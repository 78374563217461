import React, { FC, JSX } from 'react';
import { SearchProps } from '@/components/Search/SearchDesktopOverlay';
import RadioResults from '@/components/Search/RadioResults';
// import Keys from '@/Translations/generated/da/search.json.keys';
// import { useTranslation } from '@/app/i18n/client';
import { Action } from '@/components/Search/useCheckboxStateReducer';
import { ApiTravelType } from '../../../api/model';

const TravelTypesSearchMobile: FC<
  Pick<
    SearchProps,
    | 'travelTypes'
    | 'getCountriesResults'
    | 'getDatesResults'
    | 'checkboxDispatch'
    | 'checkboxState'
    | 'isLoading'
  > &
    Partial<SearchProps> & {
      productsTotalTravelType: number;
    }
> = ({
  travelTypes,
  getCountriesResults,
  getDatesResults,
  checkboxDispatch,
  checkboxState,
  // productsTotalTravelType,
  isLoading
}): JSX.Element => {
  // const { t } = useTranslation('search');
  //
  // const checkAllTravelTypes = async (): Promise<void> => {
  //   checkboxDispatch({
  //     type: Action.ADD_OR_REMOVE_ALL_PER_TYPE,
  //     payload: {
  //       id: '',
  //       type: 'travelType',
  //       name: '',
  //       items: travelTypes
  //         .filter(travelType => !!travelType?.products && travelType.products > 0)
  //         .map(travelType => ({
  //           id: travelType.id || '',
  //           name: travelType.name || ''
  //         })),
  //       callbacks: [getCountriesResults, getDatesResults]
  //     }
  //   });
  // };

  const checkSingleTravelType = async (travelType: ApiTravelType): Promise<void> => {
    checkboxDispatch({
      type: Action.ADD_OR_REMOVE_SINGLE,
      payload: {
        id: travelType.id || '',
        name: travelType.name || '',
        type: 'travelType',
        callbacks: [getCountriesResults, getDatesResults]
      }
    });
  };

  return (
    <div className="search__content__mobile">
      {/* maybe later... */}
      {/*<RadioResults*/}
      {/*  key="all"*/}
      {/*  label={t(Keys.all)}*/}
      {/*  checked={*/}
      {/*    Object.entries(checkboxState.items).filter(([, item]) => item.type === 'travelType')*/}
      {/*      .length === travelTypes.filter(travelType => (travelType?.products || 0) > 0).length*/}
      {/*  }*/}
      {/*  onChange={checkAllTravelTypes}*/}
      {/*  results={productsTotalTravelType}*/}
      {/*  isLoading={isLoading}*/}
      {/*/>*/}
      {travelTypes
        .filter(travelType => (travelType.products || 0) > 0)
        .map(travelType => (
          <RadioResults
            key={travelType.id}
            label={travelType.name}
            checked={!!travelType?.id && !!checkboxState.items[travelType.id]}
            onChange={(): Promise<void> => checkSingleTravelType(travelType)}
            results={travelType.products || 0}
            isLoading={isLoading}
          />
        ))}
    </div>
  );
};

export default TravelTypesSearchMobile;
