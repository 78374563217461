/* This file was generated by running "node scripts/generateTranslations.js". */
/* Created: 2024-10-13 16:16:22 */

const Keys = {
  headline: 'headline',
  moreInformation: 'moreInformation',
  citizens: 'citizens',
  capital: 'capital',
  language: 'language',
  religion: 'religion',
  currency: 'currency',
  cities: 'cities',
  foundation: 'foundation',
  heightAboveSeaLevel: 'heightAboveSeaLevel',
  headlineAccordion: 'headlineAccordion',
  itinerary: 'itinerary',
  headlineMap: 'headlineMap',
  headlineClimate: 'headlineClimate',
  headlineTrips: 'headlineTrips',
  headlineImages: 'headlineImages',
  headlineVideos: 'headlineVideos',
  headlineFacts: 'headlineFacts',
  showAllTrips: 'showAllTrips'
}; export default Keys;