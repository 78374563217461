/* This file was generated by running "node scripts/generateTranslations.js". */
/* Created: 2024-10-13 16:16:22 */

const Keys = {
  search: 'search',
  destinations: 'destinations',
  travels: 'travels',
  travelTypes: 'travelTypes',
  departureTime: 'departureTime',
  showResults: 'showResults',
  inputPlaceholder: 'inputPlaceholder',
  all: 'all',
  gem: 'gem',
  departureAirport: 'departureAirport',
  singleRoomOnly: 'singleRoomOnly',
  clearAll: 'clearAll',
  foundTravels: 'foundTravels',
  hideCanceledTrips: 'hideCanceledTrips',
  productImageView: 'productImageView',
  mapView: 'mapView',
  calendarView: 'calendarView',
  apply: 'apply',
  filters: 'filters',
  foundTrips: 'foundTrips',
  foundTrips_plural: 'foundTrips_plural',
  hideUnavailableTrips: 'hideUnavailableTrips',
  price: 'price',
  duration: 'duration',
  departureDate: 'departureDate',
  low: 'low',
  high: 'high',
  departureDates: 'departureDates',
  day: 'day',
  day_plural: 'day_plural',
  from: 'from',
  soldOut: 'soldOut',
  goToProduct: 'goToProduct',
  sortBy: 'sortBy'
}; export default Keys;