import React, { FC, JSX } from 'react';
import classNames from 'classnames';
import Keys from '@/Translations/generated/da/search.json.keys';
import { getProductDisplayUrl } from '@/components/Search/TextSearchTab';
import { SearchProps } from '@/components/Search/SearchDesktopOverlay';
import { useTranslation } from '@/app/i18n/client';
import { TransitionStatus } from 'react-transition-state';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { facGlobeMagnifier, facLocationMarker } from '@/Theme/SVG/Icons';
import { ApiCountryComponent, ApiProductComponent } from '@ibe/api';
import { fromUUID, SearchParamsQuery } from '@/components/Search/helpers';
import { SEARCH_QUERY_PARAMETER } from '@/Util/globals';

const getCountrySearchQuery = (country: ApiCountryComponent): string => {
  const query: SearchParamsQuery = {
    continent: { [fromUUID(country.continent || '')]: [fromUUID(country.id || '')] }
  };
  const params = new URLSearchParams();
  params.set(SEARCH_QUERY_PARAMETER, JSON.stringify(query));
  return params.toString();
};

const TextSearchResults: FC<
  Pick<SearchProps, 'textSearch' | 'productPageBasePath' | 'resultsPageBasePath'> & {
    status?: TransitionStatus;
  }
> = ({ textSearch, productPageBasePath, status, resultsPageBasePath }): JSX.Element => {
  const { t } = useTranslation('search');
  const { countries: textSearchCountries, products: textSearchProducts } = textSearch;

  return (
    <div
      className={classNames('search__content__input__dropdown', {
        'search__content__input__dropdown--hidden': status === 'preEnter' || status === 'exiting'
      })}
    >
      {!!textSearchCountries && textSearchCountries.length > 0 && (
        <>
          <div className="search__content__input__dropdown__headline">
            <FontAwesomeIcon icon={facLocationMarker} />
            <span>{`${t(Keys.destinations)} (${textSearchCountries.length})`}</span>
          </div>
          {textSearchCountries.map(textSearchCountry => (
            <div key={textSearchCountry.id} className="search__content__input__dropdown__item">
              <a href={`/${resultsPageBasePath}?${getCountrySearchQuery(textSearchCountry)}`}>
                {textSearchCountry.name}
              </a>
            </div>
          ))}
        </>
      )}
      {!!textSearchCountries &&
        textSearchCountries.length > 0 &&
        !!textSearchProducts &&
        textSearchProducts.length > 0 && <hr className="search__content__input__dropdown__hr" />}
      {!!textSearchProducts && textSearchProducts.length > 0 && (
        <>
          <div className="search__content__input__dropdown__headline">
            <FontAwesomeIcon icon={facGlobeMagnifier} />
            <span>{`${t(Keys.travels)} (${textSearchProducts.length})`}</span>
          </div>
          {textSearchProducts.map((textSearchProduct: ApiProductComponent) => (
            <div
              key={textSearchProduct.productTitle}
              className="search__content__input__dropdown__item"
            >
              <a href={`/${productPageBasePath}/${getProductDisplayUrl(textSearchProduct)}`}>
                {textSearchProduct.productTitle}
              </a>
            </div>
          ))}
        </>
      )}
    </div>
  );
};

export default TextSearchResults;
