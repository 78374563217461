'use client';

import React, { FC, JSX, PropsWithChildren } from 'react';
import { EditorContextType } from '@/components/magnoliaPage/componentHelper';
import { EditorContext } from '@magnolia/react-editor';
import { config } from '@/Util/magnolia';
import { initTranslations } from '@/Config/config';
import { Props } from '@/types/cms/magnolia';

export const EditorContextForLayout: FC<
  PropsWithChildren<{
    context: EditorContextType;
    pageProps: Props;
    isLocal: string;
    locale: string;
  }>
> = ({ context, children, pageProps, isLocal, locale }): JSX.Element => {
  const { translationsVersion, magnoliaHost } = pageProps;

  (async (): Promise<void> => {
    await initTranslations(isLocal, magnoliaHost, locale, translationsVersion);
  })();

  return (
    <EditorContext.Provider value={{ ...context, componentMappings: config.componentMappings }}>
      {children}
    </EditorContext.Provider>
  );
};
