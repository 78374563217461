/* tslint:disable */
/* eslint-disable */
/**
 * Albatros CMS API
 * ISO API for the IBE. 
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ApiAccommodation,
    ApiAccommodationFromJSON,
    ApiAccommodationFromJSONTyped,
    ApiAirlineLogo,
    ApiAirlineLogoFromJSON,
    ApiAirlineLogoFromJSONTyped,
    ApiCity,
    ApiCityFromJSON,
    ApiCityFromJSONTyped,
    ApiContinent,
    ApiContinentFromJSON,
    ApiContinentFromJSONTyped,
    ApiCountriesRQ,
    ApiCountriesRQFromJSON,
    ApiCountriesRQFromJSONTyped,
    ApiCountry,
    ApiCountryFromJSON,
    ApiCountryFromJSONTyped,
    ApiExcursion,
    ApiExcursionFromJSON,
    ApiExcursionFromJSONTyped,
    ApiExtension,
    ApiExtensionFromJSON,
    ApiExtensionFromJSONTyped,
    ApiExtra,
    ApiExtraFromJSON,
    ApiExtraFromJSONTyped,
    ApiInsurance,
    ApiInsuranceFromJSON,
    ApiInsuranceFromJSONTyped,
    ApiPage,
    ApiPageFromJSON,
    ApiPageFromJSONTyped,
    ApiProduct,
    ApiProductFromJSON,
    ApiProductFromJSONTyped,
    ApiProductStateType,
    ApiProductStateTypeFromJSON,
    ApiProductStateTypeFromJSONTyped,
    ApiProductsCountResponseItem,
    ApiProductsCountResponseItemFromJSON,
    ApiProductsCountResponseItemFromJSONTyped,
    ApiShip,
    ApiShipFromJSON,
    ApiShipFromJSONTyped,
    ApiStaticPageProps,
    ApiStaticPagePropsFromJSON,
    ApiStaticPagePropsFromJSONTyped,
    ApiTravelType,
    ApiTravelTypeFromJSON,
    ApiTravelTypeFromJSONTyped,
} from '../models';

export interface DefaultApiAccommodationByCmsCodeRequest {
    cmsCode: string;
    site: string;
    lang?: string;
}

export interface DefaultApiExcursionByCmsCodeRequest {
    cmsCode: string;
    site: string;
    lang?: string;
}

export interface DefaultApiExtensionByCmsCodeRequest {
    cmsCode: string;
    lang?: string;
}

export interface DefaultApiExtraByCmsCodeRequest {
    cmsCode: string;
    category: string;
    site: string;
    lang?: string;
}

export interface DefaultApiGetAirlineLogosByCodeRequest {
    codes?: Array<string>;
}

export interface DefaultApiGetAllProductsRequest {
    site?: string;
    lang?: string;
}

export interface DefaultApiGetCityRequest {
    name: string;
    site: string;
    lang?: string;
}

export interface DefaultApiGetContinentRequest {
    name: string;
    site: string;
    lang?: string;
}

export interface DefaultApiGetContinentsRequest {
    site: string;
    lang?: string;
    simple?: boolean;
}

export interface DefaultApiGetCountriesRequest {
    apiCountriesRQ?: ApiCountriesRQ;
}

export interface DefaultApiGetCountryRequest {
    name: string;
    site: string;
    lang?: string;
}

export interface DefaultApiGetPagePropsRequest {
    linkSelection: string;
    pageId: string;
    site: string;
}

export interface DefaultApiGetProductRequest {
    name: string;
    site: string;
    lang?: string;
}

export interface DefaultApiGetProductListByCodesRequest {
    codes: Array<string>;
    site: string;
    lang?: string;
}

export interface DefaultApiGetProductListByNamesSimpleRequest {
    names: Array<string>;
    site: string;
    lang?: string;
    simple?: boolean;
}

export interface DefaultApiGetProductsRequest {
    site: string;
    lang?: string;
    travelTypes?: Array<string>;
    countries?: Array<string>;
    simple?: boolean;
}

export interface DefaultApiGetProductsByCityIdRequest {
    cityId: string;
    site: string;
    lang?: string;
    limit?: number;
}

export interface DefaultApiGetProductsByContinentIdRequest {
    continentId: string;
    site: string;
    lang?: string;
    limit?: number;
}

export interface DefaultApiGetProductsByCountryRequest {
    countryId: string;
    site: string;
    lang?: string;
    limit?: number;
}

export interface DefaultApiGetProductsByMarketRequest {
    market: string;
    site: string;
    lang?: string;
    limit?: number;
}

export interface DefaultApiGetProductsByMarketAndContinentCountRequest {
    market: string;
    continents: Array<string>;
    lang?: string;
}

export interface DefaultApiGetProductsByMarketCountRequest {
    market: string;
    site: string;
    lang?: string;
}

export interface DefaultApiGetProductsByShipRequest {
    shipId: string;
    site: string;
    lang?: string;
    limit?: number;
}

export interface DefaultApiGetProductsByTravelTypeRequest {
    travelTypeId: string;
    site: string;
    lang?: string;
    limit?: number;
}

export interface DefaultApiGetShipByDisplayUrlRequest {
    displayUrl: string;
    site: string;
    lang?: string;
}

export interface DefaultApiGetShipsRequest {
    site: string;
    lang?: string;
    simple?: boolean;
}

export interface DefaultApiGetStaticPagePropsRequest {
    tags: string;
    site: string;
}

export interface DefaultApiGetTravelTypeRequest {
    name: string;
    site: string;
    lang?: string;
}

export interface DefaultApiGetTravelTypeByIdRequest {
    id: string;
    site: string;
    lang?: string;
}

export interface DefaultApiGetTravelTypesRequest {
    site: string;
    lang?: string;
    simple?: boolean;
}

export interface DefaultApiInsuranceByCmsCodeRequest {
    cmsCode: string;
    site: string;
    lang?: string;
}

export interface DefaultApiUpdateProductStateRequest {
    codes: Array<string>;
    state: ApiProductStateType;
}

/**
 * no description
 */
export class DefaultApi extends runtime.BaseAPI {

    /**
     * Get Accommodation by CMS code
     */
    async accommodationByCmsCodeRaw(requestParameters: DefaultApiAccommodationByCmsCodeRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ApiAccommodation>> {
        if (requestParameters.cmsCode === null || requestParameters.cmsCode === undefined) {
            throw new runtime.RequiredError('cmsCode','Required parameter requestParameters.cmsCode was null or undefined when calling accommodationByCmsCode.');
        }

        if (requestParameters.site === null || requestParameters.site === undefined) {
            throw new runtime.RequiredError('site','Required parameter requestParameters.site was null or undefined when calling accommodationByCmsCode.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.cmsCode !== undefined) {
            queryParameters['cmsCode'] = requestParameters.cmsCode;
        }

        if (requestParameters.site !== undefined) {
            queryParameters['site'] = requestParameters.site;
        }

        if (requestParameters.lang !== undefined) {
            queryParameters['lang'] = requestParameters.lang;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        const response = await this.request({
            path: `/accommodation/accommodationByCmsCode`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiAccommodationFromJSON(jsonValue));
    }

    /**
     * Get Accommodation by CMS code
     */
    async accommodationByCmsCode(cmsCode: string, site: string, lang?: string, initOverrides?: RequestInit): Promise<ApiAccommodation> {
        const response = await this.accommodationByCmsCodeRaw({ cmsCode: cmsCode, site: site, lang: lang }, initOverrides);
        return await response.value();
    }

    /**
     * Get Excursion by CMS code
     */
    async excursionByCmsCodeRaw(requestParameters: DefaultApiExcursionByCmsCodeRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ApiExcursion>> {
        if (requestParameters.cmsCode === null || requestParameters.cmsCode === undefined) {
            throw new runtime.RequiredError('cmsCode','Required parameter requestParameters.cmsCode was null or undefined when calling excursionByCmsCode.');
        }

        if (requestParameters.site === null || requestParameters.site === undefined) {
            throw new runtime.RequiredError('site','Required parameter requestParameters.site was null or undefined when calling excursionByCmsCode.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.cmsCode !== undefined) {
            queryParameters['cmsCode'] = requestParameters.cmsCode;
        }

        if (requestParameters.site !== undefined) {
            queryParameters['site'] = requestParameters.site;
        }

        if (requestParameters.lang !== undefined) {
            queryParameters['lang'] = requestParameters.lang;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        const response = await this.request({
            path: `/excursion/excursionByCmsCode`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiExcursionFromJSON(jsonValue));
    }

    /**
     * Get Excursion by CMS code
     */
    async excursionByCmsCode(cmsCode: string, site: string, lang?: string, initOverrides?: RequestInit): Promise<ApiExcursion> {
        const response = await this.excursionByCmsCodeRaw({ cmsCode: cmsCode, site: site, lang: lang }, initOverrides);
        return await response.value();
    }

    /**
     * Get Extension by CMS code
     */
    async extensionByCmsCodeRaw(requestParameters: DefaultApiExtensionByCmsCodeRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ApiExtension>> {
        if (requestParameters.cmsCode === null || requestParameters.cmsCode === undefined) {
            throw new runtime.RequiredError('cmsCode','Required parameter requestParameters.cmsCode was null or undefined when calling extensionByCmsCode.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.cmsCode !== undefined) {
            queryParameters['cmsCode'] = requestParameters.cmsCode;
        }

        if (requestParameters.lang !== undefined) {
            queryParameters['lang'] = requestParameters.lang;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        const response = await this.request({
            path: `/extension/extensionByCmsCode`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiExtensionFromJSON(jsonValue));
    }

    /**
     * Get Extension by CMS code
     */
    async extensionByCmsCode(cmsCode: string, lang?: string, initOverrides?: RequestInit): Promise<ApiExtension> {
        const response = await this.extensionByCmsCodeRaw({ cmsCode: cmsCode, lang: lang }, initOverrides);
        return await response.value();
    }

    /**
     * Get Extra by CMS code
     */
    async extraByCmsCodeRaw(requestParameters: DefaultApiExtraByCmsCodeRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ApiExtra>> {
        if (requestParameters.cmsCode === null || requestParameters.cmsCode === undefined) {
            throw new runtime.RequiredError('cmsCode','Required parameter requestParameters.cmsCode was null or undefined when calling extraByCmsCode.');
        }

        if (requestParameters.category === null || requestParameters.category === undefined) {
            throw new runtime.RequiredError('category','Required parameter requestParameters.category was null or undefined when calling extraByCmsCode.');
        }

        if (requestParameters.site === null || requestParameters.site === undefined) {
            throw new runtime.RequiredError('site','Required parameter requestParameters.site was null or undefined when calling extraByCmsCode.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.cmsCode !== undefined) {
            queryParameters['cmsCode'] = requestParameters.cmsCode;
        }

        if (requestParameters.category !== undefined) {
            queryParameters['category'] = requestParameters.category;
        }

        if (requestParameters.site !== undefined) {
            queryParameters['site'] = requestParameters.site;
        }

        if (requestParameters.lang !== undefined) {
            queryParameters['lang'] = requestParameters.lang;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        const response = await this.request({
            path: `/extra/extraByCmsCode`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiExtraFromJSON(jsonValue));
    }

    /**
     * Get Extra by CMS code
     */
    async extraByCmsCode(cmsCode: string, category: string, site: string, lang?: string, initOverrides?: RequestInit): Promise<ApiExtra> {
        const response = await this.extraByCmsCodeRaw({ cmsCode: cmsCode, category: category, site: site, lang: lang }, initOverrides);
        return await response.value();
    }

    /**
     * Get airline logos by code
     */
    async getAirlineLogosByCodeRaw(requestParameters: DefaultApiGetAirlineLogosByCodeRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<ApiAirlineLogo>>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.codes !== undefined) {
            queryParameters['codes'] = requestParameters.codes;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        const response = await this.request({
            path: `/airlineLogo/getAirlineLogosByCode`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ApiAirlineLogoFromJSON));
    }

    /**
     * Get airline logos by code
     */
    async getAirlineLogosByCode(codes?: Array<string>, initOverrides?: RequestInit): Promise<Array<ApiAirlineLogo>> {
        const response = await this.getAirlineLogosByCodeRaw({ codes: codes }, initOverrides);
        return await response.value();
    }

    /**
     * Get all available products
     */
    async getAllProductsRaw(requestParameters: DefaultApiGetAllProductsRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<ApiProduct>>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.site !== undefined) {
            queryParameters['site'] = requestParameters.site;
        }

        if (requestParameters.lang !== undefined) {
            queryParameters['lang'] = requestParameters.lang;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        const response = await this.request({
            path: `/product/getAllProducts`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ApiProductFromJSON));
    }

    /**
     * Get all available products
     */
    async getAllProducts(site?: string, lang?: string, initOverrides?: RequestInit): Promise<Array<ApiProduct>> {
        const response = await this.getAllProductsRaw({ site: site, lang: lang }, initOverrides);
        return await response.value();
    }

    /**
     * Get city
     */
    async getCityRaw(requestParameters: DefaultApiGetCityRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ApiCity>> {
        if (requestParameters.name === null || requestParameters.name === undefined) {
            throw new runtime.RequiredError('name','Required parameter requestParameters.name was null or undefined when calling getCity.');
        }

        if (requestParameters.site === null || requestParameters.site === undefined) {
            throw new runtime.RequiredError('site','Required parameter requestParameters.site was null or undefined when calling getCity.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.site !== undefined) {
            queryParameters['site'] = requestParameters.site;
        }

        if (requestParameters.lang !== undefined) {
            queryParameters['lang'] = requestParameters.lang;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        const response = await this.request({
            path: `/city/{name}`.replace(`{${"name"}}`, encodeURIComponent(String(requestParameters.name))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiCityFromJSON(jsonValue));
    }

    /**
     * Get city
     */
    async getCity(name: string, site: string, lang?: string, initOverrides?: RequestInit): Promise<ApiCity> {
        const response = await this.getCityRaw({ name: name, site: site, lang: lang }, initOverrides);
        return await response.value();
    }

    /**
     * Get continent
     */
    async getContinentRaw(requestParameters: DefaultApiGetContinentRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ApiContinent>> {
        if (requestParameters.name === null || requestParameters.name === undefined) {
            throw new runtime.RequiredError('name','Required parameter requestParameters.name was null or undefined when calling getContinent.');
        }

        if (requestParameters.site === null || requestParameters.site === undefined) {
            throw new runtime.RequiredError('site','Required parameter requestParameters.site was null or undefined when calling getContinent.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.site !== undefined) {
            queryParameters['site'] = requestParameters.site;
        }

        if (requestParameters.lang !== undefined) {
            queryParameters['lang'] = requestParameters.lang;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        const response = await this.request({
            path: `/continent/{name}`.replace(`{${"name"}}`, encodeURIComponent(String(requestParameters.name))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiContinentFromJSON(jsonValue));
    }

    /**
     * Get continent
     */
    async getContinent(name: string, site: string, lang?: string, initOverrides?: RequestInit): Promise<ApiContinent> {
        const response = await this.getContinentRaw({ name: name, site: site, lang: lang }, initOverrides);
        return await response.value();
    }

    /**
     * Get continents
     */
    async getContinentsRaw(requestParameters: DefaultApiGetContinentsRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<ApiContinent>>> {
        if (requestParameters.site === null || requestParameters.site === undefined) {
            throw new runtime.RequiredError('site','Required parameter requestParameters.site was null or undefined when calling getContinents.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.site !== undefined) {
            queryParameters['site'] = requestParameters.site;
        }

        if (requestParameters.lang !== undefined) {
            queryParameters['lang'] = requestParameters.lang;
        }

        if (requestParameters.simple !== undefined) {
            queryParameters['simple'] = requestParameters.simple;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        const response = await this.request({
            path: `/continent/getContinents`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ApiContinentFromJSON));
    }

    /**
     * Get continents
     */
    async getContinents(site: string, lang?: string, simple?: boolean, initOverrides?: RequestInit): Promise<Array<ApiContinent>> {
        const response = await this.getContinentsRaw({ site: site, lang: lang, simple: simple }, initOverrides);
        return await response.value();
    }

    /**
     * Get countries
     */
    async getCountriesRaw(requestParameters: DefaultApiGetCountriesRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<ApiCountry>>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        const response = await this.request({
            path: `/country/getCountries`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ApiCountriesRQFromJSONTyped(requestParameters.apiCountriesRQ, false),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ApiCountryFromJSON));
    }

    /**
     * Get countries
     */
    async getCountries(apiCountriesRQ?: ApiCountriesRQ, initOverrides?: RequestInit): Promise<Array<ApiCountry>> {
        const response = await this.getCountriesRaw({ apiCountriesRQ: apiCountriesRQ }, initOverrides);
        return await response.value();
    }

    /**
     * Get country
     */
    async getCountryRaw(requestParameters: DefaultApiGetCountryRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ApiCountry>> {
        if (requestParameters.name === null || requestParameters.name === undefined) {
            throw new runtime.RequiredError('name','Required parameter requestParameters.name was null or undefined when calling getCountry.');
        }

        if (requestParameters.site === null || requestParameters.site === undefined) {
            throw new runtime.RequiredError('site','Required parameter requestParameters.site was null or undefined when calling getCountry.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.site !== undefined) {
            queryParameters['site'] = requestParameters.site;
        }

        if (requestParameters.lang !== undefined) {
            queryParameters['lang'] = requestParameters.lang;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        const response = await this.request({
            path: `/country/{name}`.replace(`{${"name"}}`, encodeURIComponent(String(requestParameters.name))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiCountryFromJSON(jsonValue));
    }

    /**
     * Get country
     */
    async getCountry(name: string, site: string, lang?: string, initOverrides?: RequestInit): Promise<ApiCountry> {
        const response = await this.getCountryRaw({ name: name, site: site, lang: lang }, initOverrides);
        return await response.value();
    }

    /**
     * Get Page Props
     */
    async getPagePropsRaw(requestParameters: DefaultApiGetPagePropsRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<ApiPage>>> {
        if (requestParameters.linkSelection === null || requestParameters.linkSelection === undefined) {
            throw new runtime.RequiredError('linkSelection','Required parameter requestParameters.linkSelection was null or undefined when calling getPageProps.');
        }

        if (requestParameters.pageId === null || requestParameters.pageId === undefined) {
            throw new runtime.RequiredError('pageId','Required parameter requestParameters.pageId was null or undefined when calling getPageProps.');
        }

        if (requestParameters.site === null || requestParameters.site === undefined) {
            throw new runtime.RequiredError('site','Required parameter requestParameters.site was null or undefined when calling getPageProps.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.linkSelection !== undefined) {
            queryParameters['linkSelection'] = requestParameters.linkSelection;
        }

        if (requestParameters.pageId !== undefined) {
            queryParameters['pageId'] = requestParameters.pageId;
        }

        if (requestParameters.site !== undefined) {
            queryParameters['site'] = requestParameters.site;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        const response = await this.request({
            path: `/page/getPageProps`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ApiPageFromJSON));
    }

    /**
     * Get Page Props
     */
    async getPageProps(linkSelection: string, pageId: string, site: string, initOverrides?: RequestInit): Promise<Array<ApiPage>> {
        const response = await this.getPagePropsRaw({ linkSelection: linkSelection, pageId: pageId, site: site }, initOverrides);
        return await response.value();
    }

    /**
     * Get product
     */
    async getProductRaw(requestParameters: DefaultApiGetProductRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ApiProduct>> {
        if (requestParameters.name === null || requestParameters.name === undefined) {
            throw new runtime.RequiredError('name','Required parameter requestParameters.name was null or undefined when calling getProduct.');
        }

        if (requestParameters.site === null || requestParameters.site === undefined) {
            throw new runtime.RequiredError('site','Required parameter requestParameters.site was null or undefined when calling getProduct.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.site !== undefined) {
            queryParameters['site'] = requestParameters.site;
        }

        if (requestParameters.lang !== undefined) {
            queryParameters['lang'] = requestParameters.lang;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        const response = await this.request({
            path: `/product/{name}`.replace(`{${"name"}}`, encodeURIComponent(String(requestParameters.name))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiProductFromJSON(jsonValue));
    }

    /**
     * Get product
     */
    async getProduct(name: string, site: string, lang?: string, initOverrides?: RequestInit): Promise<ApiProduct> {
        const response = await this.getProductRaw({ name: name, site: site, lang: lang }, initOverrides);
        return await response.value();
    }

    /**
     * Get productlist by codes
     */
    async getProductListByCodesRaw(requestParameters: DefaultApiGetProductListByCodesRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<ApiProduct>>> {
        if (requestParameters.codes === null || requestParameters.codes === undefined) {
            throw new runtime.RequiredError('codes','Required parameter requestParameters.codes was null or undefined when calling getProductListByCodes.');
        }

        if (requestParameters.site === null || requestParameters.site === undefined) {
            throw new runtime.RequiredError('site','Required parameter requestParameters.site was null or undefined when calling getProductListByCodes.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.codes !== undefined) {
            queryParameters['codes'] = requestParameters.codes;
        }

        if (requestParameters.site !== undefined) {
            queryParameters['site'] = requestParameters.site;
        }

        if (requestParameters.lang !== undefined) {
            queryParameters['lang'] = requestParameters.lang;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        const response = await this.request({
            path: `/product/getProductListByCodes`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ApiProductFromJSON));
    }

    /**
     * Get productlist by codes
     */
    async getProductListByCodes(codes: Array<string>, site: string, lang?: string, initOverrides?: RequestInit): Promise<Array<ApiProduct>> {
        const response = await this.getProductListByCodesRaw({ codes: codes, site: site, lang: lang }, initOverrides);
        return await response.value();
    }

    /**
     * Get productlist by codes
     */
    async getProductListByNamesSimpleRaw(requestParameters: DefaultApiGetProductListByNamesSimpleRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<ApiProduct>>> {
        if (requestParameters.names === null || requestParameters.names === undefined) {
            throw new runtime.RequiredError('names','Required parameter requestParameters.names was null or undefined when calling getProductListByNamesSimple.');
        }

        if (requestParameters.site === null || requestParameters.site === undefined) {
            throw new runtime.RequiredError('site','Required parameter requestParameters.site was null or undefined when calling getProductListByNamesSimple.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.names !== undefined) {
            queryParameters['names'] = requestParameters.names;
        }

        if (requestParameters.site !== undefined) {
            queryParameters['site'] = requestParameters.site;
        }

        if (requestParameters.lang !== undefined) {
            queryParameters['lang'] = requestParameters.lang;
        }

        if (requestParameters.simple !== undefined) {
            queryParameters['simple'] = requestParameters.simple;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        const response = await this.request({
            path: `/product/getProductListByNamesSimple`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ApiProductFromJSON));
    }

    /**
     * Get productlist by codes
     */
    async getProductListByNamesSimple(names: Array<string>, site: string, lang?: string, simple?: boolean, initOverrides?: RequestInit): Promise<Array<ApiProduct>> {
        const response = await this.getProductListByNamesSimpleRaw({ names: names, site: site, lang: lang, simple: simple }, initOverrides);
        return await response.value();
    }

    /**
     * Get products
     */
    async getProductsRaw(requestParameters: DefaultApiGetProductsRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<ApiProduct>>> {
        if (requestParameters.site === null || requestParameters.site === undefined) {
            throw new runtime.RequiredError('site','Required parameter requestParameters.site was null or undefined when calling getProducts.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.site !== undefined) {
            queryParameters['site'] = requestParameters.site;
        }

        if (requestParameters.lang !== undefined) {
            queryParameters['lang'] = requestParameters.lang;
        }

        if (requestParameters.travelTypes !== undefined) {
            queryParameters['travelTypes'] = requestParameters.travelTypes;
        }

        if (requestParameters.countries !== undefined) {
            queryParameters['countries'] = requestParameters.countries;
        }

        if (requestParameters.simple !== undefined) {
            queryParameters['simple'] = requestParameters.simple;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        const response = await this.request({
            path: `/product/getProducts`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ApiProductFromJSON));
    }

    /**
     * Get products
     */
    async getProducts(site: string, lang?: string, travelTypes?: Array<string>, countries?: Array<string>, simple?: boolean, initOverrides?: RequestInit): Promise<Array<ApiProduct>> {
        const response = await this.getProductsRaw({ site: site, lang: lang, travelTypes: travelTypes, countries: countries, simple: simple }, initOverrides);
        return await response.value();
    }

    /**
     * Get products by city
     */
    async getProductsByCityIdRaw(requestParameters: DefaultApiGetProductsByCityIdRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<ApiProduct>>> {
        if (requestParameters.cityId === null || requestParameters.cityId === undefined) {
            throw new runtime.RequiredError('cityId','Required parameter requestParameters.cityId was null or undefined when calling getProductsByCityId.');
        }

        if (requestParameters.site === null || requestParameters.site === undefined) {
            throw new runtime.RequiredError('site','Required parameter requestParameters.site was null or undefined when calling getProductsByCityId.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.cityId !== undefined) {
            queryParameters['cityId'] = requestParameters.cityId;
        }

        if (requestParameters.site !== undefined) {
            queryParameters['site'] = requestParameters.site;
        }

        if (requestParameters.lang !== undefined) {
            queryParameters['lang'] = requestParameters.lang;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        const response = await this.request({
            path: `/product/getProductsByCity`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ApiProductFromJSON));
    }

    /**
     * Get products by city
     */
    async getProductsByCityId(cityId: string, site: string, lang?: string, limit?: number, initOverrides?: RequestInit): Promise<Array<ApiProduct>> {
        const response = await this.getProductsByCityIdRaw({ cityId: cityId, site: site, lang: lang, limit: limit }, initOverrides);
        return await response.value();
    }

    /**
     * Get products by continent
     */
    async getProductsByContinentIdRaw(requestParameters: DefaultApiGetProductsByContinentIdRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<ApiProduct>>> {
        if (requestParameters.continentId === null || requestParameters.continentId === undefined) {
            throw new runtime.RequiredError('continentId','Required parameter requestParameters.continentId was null or undefined when calling getProductsByContinentId.');
        }

        if (requestParameters.site === null || requestParameters.site === undefined) {
            throw new runtime.RequiredError('site','Required parameter requestParameters.site was null or undefined when calling getProductsByContinentId.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.continentId !== undefined) {
            queryParameters['continentId'] = requestParameters.continentId;
        }

        if (requestParameters.site !== undefined) {
            queryParameters['site'] = requestParameters.site;
        }

        if (requestParameters.lang !== undefined) {
            queryParameters['lang'] = requestParameters.lang;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        const response = await this.request({
            path: `/product/getProductsByContinent`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ApiProductFromJSON));
    }

    /**
     * Get products by continent
     */
    async getProductsByContinentId(continentId: string, site: string, lang?: string, limit?: number, initOverrides?: RequestInit): Promise<Array<ApiProduct>> {
        const response = await this.getProductsByContinentIdRaw({ continentId: continentId, site: site, lang: lang, limit: limit }, initOverrides);
        return await response.value();
    }

    /**
     * Get products by country
     */
    async getProductsByCountryRaw(requestParameters: DefaultApiGetProductsByCountryRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<ApiProduct>>> {
        if (requestParameters.countryId === null || requestParameters.countryId === undefined) {
            throw new runtime.RequiredError('countryId','Required parameter requestParameters.countryId was null or undefined when calling getProductsByCountry.');
        }

        if (requestParameters.site === null || requestParameters.site === undefined) {
            throw new runtime.RequiredError('site','Required parameter requestParameters.site was null or undefined when calling getProductsByCountry.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.countryId !== undefined) {
            queryParameters['countryId'] = requestParameters.countryId;
        }

        if (requestParameters.site !== undefined) {
            queryParameters['site'] = requestParameters.site;
        }

        if (requestParameters.lang !== undefined) {
            queryParameters['lang'] = requestParameters.lang;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        const response = await this.request({
            path: `/product/getProductsByCountry`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ApiProductFromJSON));
    }

    /**
     * Get products by country
     */
    async getProductsByCountry(countryId: string, site: string, lang?: string, limit?: number, initOverrides?: RequestInit): Promise<Array<ApiProduct>> {
        const response = await this.getProductsByCountryRaw({ countryId: countryId, site: site, lang: lang, limit: limit }, initOverrides);
        return await response.value();
    }

    /**
     * Get products by market
     */
    async getProductsByMarketRaw(requestParameters: DefaultApiGetProductsByMarketRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<ApiProduct>>> {
        if (requestParameters.market === null || requestParameters.market === undefined) {
            throw new runtime.RequiredError('market','Required parameter requestParameters.market was null or undefined when calling getProductsByMarket.');
        }

        if (requestParameters.site === null || requestParameters.site === undefined) {
            throw new runtime.RequiredError('site','Required parameter requestParameters.site was null or undefined when calling getProductsByMarket.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.market !== undefined) {
            queryParameters['market'] = requestParameters.market;
        }

        if (requestParameters.site !== undefined) {
            queryParameters['site'] = requestParameters.site;
        }

        if (requestParameters.lang !== undefined) {
            queryParameters['lang'] = requestParameters.lang;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        const response = await this.request({
            path: `/product/getProductsByMarket`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ApiProductFromJSON));
    }

    /**
     * Get products by market
     */
    async getProductsByMarket(market: string, site: string, lang?: string, limit?: number, initOverrides?: RequestInit): Promise<Array<ApiProduct>> {
        const response = await this.getProductsByMarketRaw({ market: market, site: site, lang: lang, limit: limit }, initOverrides);
        return await response.value();
    }

    /**
     * Get number of products by market and continent
     */
    async getProductsByMarketAndContinentCountRaw(requestParameters: DefaultApiGetProductsByMarketAndContinentCountRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<ApiProductsCountResponseItem>>> {
        if (requestParameters.market === null || requestParameters.market === undefined) {
            throw new runtime.RequiredError('market','Required parameter requestParameters.market was null or undefined when calling getProductsByMarketAndContinentCount.');
        }

        if (requestParameters.continents === null || requestParameters.continents === undefined) {
            throw new runtime.RequiredError('continents','Required parameter requestParameters.continents was null or undefined when calling getProductsByMarketAndContinentCount.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.market !== undefined) {
            queryParameters['market'] = requestParameters.market;
        }

        if (requestParameters.continents !== undefined) {
            queryParameters['continents'] = requestParameters.continents;
        }

        if (requestParameters.lang !== undefined) {
            queryParameters['lang'] = requestParameters.lang;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        const response = await this.request({
            path: `/product/getProductsByMarketAndContinentCount`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ApiProductsCountResponseItemFromJSON));
    }

    /**
     * Get number of products by market and continent
     */
    async getProductsByMarketAndContinentCount(market: string, continents: Array<string>, lang?: string, initOverrides?: RequestInit): Promise<Array<ApiProductsCountResponseItem>> {
        const response = await this.getProductsByMarketAndContinentCountRaw({ market: market, continents: continents, lang: lang }, initOverrides);
        return await response.value();
    }

    /**
     * Get number of products by market
     */
    async getProductsByMarketCountRaw(requestParameters: DefaultApiGetProductsByMarketCountRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<number>> {
        if (requestParameters.market === null || requestParameters.market === undefined) {
            throw new runtime.RequiredError('market','Required parameter requestParameters.market was null or undefined when calling getProductsByMarketCount.');
        }

        if (requestParameters.site === null || requestParameters.site === undefined) {
            throw new runtime.RequiredError('site','Required parameter requestParameters.site was null or undefined when calling getProductsByMarketCount.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.market !== undefined) {
            queryParameters['market'] = requestParameters.market;
        }

        if (requestParameters.site !== undefined) {
            queryParameters['site'] = requestParameters.site;
        }

        if (requestParameters.lang !== undefined) {
            queryParameters['lang'] = requestParameters.lang;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        const response = await this.request({
            path: `/product/getProductsByMarketCount`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     * Get number of products by market
     */
    async getProductsByMarketCount(market: string, site: string, lang?: string, initOverrides?: RequestInit): Promise<number> {
        const response = await this.getProductsByMarketCountRaw({ market: market, site: site, lang: lang }, initOverrides);
        return await response.value();
    }

    /**
     * Get products by ship
     */
    async getProductsByShipRaw(requestParameters: DefaultApiGetProductsByShipRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<ApiProduct>>> {
        if (requestParameters.shipId === null || requestParameters.shipId === undefined) {
            throw new runtime.RequiredError('shipId','Required parameter requestParameters.shipId was null or undefined when calling getProductsByShip.');
        }

        if (requestParameters.site === null || requestParameters.site === undefined) {
            throw new runtime.RequiredError('site','Required parameter requestParameters.site was null or undefined when calling getProductsByShip.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.shipId !== undefined) {
            queryParameters['shipId'] = requestParameters.shipId;
        }

        if (requestParameters.site !== undefined) {
            queryParameters['site'] = requestParameters.site;
        }

        if (requestParameters.lang !== undefined) {
            queryParameters['lang'] = requestParameters.lang;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        const response = await this.request({
            path: `/product/getProductsByShip`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ApiProductFromJSON));
    }

    /**
     * Get products by ship
     */
    async getProductsByShip(shipId: string, site: string, lang?: string, limit?: number, initOverrides?: RequestInit): Promise<Array<ApiProduct>> {
        const response = await this.getProductsByShipRaw({ shipId: shipId, site: site, lang: lang, limit: limit }, initOverrides);
        return await response.value();
    }

    /**
     * Get products by travelType
     */
    async getProductsByTravelTypeRaw(requestParameters: DefaultApiGetProductsByTravelTypeRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<ApiProduct>>> {
        if (requestParameters.travelTypeId === null || requestParameters.travelTypeId === undefined) {
            throw new runtime.RequiredError('travelTypeId','Required parameter requestParameters.travelTypeId was null or undefined when calling getProductsByTravelType.');
        }

        if (requestParameters.site === null || requestParameters.site === undefined) {
            throw new runtime.RequiredError('site','Required parameter requestParameters.site was null or undefined when calling getProductsByTravelType.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.travelTypeId !== undefined) {
            queryParameters['travelTypeId'] = requestParameters.travelTypeId;
        }

        if (requestParameters.site !== undefined) {
            queryParameters['site'] = requestParameters.site;
        }

        if (requestParameters.lang !== undefined) {
            queryParameters['lang'] = requestParameters.lang;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        const response = await this.request({
            path: `/product/getProductsByTravelType`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ApiProductFromJSON));
    }

    /**
     * Get products by travelType
     */
    async getProductsByTravelType(travelTypeId: string, site: string, lang?: string, limit?: number, initOverrides?: RequestInit): Promise<Array<ApiProduct>> {
        const response = await this.getProductsByTravelTypeRaw({ travelTypeId: travelTypeId, site: site, lang: lang, limit: limit }, initOverrides);
        return await response.value();
    }

    /**
     * Get total number of products
     */
    async getProductsCountRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<number>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        const response = await this.request({
            path: `/product/getProductsCount`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     * Get total number of products
     */
    async getProductsCount(initOverrides?: RequestInit): Promise<number> {
        const response = await this.getProductsCountRaw(initOverrides);
        return await response.value();
    }

    /**
     * Get ship by displayUrl
     */
    async getShipByDisplayUrlRaw(requestParameters: DefaultApiGetShipByDisplayUrlRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ApiShip>> {
        if (requestParameters.displayUrl === null || requestParameters.displayUrl === undefined) {
            throw new runtime.RequiredError('displayUrl','Required parameter requestParameters.displayUrl was null or undefined when calling getShipByDisplayUrl.');
        }

        if (requestParameters.site === null || requestParameters.site === undefined) {
            throw new runtime.RequiredError('site','Required parameter requestParameters.site was null or undefined when calling getShipByDisplayUrl.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.site !== undefined) {
            queryParameters['site'] = requestParameters.site;
        }

        if (requestParameters.lang !== undefined) {
            queryParameters['lang'] = requestParameters.lang;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        const response = await this.request({
            path: `/ship/{displayUrl}`.replace(`{${"displayUrl"}}`, encodeURIComponent(String(requestParameters.displayUrl))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiShipFromJSON(jsonValue));
    }

    /**
     * Get ship by displayUrl
     */
    async getShipByDisplayUrl(displayUrl: string, site: string, lang?: string, initOverrides?: RequestInit): Promise<ApiShip> {
        const response = await this.getShipByDisplayUrlRaw({ displayUrl: displayUrl, site: site, lang: lang }, initOverrides);
        return await response.value();
    }

    /**
     * Get all ships
     */
    async getShipsRaw(requestParameters: DefaultApiGetShipsRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<ApiShip>>> {
        if (requestParameters.site === null || requestParameters.site === undefined) {
            throw new runtime.RequiredError('site','Required parameter requestParameters.site was null or undefined when calling getShips.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.site !== undefined) {
            queryParameters['site'] = requestParameters.site;
        }

        if (requestParameters.lang !== undefined) {
            queryParameters['lang'] = requestParameters.lang;
        }

        if (requestParameters.simple !== undefined) {
            queryParameters['simple'] = requestParameters.simple;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        const response = await this.request({
            path: `/ship/getShips`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ApiShipFromJSON));
    }

    /**
     * Get all ships
     */
    async getShips(site: string, lang?: string, simple?: boolean, initOverrides?: RequestInit): Promise<Array<ApiShip>> {
        const response = await this.getShipsRaw({ site: site, lang: lang, simple: simple }, initOverrides);
        return await response.value();
    }

    /**
     * Get Static Page Props
     */
    async getStaticPagePropsRaw(requestParameters: DefaultApiGetStaticPagePropsRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<ApiStaticPageProps>>> {
        if (requestParameters.tags === null || requestParameters.tags === undefined) {
            throw new runtime.RequiredError('tags','Required parameter requestParameters.tags was null or undefined when calling getStaticPageProps.');
        }

        if (requestParameters.site === null || requestParameters.site === undefined) {
            throw new runtime.RequiredError('site','Required parameter requestParameters.site was null or undefined when calling getStaticPageProps.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.tags !== undefined) {
            queryParameters['tags'] = requestParameters.tags;
        }

        if (requestParameters.site !== undefined) {
            queryParameters['site'] = requestParameters.site;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        const response = await this.request({
            path: `/tag/getStaticPageProps`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ApiStaticPagePropsFromJSON));
    }

    /**
     * Get Static Page Props
     */
    async getStaticPageProps(tags: string, site: string, initOverrides?: RequestInit): Promise<Array<ApiStaticPageProps>> {
        const response = await this.getStaticPagePropsRaw({ tags: tags, site: site }, initOverrides);
        return await response.value();
    }

    /**
     * Get travelType
     */
    async getTravelTypeRaw(requestParameters: DefaultApiGetTravelTypeRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ApiTravelType>> {
        if (requestParameters.name === null || requestParameters.name === undefined) {
            throw new runtime.RequiredError('name','Required parameter requestParameters.name was null or undefined when calling getTravelType.');
        }

        if (requestParameters.site === null || requestParameters.site === undefined) {
            throw new runtime.RequiredError('site','Required parameter requestParameters.site was null or undefined when calling getTravelType.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.site !== undefined) {
            queryParameters['site'] = requestParameters.site;
        }

        if (requestParameters.lang !== undefined) {
            queryParameters['lang'] = requestParameters.lang;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        const response = await this.request({
            path: `/travelType/{name}`.replace(`{${"name"}}`, encodeURIComponent(String(requestParameters.name))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiTravelTypeFromJSON(jsonValue));
    }

    /**
     * Get travelType
     */
    async getTravelType(name: string, site: string, lang?: string, initOverrides?: RequestInit): Promise<ApiTravelType> {
        const response = await this.getTravelTypeRaw({ name: name, site: site, lang: lang }, initOverrides);
        return await response.value();
    }

    /**
     * Get travelType by id
     */
    async getTravelTypeByIdRaw(requestParameters: DefaultApiGetTravelTypeByIdRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ApiTravelType>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getTravelTypeById.');
        }

        if (requestParameters.site === null || requestParameters.site === undefined) {
            throw new runtime.RequiredError('site','Required parameter requestParameters.site was null or undefined when calling getTravelTypeById.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        if (requestParameters.site !== undefined) {
            queryParameters['site'] = requestParameters.site;
        }

        if (requestParameters.lang !== undefined) {
            queryParameters['lang'] = requestParameters.lang;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        const response = await this.request({
            path: `/travelType/getTravelTypeById`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiTravelTypeFromJSON(jsonValue));
    }

    /**
     * Get travelType by id
     */
    async getTravelTypeById(id: string, site: string, lang?: string, initOverrides?: RequestInit): Promise<ApiTravelType> {
        const response = await this.getTravelTypeByIdRaw({ id: id, site: site, lang: lang }, initOverrides);
        return await response.value();
    }

    /**
     * Get all travelTypes
     */
    async getTravelTypesRaw(requestParameters: DefaultApiGetTravelTypesRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<ApiTravelType>>> {
        if (requestParameters.site === null || requestParameters.site === undefined) {
            throw new runtime.RequiredError('site','Required parameter requestParameters.site was null or undefined when calling getTravelTypes.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.site !== undefined) {
            queryParameters['site'] = requestParameters.site;
        }

        if (requestParameters.lang !== undefined) {
            queryParameters['lang'] = requestParameters.lang;
        }

        if (requestParameters.simple !== undefined) {
            queryParameters['simple'] = requestParameters.simple;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        const response = await this.request({
            path: `/travelType/getTravelTypes`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ApiTravelTypeFromJSON));
    }

    /**
     * Get all travelTypes
     */
    async getTravelTypes(site: string, lang?: string, simple?: boolean, initOverrides?: RequestInit): Promise<Array<ApiTravelType>> {
        const response = await this.getTravelTypesRaw({ site: site, lang: lang, simple: simple }, initOverrides);
        return await response.value();
    }

    /**
     * Get Insurance by CMS code
     */
    async insuranceByCmsCodeRaw(requestParameters: DefaultApiInsuranceByCmsCodeRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ApiInsurance>> {
        if (requestParameters.cmsCode === null || requestParameters.cmsCode === undefined) {
            throw new runtime.RequiredError('cmsCode','Required parameter requestParameters.cmsCode was null or undefined when calling insuranceByCmsCode.');
        }

        if (requestParameters.site === null || requestParameters.site === undefined) {
            throw new runtime.RequiredError('site','Required parameter requestParameters.site was null or undefined when calling insuranceByCmsCode.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.cmsCode !== undefined) {
            queryParameters['cmsCode'] = requestParameters.cmsCode;
        }

        if (requestParameters.site !== undefined) {
            queryParameters['site'] = requestParameters.site;
        }

        if (requestParameters.lang !== undefined) {
            queryParameters['lang'] = requestParameters.lang;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        const response = await this.request({
            path: `/insurance/insuranceByCmsCode`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiInsuranceFromJSON(jsonValue));
    }

    /**
     * Get Insurance by CMS code
     */
    async insuranceByCmsCode(cmsCode: string, site: string, lang?: string, initOverrides?: RequestInit): Promise<ApiInsurance> {
        const response = await this.insuranceByCmsCodeRaw({ cmsCode: cmsCode, site: site, lang: lang }, initOverrides);
        return await response.value();
    }

    /**
     * Update product state
     */
    async updateProductStateRaw(requestParameters: DefaultApiUpdateProductStateRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.codes === null || requestParameters.codes === undefined) {
            throw new runtime.RequiredError('codes','Required parameter requestParameters.codes was null or undefined when calling updateProductState.');
        }

        if (requestParameters.state === null || requestParameters.state === undefined) {
            throw new runtime.RequiredError('state','Required parameter requestParameters.state was null or undefined when calling updateProductState.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.codes !== undefined) {
            queryParameters['codes'] = requestParameters.codes;
        }

        if (requestParameters.state !== undefined) {
            queryParameters['state'] = requestParameters.state;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        const response = await this.request({
            path: `/product/updateProductState`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     * Update product state
     */
    async updateProductState(codes: Array<string>, state: ApiProductStateType, initOverrides?: RequestInit): Promise<string> {
        const response = await this.updateProductStateRaw({ codes: codes, state: state }, initOverrides);
        return await response.value();
    }

}

// custom code here ##################################################################################################################
// function toUTC(date) {
//   var date_utc =  Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), date.getHours(), date.getMinutes(), date.getSeconds());
//   return new Date(date_utc);
// }
