import React, { Dispatch, FC, RefObject, SetStateAction, useMemo } from 'react';
import { CollapseIndex, SearchProps } from '@/components/Search/SearchDesktopOverlay';
import { useMediaQuery } from '@ibe/components';
import { MEDIAQUERY_DEFAULTS } from '@/Util/globals';
import Tabs from '@/components/SearchForResults/Tabs';
import SearchForResultsDesktopOverlay from '@/components/SearchForResults/SearchForResultsDesktopOverlay';
import Pills from '@/components/SearchForResults/Pills';
import MonthSelectorTab from '@/components/SearchForResults/MonthSelectorTab';
import DepartureAirportTab from '@/components/SearchForResults/DepartureAirportTab';
import SearchForResultsMobileOverlay from '@/components/SearchForResults/SearchForResultsMobileOverlay';
import { ApiProductsCacheResponse } from '@ibe/api';
import { ReducerState } from '@/components/Search/useCheckboxStateReducer';

export const getProductsTotalResults = (
  checkboxState: ReducerState,
  productPackages: ApiProductsCacheResponse[],
  productsTotalResults: number
): number => {
  const currentNumberOfPackages = productPackages.length;
  if (
    Object.entries(checkboxState.items).some(([, item]) => item.type === 'airport') &&
    productsTotalResults > currentNumberOfPackages
  ) {
    return currentNumberOfPackages;
  } else {
    return productsTotalResults;
  }
};

const SearchForResultsMarkup: FC<
  SearchProps & {
    textSearchInputRef: RefObject<HTMLInputElement>;
    setCollapseOpen: Dispatch<SetStateAction<CollapseIndex>>;
    productsTotalCountry: number;
    productsTotalTravelType: number;
    hasSelectedItems: boolean;
    isDesktop: boolean;
    closeCollapse: () => void;
    singleRoomOnlyRadioChecked: boolean;
    setSingleRoomOnlyRadioChecked: Dispatch<SetStateAction<boolean>>;
    productPackages: ApiProductsCacheResponse[];
    initialProductPackages: ApiProductsCacheResponse[];
  }
> = ({
  activeIndex,
  setCollapseOpen,
  collapseOpen,
  continents,
  travelTypes,
  dates,
  checkboxState,
  checkboxDispatch,
  getCountriesResults,
  getTravelTypesResults,
  getDatesResults,
  productsTotalCountry,
  productsTotalTravelType,
  setSingleRoomOnlyRadioChecked,
  singleRoomOnlyRadioChecked,
  productsTotalResults,
  closeCollapse,
  isInitialSearch,
  productPackages,
  hasSelectedItems,
  isLoading,
  initialProductPackages
}): JSX.Element => {
  const isDesktopForResults = useMediaQuery({ type: 'min', query: MEDIAQUERY_DEFAULTS.xm });

  const calculatedProductsTotalResults = useMemo(() => {
    return getProductsTotalResults(checkboxState, productPackages, productsTotalResults);
  }, [checkboxState, productPackages, productsTotalResults]);

  return (
    <div className="search-for-results">
      {isDesktopForResults ? (
        <>
          <Tabs
            collapseOpen={activeIndex}
            setCollapseOpen={setCollapseOpen}
            isLoading={isLoading}
            monthSelectorTab={
              <MonthSelectorTab
                collapseOpen={activeIndex === 3}
                closeCollapse={closeCollapse}
                checkboxDispatch={checkboxDispatch}
                checkboxState={checkboxState}
                dates={dates}
                getCountriesResults={getCountriesResults}
                getTravelTypesResults={getTravelTypesResults}
                productsTotalResults={productsTotalResults}
                isLoading={isLoading}
              />
            }
            departureAirPortTab={
              <DepartureAirportTab
                collapseOpen={activeIndex === 4}
                closeCollapse={closeCollapse}
                productsTotalResults={productsTotalResults}
                productPackages={productPackages}
                checkboxState={checkboxState}
                checkboxDispatch={checkboxDispatch}
                isLoading={isLoading}
                initialProductPackages={initialProductPackages}
              />
            }
            singleRoomOnlyRadioChecked={singleRoomOnlyRadioChecked}
            setSingleRoomOnlyRadioChecked={setSingleRoomOnlyRadioChecked}
          />
          <SearchForResultsDesktopOverlay
            collapseOpen={collapseOpen}
            activeIndex={activeIndex}
            continents={continents}
            travelTypes={travelTypes}
            dates={dates}
            checkboxState={checkboxState}
            checkboxDispatch={checkboxDispatch}
            getCountriesResults={getCountriesResults}
            getTravelTypesResults={getTravelTypesResults}
            getDatesResults={getDatesResults}
            productsTotalCountry={productsTotalCountry}
            productsTotalTravelType={productsTotalTravelType}
            productsTotalResults={productsTotalResults}
            closeCollapse={closeCollapse}
            isLoading={isLoading}
            calculatedProductsTotalResults={calculatedProductsTotalResults}
          />
        </>
      ) : (
        <SearchForResultsMobileOverlay
          collapseOpen={collapseOpen}
          activeIndex={activeIndex}
          continents={continents}
          travelTypes={travelTypes}
          dates={dates}
          checkboxState={checkboxState}
          checkboxDispatch={checkboxDispatch}
          getCountriesResults={getCountriesResults}
          getTravelTypesResults={getTravelTypesResults}
          getDatesResults={getDatesResults}
          productsTotalCountry={productsTotalCountry}
          productsTotalTravelType={productsTotalTravelType}
          productsTotalResults={productsTotalResults}
          singleRoomOnlyRadioChecked={singleRoomOnlyRadioChecked}
          setSingleRoomOnlyRadioChecked={setSingleRoomOnlyRadioChecked}
          isInitialSearch={isInitialSearch}
          hasSelectedItems={hasSelectedItems}
          isLoading={isLoading}
        />
      )}
      <Pills
        checkboxState={checkboxState}
        checkboxDispatch={checkboxDispatch}
        getCountriesResults={getCountriesResults}
        getTravelTypesResults={getTravelTypesResults}
        getDatesResults={getDatesResults}
        isLoading={isLoading}
      />
    </div>
  );
};

export default SearchForResultsMarkup;
