'use client';

import React, { AnchorHTMLAttributes } from 'react';
import NextLink, { LinkProps } from 'next/link';
import { MagnoliaNode, LinkSwitchableMixin } from '@/types/cms/magnolia';
import { useGlobalMGLProps } from '@/Util/GlobalMGLPropsContext';
import { Props } from '@/types/cms/magnolia';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { facButtonCaret } from '@/Theme/SVG/Icons';

const HTTP = 'http://';
const HTTPS = 'https://';

export interface LinkTypeProps extends MagnoliaNode {
  title: string;
  openNewBrowserWindow?: boolean;
  className?: string;
  showCaret?: boolean;
}
export type SwitchableLinkProps = LinkTypeProps & LinkSwitchableMixin;
export type LinkPropsType = LinkProps & AnchorHTMLAttributes<HTMLAnchorElement>;

const Link = (props: SwitchableLinkProps) => {
  const { title, linkType, className, showCaret } = props;
  const linkAttrs = getNextLinkAttrs(props, useGlobalMGLProps());
  return (
    <NextLink
      className={classNames(linkType, className || '')}
      passHref
      {...linkAttrs}
      prefetch={false}
    >
      <span className="ignore-style">{title}</span>
      {showCaret && <FontAwesomeIcon icon={facButtonCaret} />}
    </NextLink>
  );
};

export const getNextLinkAttrs = (
  props: LinkSwitchableMixin & { openNewBrowserWindow?: boolean },
  globalProps?: Props | undefined
): LinkPropsType => {
  const {
    linkType,
    openNewBrowserWindow,
    linkTypeexternal,
    linkTypeinternal,
    linkTypedownload,
    linkTypepage,
    linkTypecontinent,
    linkTypecountry,
    linkTypecity,
    linkTypetravelType,
    linkTypeproduct
  } = props;

  const { rootNodePath, siteConfig } = globalProps ?? {};

  let linkProps: LinkPropsType = {
    href: '/',
    target: '_self'
  };
  if (openNewBrowserWindow || linkType === 'download') {
    linkProps = { ...linkProps, target: '_blank', rel: 'noopener noreferrer' };
  }
  if (linkType === 'external') {
    const extLink =
      linkTypeexternal?.startsWith(HTTP) || linkTypeexternal?.startsWith(HTTPS)
        ? linkTypeexternal
        : `${HTTPS}${linkTypeexternal}`;
    linkProps = { ...linkProps, passHref: true, href: extLink, target: '_blank' };
  }
  if (linkType === 'internal') {
    const intLink = linkTypeinternal?.startsWith('/') ? linkTypeinternal : '/' + linkTypeinternal;
    linkProps = { ...linkProps, passHref: true, href: intLink };
  }
  if (linkType === 'download') {
    linkProps = {
      ...linkProps,
      passHref: true,
      href: linkTypedownload?.['@link'] || '',
      download: true
    };
  }
  if (linkType === 'page' && rootNodePath) {
    let href = linkTypepage?.['@path']?.replace(rootNodePath, '') || '';
    if (!href) {
      href = '/';
    }
    linkProps = { ...linkProps, href: href || '' };
  }
  if (linkType === 'continent' && rootNodePath && siteConfig) {
    linkProps = {
      ...linkProps,
      href:
        siteConfig?.parameters?.continentDetailsPage
          ?.replace(rootNodePath, '')
          ?.replace('continent', linkTypecontinent?.metaInformation?.displayUrl || '') || ''
    };
  }
  if (linkType === 'country' && rootNodePath && siteConfig) {
    linkProps = {
      ...linkProps,
      href:
        siteConfig.parameters?.countryDetailsPage
          ?.replace(rootNodePath, '')
          ?.replace('continent', linkTypecountry?.continent?.metaInformation?.displayUrl || '')
          ?.replace('country', linkTypecountry?.metaInformation?.displayUrl || '') || ''
    };
  }
  if (linkType === 'city' && rootNodePath && siteConfig) {
    linkProps = {
      ...linkProps,
      href:
        siteConfig.parameters?.cityDetailsPage
          ?.replace(rootNodePath, '')
          ?.replace(
            'continent',
            linkTypecity?.country?.continent?.metaInformation?.displayUrl || ''
          )
          ?.replace('country', linkTypecity?.country?.metaInformation?.displayUrl || '')
          ?.replace('city', linkTypecity?.metaInformation?.displayUrl || '') || ''
    };
  }
  if (linkType === 'travelType' && rootNodePath && siteConfig) {
    linkProps = {
      ...linkProps,
      href:
        (siteConfig.parameters?.travelTypePage?.replace(rootNodePath, '') || '') +
        '/' +
        (linkTypetravelType?.travelTypePage?.['@name'] || linkTypetravelType?.['@name'] || '')
    };
  }
  if (linkType === 'product' && rootNodePath && siteConfig) {
    linkProps = {
      ...linkProps,
      href:
        (siteConfig.parameters?.productPage?.replace(rootNodePath, '') || '') +
          '/' +
          linkTypeproduct?.displayUrl || ''
    };
  }
  return linkProps;
};

export default Link;
