import React, { Dispatch, FC, SetStateAction, useMemo, useState } from 'react';
import {
  ApiAddItemRequestFromJSON,
  ApiOverlaySeat,
  ApiOverlaySeatMap,
  ApiSpecialRequestFromJSON,
  ApiSpecialRequestGroup,
  ApiTransfer
} from '@ibe/api';
import CheckoutStore from '@/templates/checkout/CheckoutStore';
import { observer } from 'mobx-react';
import SeatSelectionSeat from '@/components/busSelection/SeatSelectionSeat';
import { useCurrentLanguage } from '@/Util/CurrentLanguageProvider';
import classNames from 'classnames';
import { useMediaQuery } from '@ibe/components';
import { API_ITEM_SERVICE_CODE, MEDIAQUERY_DEFAULTS } from '@/Util/globals';
import { getServiceTypeCode } from '@/components/checkout/cart/getUpgrades';
import Image from 'next/image';
import SeatSelectionUpper from '@/components/busSelection/SeatSelectionUpper';
import { Collapse } from 'reactstrap';

export const BUS_TEASER_CONTAINER_ID = 'checkout-bus-teaser-container-id';

const SeatSelection: FC<{
  checkoutStore: CheckoutStore;
  seatMap: ApiOverlaySeatMap;
  seatMapImageUrl: string;
  bus: ApiTransfer;
  componentId: string;
  stepChangeTriggered: boolean;
  setIgnoreBusSeatValidation: Dispatch<SetStateAction<boolean>>;
  ignoreBusSeatValidation: boolean;
}> = observer(function SeatSelection({
  checkoutStore,
  seatMap,
  seatMapImageUrl,
  bus,
  componentId,
  stepChangeTriggered,
  setIgnoreBusSeatValidation,
  ignoreBusSeatValidation
}): JSX.Element {
  const locale = useCurrentLanguage();
  const isDesktop = useMediaQuery({ type: 'min', query: MEDIAQUERY_DEFAULTS.lg });
  const { booking: { travelers = [], bookedItems } = {} } = checkoutStore;
  const { height, width, seats } = seatMap;
  const mapAspectRatio = ((height || 0) / (width || 1)) * 100;
  const [selectedTraveler, setSelectedTraveler] = useState<string>(travelers?.[0]?.id || '');

  const selectedSeats = useMemo(() => {
    return (
      bookedItems
        ?.find(bookedItem => getServiceTypeCode(bookedItem) === API_ITEM_SERVICE_CODE.BU)
        ?.specialRequests?.filter(
          request => request.group === ApiSpecialRequestGroup.SEATRESERVATION
        ) || []
    );
  }, [bookedItems]);

  const fees = useMemo(() => {
    return (
      seatMap?.seats?.reduce((total: { [key: string]: number }, current) => {
        return !!current.feeCode && current.available && total[current.feeCode] === undefined
          ? { ...total, [current.feeCode]: Object.keys(total).length }
          : total;
      }, {}) || {}
    );
  }, [seatMap]);

  const selectSeat = async (seat: ApiOverlaySeat): Promise<void> => {
    await checkoutStore.selectItemsInCart(componentId, [bus?.units?.[0]?.id], {
      [bus?.units?.[0]?.id]: ApiAddItemRequestFromJSON({
        count: 1,
        date: bus.start,
        pickupStation: bus.pickupStation,
        dropoffStation: bus.dropoffStation,
        specialRequests: [
          ApiSpecialRequestFromJSON({
            id: seat.seatNumber,
            code: seat.seatNumber,
            travelerPosition: selectedTraveler,
            group: ApiSpecialRequestGroup.SEATRESERVATION
          }),
          ...selectedSeats
            .filter(
              selectedSeat =>
                !!selectedSeat.travelerPosition &&
                selectedSeat.travelerPosition.toString() !== selectedTraveler
            )
            .map(seat => ({
              id: seat.id,
              code: seat.id,
              travelerPosition: seat.travelerPosition,
              group: ApiSpecialRequestGroup.SEATRESERVATION
            }))
        ],
        notes: [],
        travelers: []
      })
    });
    const selectedTravelerIndex = travelers?.findIndex(
      traveler => traveler.id === selectedTraveler
    );
    if (selectedTravelerIndex > -1) {
      const foundNextTraveler =
        travelers?.[
          selectedTravelerIndex === (travelers?.length || 0) - 1 ? 0 : selectedTravelerIndex + 1
        ];
      if (!!foundNextTraveler) {
        setSelectedTraveler(foundNextTraveler.id);
      }
    }
  };

  const deselectAllSeats = async (): Promise<boolean> => {
    return await checkoutStore.selectItemsInCart(componentId, [bus?.units?.[0]?.id], {
      [bus?.units?.[0]?.id]: ApiAddItemRequestFromJSON({
        count: 0,
        date: bus.start,
        pickupStation: bus.pickupStation,
        dropoffStation: bus.dropoffStation,
        specialRequests: [],
        notes: [],
        travelers: []
      })
    });
  };

  return (
    <div
      id={BUS_TEASER_CONTAINER_ID}
      className={classNames('seat-selection', {
        'seat-selection--error':
          stepChangeTriggered && selectedSeats.length !== (travelers || []).length
      })}
    >
      <SeatSelectionUpper
        checkoutStore={checkoutStore}
        seatMap={seatMap}
        selectSeat={selectSeat}
        fees={fees}
        selectedSeats={selectedSeats}
        selectedTraveler={selectedTraveler}
        setSelectedTraveler={setSelectedTraveler}
        isDesktop={isDesktop}
        setIgnoreBusSeatValidation={setIgnoreBusSeatValidation}
        ignoreBusSeatValidation={ignoreBusSeatValidation}
        deselectAllSeats={deselectAllSeats}
      />
      <Collapse isOpen={!ignoreBusSeatValidation}>
        <div className="seat-selection__image seat-selection__image--padded">
          <div
            className="seat-selection__image__inner"
            style={{ paddingTop: `${mapAspectRatio}%` }}
          >
            {!!seatMapImageUrl && (
              <Image src={seatMapImageUrl} alt="seat map" height={height} width={width} />
            )}
            <div className="seat-selection__map">
              {seats?.map(seat => (
                <SeatSelectionSeat
                  key={seat.seatNumber}
                  seat={seat}
                  fees={fees}
                  height={height}
                  width={width}
                  selected={
                    !!selectedSeats.find(selectedSeat => selectedSeat.id === seat.seatNumber)
                  }
                  locale={locale}
                  onClick={selectSeat}
                  disableOnClick={!isDesktop}
                />
              ))}
            </div>
          </div>
        </div>
      </Collapse>
    </div>
  );
});

export default SeatSelection;
