'use client';

import React, { PropsWithChildren, Suspense, useEffect, useState } from 'react';
import createCache from '@emotion/cache';
import { ThemeProvider, CacheProvider } from '@emotion/react';
import getTheme from '../../Theme/theme';
import { enableStaticRendering } from 'mobx-react';
import { boot, initTranslations } from '@/Config/config';
import { LoggerFactory } from '@ibe/services';
import {
  GlobalTranslationFunction,
  GlobalTranslationContextProvider,
  IsNextEnvContext
} from '@ibe/components';
import isClient from '../../Util/globals';
import GlobalWindowProvider from '../../Util/GlobalWindowProvider';
import { ClientSideBootingFinishedContext } from '@/Hooks/useClientSideBootingFinished';
import { FontLoaderAndSetter } from '@/Theme/fonts';
import { Props } from '@/types/cms/magnolia';
import { IsBookingInitializedProvider } from '@/Hooks/useIsBookingInitialized';
import initTrackingSubscriptions, { broadcastEvent } from '@/Tracking/trackingSubscriptions';
import { GlobalMGLPropsContextProvider } from '@/Util/GlobalMGLPropsContext';
import { useMount } from 'react-use';
import { nameSpaces, useTranslation } from '@/app/i18n/client';
import CurrentLanguageProvider from '@/Util/CurrentLanguageProvider';
import ErrorBoundary from '@/components/ErrorBoundary';
import { fallbackLng } from '@/app/i18n/settings';
import AlbConfigModel from '@/Config/AlbConfigModel';
import { EventType } from '@/Tracking/types';
import { logger as localLogger } from '@/Util/globals';
import SuspenseFallbackComponent from '@/components/SuspenseFallbackComponent/SuspenseFallbackComponent';

const logger = LoggerFactory.get('App');

const cache = createCache({ key: 'next' });

export type ApplicationProps = {
  isProduction: boolean;
  pageProps: Props;
  locale: string;
  albConfig: AlbConfigModel;
  isLocal: string;
};

let timer: ReturnType<typeof setTimeout> | null = null;

const ContextProvider = ({
  pageProps,
  isProduction,
  children,
  locale,
  albConfig,
  isLocal
}: PropsWithChildren<ApplicationProps>) => {
  enableStaticRendering(!isClient());
  const { magnoliaContext, siteConfig, magnoliaHost, translationsVersion } = pageProps;
  const [clientSideBootingFinished, setClientSideBootingFinished] = useState<boolean>(false);

  (async (): Promise<void> => {
    if (!isClient()) {
      await initTranslations(isLocal, magnoliaHost, locale, translationsVersion);
    }
  })();

  useEffect(() => {
    return () => {
      if (!!timer) {
        clearTimeout(timer);
      }
    };
  }, []);

  useMount(
    async (): Promise<void> => {
      const config = await boot(
        !isProduction,
        albConfig,
        isLocal,
        magnoliaHost,
        locale,
        false,
        pageProps
      );
      if (
        !magnoliaContext?.isMagnoliaEdit &&
        !magnoliaContext?.isMagnolia &&
        siteConfig?.parameters?.useConsentManager
      ) {
        initTrackingSubscriptions(config, locale);
        logger.log('tracking initialized...');
        timer = setTimeout(() => {
          if (
            magnoliaContext.nodePath !== siteConfig.parameters.checkoutPage &&
            magnoliaContext.nodePath !== siteConfig.parameters.productPage
          ) {
            broadcastEvent(EventType.PAGE_VIEW, config, locale, {
              data: { pageProps }
            });
          }
        }, 400);
      }
      setClientSideBootingFinished(true);
    }
  );

  useEffect(() => {
    const onKeyup = (e: KeyboardEvent): void => {
      if (e.ctrlKey && e.shiftKey && e.altKey && e.code === 'KeyN' && !isProduction) {
        localLogger()(nameSpaces);
      }
    };

    document.addEventListener('keyup', onKeyup);

    return () => {
      document.removeEventListener('keyup', onKeyup);
    };
  }, []);

  return (
    <Suspense fallback={<SuspenseFallbackComponent logoOnly />}>
      <ErrorBoundary>
        <CacheProvider value={cache}>
          <GlobalWindowProvider>
            <GlobalTranslationContextProvider
              globalTranslation={(useTranslation as unknown) as GlobalTranslationFunction}
            >
              <CurrentLanguageProvider lng={locale || fallbackLng}>
                <IsNextEnvContext.Provider value={true}>
                  <GlobalMGLPropsContextProvider {...pageProps}>
                    <ClientSideBootingFinishedContext.Provider value={clientSideBootingFinished}>
                      <IsBookingInitializedProvider>
                        <ThemeProvider theme={getTheme()}>
                          <FontLoaderAndSetter>{children}</FontLoaderAndSetter>
                        </ThemeProvider>
                      </IsBookingInitializedProvider>
                    </ClientSideBootingFinishedContext.Provider>
                  </GlobalMGLPropsContextProvider>
                </IsNextEnvContext.Provider>
              </CurrentLanguageProvider>
            </GlobalTranslationContextProvider>
          </GlobalWindowProvider>
        </CacheProvider>
      </ErrorBoundary>
    </Suspense>
  );
};

export default ContextProvider;
