/* This file was generated by running "node scripts/generateTranslations.js". */
/* Created: 2024-10-13 16:16:22 */

const Keys = {
  'en-US': 'en-US',
  'en-GB': 'en-GB',
  'de-DE': 'de-DE',
  'fr-FR': 'fr-FR',
  'it-IT': 'it-IT',
  'cz-CZ': 'cz-CZ',
  cs: 'cs',
  currency: 'currency',
  language: 'language',
  settings: 'settings',
  cantChangeCurrency: 'cantChangeCurrency',
  infos: 'infos',
  withAlbatros: 'withAlbatros',
  search: 'search',
  product: 'product',
  destination: 'destination',
  otherPages: 'otherPages',
  faq: 'faq',
  seeAllResults: 'seeAllResults',
  productFull: 'productFull',
  destinationFull: 'destinationFull',
  otherPagesFull: 'otherPagesFull',
  faqFull: 'faqFull',
  productAll: 'productAll',
  destinationAll: 'destinationAll',
  otherPagesAll: 'otherPagesAll',
  faqAll: 'faqAll',
  results: 'results',
  learnMore: 'learnMore'
}; export default Keys;