'use client';

import React, { JSX, ReactElement } from 'react';
import Keys from '@/Translations/generated/da/country.json.keys';
import Map from '@/components/map/Map';
import { useTranslation } from '@/app/i18n/client';
import { THEMES } from '@/Util/globals';
import { ApiCountry } from '../../../api/model';
import { AspectRatio } from '@/Util/imageLoader';
import Intro from '@/components/intro/Intro';
import sanitize from '@/Util/sanitize';
import Breadcrumb from '@/components/breadcrumb/Breadcrumb';
import { useGlobalMGLProps } from '@/Util/GlobalMGLPropsContext';
import { usePathname } from 'next/navigation';
import { Props } from '@/types/cms/magnolia';

const CountryMap = ({
  country,
  breadcrumb,
  pageProps
}: {
  country?: ApiCountry;
  breadcrumb?: ReactElement;
  pageProps?: Props;
}): JSX.Element => {
  const { map, introTitleMap, introTextMap, aspectRatio, themeIntroMap } = country || {};
  const { t } = useTranslation('country');

  return (
    <>
      <Intro
        theme={themeIntroMap || THEMES.blue}
        headline={introTitleMap ? introTitleMap : `${t(Keys.headlineMap)} ${country?.name}`}
        breadcrumb={breadcrumb}
        singleColumn={
          <div>
            <div className="country-map__description">
              {introTextMap && (
                <div dangerouslySetInnerHTML={{ __html: sanitize(introTextMap, true) }} />
              )}
            </div>
            <div className="country-map__map-wrapper">
              <Map
                map={map}
                theme={themeIntroMap ? themeIntroMap : THEMES.darkGreen}
                aspectRatio={aspectRatio as AspectRatio}
                pageProps={pageProps}
              />
            </div>
          </div>
        }
      />
    </>
  );
};

export default CountryMap;
