'use client';

import React, { ReactElement, useMemo } from 'react';
import { useTranslation } from '@/app/i18n/client';
import { ApiCity } from '../../../api/model';
import Keys from '@/Translations/generated/da/city.json.keys';
import { THEMES } from '@/Util/globals';
import Intro from '@/components/intro/Intro';
import sanitize from '@/Util/sanitize';
import Breadcrumb from '@/components/breadcrumb/Breadcrumb';
import { useGlobalMGLProps } from '@/Util/GlobalMGLPropsContext';
import { usePathname } from 'next/navigation';

const CityTrips = ({
  city,
  breadcrumb
}: {
  city?: ApiCity;
  breadcrumb?: ReactElement;
}): ReactElement => {
  const { name, introTitleProducts, introTextProducts, themeIntroTrips } = city || {};
  const { t } = useTranslation('city');

  return (
    <>
      <Intro
        theme={themeIntroTrips || THEMES.blue}
        headline={introTitleProducts ? introTitleProducts : `${t(Keys.headlineTrips)} ${name}`}
        breadcrumb={breadcrumb}
        singleColumn={
          <div>
            {!!introTextProducts && (
              <div
                dangerouslySetInnerHTML={{
                  __html: sanitize(introTextProducts, true)
                }}
              />
            )}
          </div>
        }
      />
    </>
  );
};

export default CityTrips;
