import { Dispatch, SetStateAction, useEffect, useReducer } from 'react';
import { broadcastEvent } from '@/Tracking/trackingSubscriptions';
import { EventType, SearchCategory } from '@/Tracking/types';
import useConfig from '@/Hooks/useConfig';
import { useCurrentLanguage } from '@/Util/CurrentLanguageProvider';
import { useGlobalMGLProps } from '@/Util/GlobalMGLPropsContext';

type PayloadType = 'continent' | 'travelType' | 'date' | 'airport' | 'none';
export enum Action {
  ADD_OR_REMOVE_SINGLE = 'ADD_OR_REMOVE_SINGLE',
  ADD_OR_REMOVE_ALL_PER_TYPE = 'ADD_OR_REMOVE_ALL_PER_TYPE',
  ADD_OR_REMOVE_ALL = 'ADD_OR_REMOVE_ALL',
  REMOVE_CALLBACKS = 'REMOVE_CALLBACKS',
  REPLACE_STATE = 'REPLACE_STATE'
}

export type ReducerStateItem = {
  type: PayloadType;
  name: string;
  countries?: { [id: string]: string };
};

export type ReducerState = {
  items: { [id: string]: ReducerStateItem };
  callbacks: Array<(checkboxState: ReducerState, doGetProducts?: boolean) => Promise<void>>;
};

type Payload = {
  id: string;
  type: PayloadType;
  name: string;
  items?: Array<{ id: string; name: string }>;
  callbacks?: ReducerState['callbacks'];
  checkboxState?: ReducerState;
};

export type ReducerAction = {
  type: Action;
  payload: Payload;
};

const addCallbacks = (payload: Payload) =>
  !!payload.callbacks && payload.callbacks.length > 0 ? [...payload.callbacks] : [];

const reducer = (state: ReducerState, action: ReducerAction): ReducerState => {
  const { type, payload } = action;
  const newState = { ...state, callbacks: addCallbacks(payload) };
  switch (type) {
    case Action.ADD_OR_REMOVE_SINGLE:
      if (payload.type === 'continent' && !!payload.items) {
        let localState = { ...newState };
        payload.items.forEach(({ id, name }) => {
          if (localState.items[payload.id]?.countries?.[id]) {
            delete localState.items[payload.id]?.countries?.[id];
            if (
              !!localState.items[payload.id]?.countries &&
              Object.keys(localState.items[payload.id].countries || {}).length === 0
            ) {
              delete localState.items[payload.id];
            }
          } else {
            localState = {
              ...localState,
              items: {
                ...localState.items,
                [payload.id]: {
                  ...(localState.items[payload.id] || {}),
                  type: payload.type,
                  countries: {
                    ...(localState.items[payload.id]?.countries || {}),
                    [id]: name
                  }
                }
              }
            };
          }
        });
        return localState;
      } else if (
        payload.type === 'travelType' ||
        payload.type === 'date' ||
        payload.type === 'airport'
      ) {
        if (!!newState.items[payload.id]) {
          delete newState.items[payload.id];
          return newState;
        } else {
          return {
            ...newState,
            items: { ...newState.items, [payload.id]: { type: payload.type, name: payload.name } }
          };
        }
      } else {
        return { ...newState };
      }
    case Action.ADD_OR_REMOVE_ALL_PER_TYPE:
      if (payload.type === 'continent' && !!payload.items) {
        const countries = newState.items[payload.id]?.countries;
        const countriesList = !!countries ? Object.keys(countries) : [];
        if (payload.items.length > 0 && countriesList.length === payload.items.length) {
          delete newState.items[payload.id];
          return newState;
        } else {
          return {
            ...newState,
            items: {
              ...newState.items,
              [payload.id]: {
                ...(newState.items[payload.id] || {}),
                type: payload.type,
                countries: Object.fromEntries(payload.items.map(({ id, name }) => [id, name]))
              }
            }
          };
        }
      } else if ((payload.type === 'travelType' || payload.type === 'airport') && !!payload.items) {
        const itemsList = Object.entries(newState.items)
          .filter(([, item]) => item.type === payload.type)
          .map(([key]) => key);
        if (payload.items.length > 0 && itemsList.length === payload.items.length) {
          itemsList.forEach(key => {
            if (!!newState.items[key]) {
              delete newState.items[key];
            }
          });
          return newState;
        } else {
          let localState = { ...newState };
          payload.items.forEach(({ id, name }) => {
            localState = {
              ...localState,
              items: { ...localState.items, [id]: { type: payload.type, name: name } }
            };
          });
          return localState;
        }
      } else {
        return newState;
      }
    case Action.ADD_OR_REMOVE_ALL:
      return {
        ...newState,
        items: {}
      };
    case Action.REMOVE_CALLBACKS:
      return {
        ...newState,
        callbacks: []
      };
    case Action.REPLACE_STATE:
      if (!!payload.checkboxState) {
        return { ...payload.checkboxState };
      } else {
        return { ...newState };
      }
    default:
      return newState;
  }
};

export const useCheckboxStateReducer = (
  setIsLoading: Dispatch<SetStateAction<boolean>>
): [ReducerState, Dispatch<ReducerAction>] => {
  const config = useConfig();
  const locale = useCurrentLanguage();
  const pageProps = useGlobalMGLProps();
  const [state, dispatch] = useReducer<typeof reducer>(reducer, {
    items: {},
    callbacks: []
  });
  const { callbacks } = state;

  useEffect(() => {
    (async (): Promise<void> => {
      if (callbacks.length > 0) {
        setIsLoading(true);
        const callbacksList = callbacks.map((callback, idx) =>
          idx === callbacks.length - 1 ? callback(state, true) : callback(state)
        );
        await Promise.all(callbacksList);
        dispatch({ type: Action.REMOVE_CALLBACKS, payload: { id: '', type: 'none', name: '' } });
        broadcastEvent(EventType.SEARCH, config, locale, {
          search: { searchCategory: SearchCategory.TOUR },
          data: { pageProps }
        });
        setIsLoading(false);
      }
    })();
  }, [callbacks]);

  return [state, dispatch];
};
