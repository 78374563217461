'use client';

import React, { useMemo, useState } from 'react';
import { ApiContinent, ApiCountry, ApiImage } from '../../../api/model';
import Link from 'next/link';
import Image from '@/components/image/Image';
import Intro from '@/components/intro/Intro';
import { useTranslation } from '@/app/i18n/client';
import Keys from '@/Translations/generated/da/continent.json.keys';
import { useMediaQuery } from '@ibe/components';
import { MEDIAQUERY_DEFAULTS } from '@/Util/globals';
import { Props as PageProps } from '@/types/cms/magnolia';

type Props = {
  countries: ApiCountry[];
  continent: ApiContinent;
  pageProps?: PageProps;
};

const ContinentCountryListClient = (props: Props): JSX.Element => {
  const { countries, continent, pageProps } = props;
  const [image, setImage] = useState<ApiImage>(countries[0].teaserImage);

  const { t } = useTranslation('continent');
  const showImage = (country: ApiCountry) => {
    setImage(country.teaserImage);
  };
  const cols = useMediaQuery({ type: 'min', query: MEDIAQUERY_DEFAULTS.md }) ? 3 : 2;

  const shortArrays = useMemo(() => {
    let shortArrays: ApiCountry[][] = [];

    for (let i = 0; i < countries.length; i += countries.length / cols) {
      shortArrays.push(countries.slice(i, i + countries.length / cols));
    }
    return shortArrays;
  }, [countries, cols]);

  return (
    <>
      <div>
        <Intro
          className="continent-page__intro"
          theme={continent.themeCountryList}
          headline={
            continent.countryListTitle
              ? continent.countryListTitle
              : `${t(Keys.continentCountryListHeadline)} ${continent.name}`
          }
          leftSide={
            <div className="continent-page__countrylist">
              {shortArrays.map((countries, index1) => (
                <div key={index1}>
                  {countries.map((country, index2) => (
                    <div key={index2}>
                      <Link
                        className="continent-page__countrylist__link"
                        href={country.mainPage?.url || ''}
                        prefetch={false}
                        onMouseEnter={() => showImage(country)}
                      >
                        {country.name}
                      </Link>
                    </div>
                  ))}
                </div>
              ))}
            </div>
          }
          rightSide={
            <div>
              {!!image && (
                <Image
                  className="continent-page__countrylist__image"
                  src={image.imageLink || ''}
                  alt={image.title || ''}
                  width={image.width}
                  height={image.height}
                  aspectRatio="1-1"
                  serverSideContext={pageProps?.albConfig.context}
                />
              )}
            </div>
          }
        />
      </div>
    </>
  );
};

export default ContinentCountryListClient;
