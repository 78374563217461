import React, { Dispatch, FC, RefObject, SetStateAction, useMemo } from 'react';
import Variant from '@/components/ProductsDatesPrices/Variant';
import Keys from '@/Translations/generated/da/product.json.keys';
import dayjs from 'dayjs';
import { getDefaultFormatPrice } from '@/Util/globals';
import Button from '@/components/Button';
import { useTranslation } from '@/app/i18n/client';
import useConfig from '@/Hooks/useConfig';
import { useCurrentLanguage } from '@/Util/CurrentLanguageProvider';
import { MagnoliaSite } from '@/types/cms/magnolia';
import { ApiProduct } from '../../../api/model';
import { ApiProductsCacheData, ApiProductsCacheResponse } from '@ibe/api';
import classNames from 'classnames';
import { getProductByOccupancy } from '@/components/Search/helpersProduct';
import { useRouter } from 'next/navigation';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLink } from '@fortawesome/free-solid-svg-icons';
import { getVariantParam } from '@/components/ProductsDatesPrices/getVariantParam';
import { useIsComponentMounted } from '@/Hooks/useIsComponentMounted';
import { isVariantActive } from '@/components/ProductsDatesPrices/DesktopContent';

const MobileContentItem: FC<{
  productPackage: ApiProductsCacheResponse;
  siteConfig?: MagnoliaSite;
  isVariant?: boolean;
  product?: ApiProduct;
  originalProduct?: ApiProduct;
  isDesktop: boolean;
  containerRef: RefObject<HTMLDivElement>;
  goToCheckout: (item: ApiProductsCacheResponse, variantParam?: string) => void;
  selectedPackage?: ApiProductsCacheResponse;
  variantUrl: string;
  setLoading: Dispatch<SetStateAction<boolean>>;
}> = ({
  productPackage,
  containerRef,
  goToCheckout,
  isDesktop,
  isVariant,
  product,
  selectedPackage,
  originalProduct,
  variantUrl,
  setLoading
}): JSX.Element => {
  const { t } = useTranslation('product');
  const router = useRouter();
  const config = useConfig();
  const language = useCurrentLanguage();
  const isMounted = useIsComponentMounted();

  const productByOccupancy = useMemo(
    (): ApiProductsCacheData | undefined => getProductByOccupancy(productPackage),
    [productPackage, productPackage.products]
  );
  const almostSoldOut =
    (productByOccupancy?.availability || 1) / (productByOccupancy?.maxAvailability || 1) < 0.2;

  const hasAvailabilities = useMemo((): boolean | undefined => {
    return productPackage.products?.some(cacheProduct => (cacheProduct?.availability || 0) > 0);
  }, [productPackage]);

  const isSelected = useMemo((): boolean => {
    const selectedDate = dayjs(selectedPackage?.products?.[0]?.travelStartDate);
    const currentDate = dayjs(productPackage.products?.[0]?.travelStartDate);
    return (
      selectedDate.isValid() && currentDate.isValid() && selectedDate.isSame(currentDate, 'day')
    );
  }, [productPackage, selectedPackage]);

  const handleLinkClick = (): void => {
    if (!!variantUrl) {
      setLoading(true);
      router.push(variantUrl);
    }
  };

  const handleButtonClick = (): void => {
    if (!!productPackage?.products?.[0].bookingLink) {
      window.open(productPackage.products[0].bookingLink, '_blank');
    } else if (!!productByOccupancy) {
      goToCheckout(
        productByOccupancy,
        getVariantParam(
          productByOccupancy?.travelStartDate || '',
          product?.variants,
          productPackage.packageCode,
          originalProduct?.name
        )
      );
    }
  };

  return (
    <div
      className={classNames('dates-prices__product', {
        'dates-prices__product--active': isSelected
      })}
    >
      {!isSelected && (
        <div className="dates-prices__product__item--variant">
          <Variant
            isVariant={isVariant}
            product={product}
            isDesktop={isDesktop}
            containerRef={containerRef}
            productPackage={productPackage}
            variantUrl={variantUrl}
            setLoading={setLoading}
          />
          {!isVariantActive(productPackage, product) && (
            <div className="dates-prices__product__item__link" onClick={handleLinkClick}>
              <FontAwesomeIcon icon={faLink} />
              <span>{t(Keys.select)}</span>
            </div>
          )}
        </div>
      )}
      <div className="dates-prices__product__item">
        <div>{t(Keys.departure)}</div>
        {!!productByOccupancy?.travelStartDate && (
          <div>
            {isMounted &&
              dayjs(productByOccupancy.travelStartDate).format(config.displayFormatDate[language])}
          </div>
        )}
      </div>
      <div className="dates-prices__product__item">
        <div>{t(Keys.price)}</div>
        <div>{getDefaultFormatPrice(productByOccupancy?.startingPriceAmount || 0, language)}</div>
      </div>
      <div className="dates-prices__product__item">
        <div>{t(Keys.duration)}</div>
        <div>{t(Keys.day, { count: productByOccupancy?.duration || 0 })}</div>
      </div>
      <div className="dates-prices__product__item">
        <div>{t(Keys.departureFrom)}</div>
        <div>{productByOccupancy?.departureAirport || ''}</div>
      </div>
      {(almostSoldOut || !productByOccupancy?.tourGuide) && (
        <div className="dates-prices__product__item">
          <div>{t(Keys.note)}</div>
          <div>{almostSoldOut ? t(Keys.almostSoldOut) : productByOccupancy?.tourGuide || ''}</div>
        </div>
      )}
      <div className="dates-prices__product__button dates-prices__button theme-dark-green">
        <Button
          color="primary"
          showCaret={hasAvailabilities}
          disabled={!hasAvailabilities}
          onClick={handleButtonClick}
        >
          {hasAvailabilities ? t(Keys.book) : t(Keys.soldOut)}
        </Button>
      </div>
    </div>
  );
};

export default MobileContentItem;
