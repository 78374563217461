'use client';

import React, { ReactElement } from 'react';

type Props = {
  name?: string;
  showButton?: boolean;
  theme?: string;
  leftSide?: ReactElement;
  rightSide?: ReactElement;
  headline?: string;
  disableBreadcrumb?: boolean;
  singleColumn?: ReactElement;
  breadcrumb?: ReactElement;
  className?: string;
};

const Intro = (props: Props): ReactElement => {
  const {
    theme,
    leftSide,
    rightSide,
    headline,
    disableBreadcrumb,
    singleColumn,
    breadcrumb,
    className
  } = props;

  return (
    <>
      <div className={`intro ${theme || ''} ${className || ''}`}>
        {!disableBreadcrumb && breadcrumb}
        {!!headline && <h2 className="intro__container__headline">{headline}</h2>}
        <div className="intro__container d-lg-flex">
          {!!singleColumn ? (
            <div className="intro__container__single">{singleColumn}</div>
          ) : (
            <>
              <div className="intro__container__left">{!!leftSide ? leftSide : <></>}</div>
              <div className="intro__container__right">{!!rightSide ? rightSide : <></>}</div>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default Intro;
