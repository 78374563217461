import { useMount } from 'react-use';
import { CHECKOUT_PARAMS_PARAMETER, logger, PACKAGE_CART_ID_PARAMETER } from '@/Util/globals';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { getParticipantCount, SelectedOptions } from '@/components/productSelection/optionsData';
import { prepareCheckoutParamsData } from '@/components/ProductsDatesPrices/ProductsDatesPrices';
import {
  ApiComponentType,
  ApiFlightItem,
  ApiItemType,
  ApiPackageCart,
  ApiProductsCacheData
} from '@ibe/api';
import { usePathname, useSearchParams } from 'next/navigation';
import { useApi, useWindow } from '@ibe/components';
import { ApiProduct } from '../../../api/model';

export const CHECKOUT_CUSTOM_EVENT = 'checkout:custom:event';

export const useCheckoutPageHelpers = (
  selectedOptions: SelectedOptions,
  setPackageCart: Dispatch<SetStateAction<ApiPackageCart | undefined>>,
  setSelectedPacificProduct: Dispatch<SetStateAction<ApiProductsCacheData | undefined>>,
  setFlights: Dispatch<SetStateAction<ApiFlightItem[] | undefined>>,
  selectedPacificProduct?: ApiProductsCacheData,
  packageCart?: ApiPackageCart,
  pacificProducts?: ApiProductsCacheData[],
  product?: ApiProduct,
  flights?: ApiFlightItem[]
): { isLoading: boolean; setIsLoading: Dispatch<SetStateAction<boolean>> } => {
  const window = useWindow();
  const pathname = usePathname();
  const searchParams = useSearchParams();
  const api = useApi();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useMount(() => {
    if (!!packageCart?.id) {
      const newParams = new URLSearchParams(searchParams.toString());
      newParams.set(PACKAGE_CART_ID_PARAMETER, packageCart.id);
      const newUrl = `${pathname}?${newParams.toString()}`;
      window?.history?.replaceState(
        { ...window?.history?.state, as: newUrl, url: newUrl },
        '',
        newUrl
      );
    }
  });

  useEffect(() => {
    (async () => {
      const newSelectedPacificProduct = pacificProducts?.find(
        pacificProduct =>
          getParticipantCount(pacificProduct) === selectedOptions.participants &&
          pacificProduct.travelStartDate === selectedOptions.departureDate &&
          pacificProduct.departureAirport === selectedOptions.departureAirport
      );
      if (
        !!newSelectedPacificProduct?.packageId &&
        (newSelectedPacificProduct.departureAirport !== selectedPacificProduct?.departureAirport ||
          newSelectedPacificProduct.travelStartDate !== selectedPacificProduct?.travelStartDate ||
          newSelectedPacificProduct.serviceUnitCode !== selectedPacificProduct?.serviceUnitCode)
      ) {
        setIsLoading(true);
        try {
          setSelectedPacificProduct(newSelectedPacificProduct);
          if (!!packageCart?.id) {
            try {
              await api.remove(packageCart.id);
            } catch (err) {
              logger('error')('Unable to remove package cart: ', err);
            }
          }
          const newPackageCart = await api.start(
            newSelectedPacificProduct.packageId,
            'EMPTY',
            true,
            undefined
          );
          if (!!newPackageCart) {
            setPackageCart(newPackageCart);
            setFlights(
              newPackageCart?.packageModel?.packageDetails?.[0]?.components
                ?.filter(
                  item =>
                    item.itemType === ApiItemType.FLIGHT &&
                    item.componentType === ApiComponentType.REQUIRED
                )
                ?.flatMap(component => component?.selectedItems) as ApiFlightItem[]
            );
          }
          const checkoutParamsData = prepareCheckoutParamsData(
            newSelectedPacificProduct.packageId || '',
            product?.displayUrl || '',
            product?.name || '',
            -1,
            '',
            window
          );
          if (!!checkoutParamsData) {
            const currentSearchParams = new URLSearchParams();
            currentSearchParams.set(CHECKOUT_PARAMS_PARAMETER, checkoutParamsData);
            if (!!newPackageCart?.id) {
              currentSearchParams.set(PACKAGE_CART_ID_PARAMETER, newPackageCart.id);
            }
            const newUrl = `${pathname}${
              !!currentSearchParams.toString() ? `?${currentSearchParams.toString()}` : ''
            }`;
            window?.history?.replaceState(
              { ...window?.history?.state, as: newUrl, url: newUrl },
              '',
              newUrl
            );

            const customEvent = new CustomEvent<{
              pacificProduct: ApiProductsCacheData;
            }>(CHECKOUT_CUSTOM_EVENT, {
              detail: { pacificProduct: newSelectedPacificProduct }
            });
            document.dispatchEvent(customEvent);
          }
        } catch (err) {
          logger('error')('Failed setting up new cart: ', err);
        } finally {
          setIsLoading(false);
        }
      }
    })();
  }, [
    selectedOptions,
    packageCart,
    selectedOptions,
    selectedPacificProduct,
    pacificProducts,
    product,
    searchParams,
    flights
  ]);

  return {
    isLoading,
    setIsLoading
  };
};
