import React, { FC, JSX, useEffect, useState } from 'react';
import Keys from '@/Translations/generated/da/search.json.keys';
import classNames from 'classnames';
import { useTranslation } from '@/app/i18n/client';
import { SearchProps } from '@/components/Search/SearchDesktopOverlay';
import CheckboxResults from '@/components/Search/CheckboxResults';
import { Action } from '@/components/Search/useCheckboxStateReducer';
import { Spinner, useHorizontalDrag } from '@ibe/components';
import Button from '@/components/Button';
import { ApiContinentComponent, ApiCountryComponent } from '@ibe/api';
// import { numberOfProductsPerContinent } from '@/components/Search/CountriesSearchMobile';

export const continentsWithCountries = (
  continents: ApiContinentComponent[]
): ApiContinentComponent[] => {
  return continents.filter(continent =>
    continent?.countries?.some(country => (country.products || 0) > 0)
  );
};

const CountriesSearchTab: FC<
  Pick<
    SearchProps,
    | 'continents'
    | 'checkboxState'
    | 'getTravelTypesResults'
    | 'getDatesResults'
    | 'checkboxDispatch'
    | 'productsTotalResults'
    | 'isLoading'
  > & {
    fullSearchView?: boolean;
    productsTotalCountry: number;
    closeCollapse?: () => void;
    calculatedProductsTotalResults?: number;
  }
> = ({
  continents,
  checkboxState,
  checkboxDispatch,
  getTravelTypesResults,
  fullSearchView,
  productsTotalResults,
  closeCollapse,
  getDatesResults,
  isLoading,
  calculatedProductsTotalResults
}): JSX.Element => {
  const { t } = useTranslation('search');
  const [selectedContinent, setSelectedContinent] = useState<ApiContinentComponent | undefined>(
    undefined
  );

  const { isDown } = useHorizontalDrag('search-content-continent-navigation', 250);

  useEffect(() => {
    setSelectedContinent(continentsWithCountries(continents)?.[0]);
  }, []);

  // const checkAllCountries = async (): Promise<void> => {
  //   checkboxDispatch({
  //     type: Action.ADD_OR_REMOVE_ALL_PER_TYPE,
  //     payload: {
  //       id: selectedContinent?.id || '',
  //       type: 'continent',
  //       name: selectedContinent?.name || '',
  //       items:
  //         selectedContinent?.countries
  //           ?.filter(country => !!country?.products && country.products > 0)
  //           .map(country => ({
  //             id: country.id || '',
  //             name: country.name
  //           })) || [],
  //       callbacks: [getTravelTypesResults, getDatesResults]
  //     }
  //   });
  // };

  const checkSingleCountry = async (country: ApiCountryComponent): Promise<void> => {
    checkboxDispatch({
      type: Action.ADD_OR_REMOVE_SINGLE,
      payload: {
        id: country?.continent || '',
        type: 'continent',
        name: selectedContinent?.name || '',
        items: [{ id: country?.id || '', name: country.name }],
        callbacks: [getTravelTypesResults, getDatesResults]
      }
    });
  };

  return (
    <>
      <div
        id="search-content-continent-navigation"
        className={classNames(
          'search__content__continents',
          {
            'search__content__continents--grab': isDown
          },
          { 'search__content__continents--full-search': fullSearchView }
        )}
      >
        {continentsWithCountries(continents).map(continent => (
          <div
            key={continent.id}
            className={classNames('search__content__continent', {
              'search__content__continent--active': continent.id === selectedContinent?.id
            })}
            onClick={(): void => setSelectedContinent(continent)}
            draggable={false}
          >
            {continent.name}
          </div>
        ))}
      </div>
      {fullSearchView && <hr className="search__content__hr" />}
      <div
        className={classNames('search__content search__content--checkboxes', {
          'search__content--checkboxes-expanded': fullSearchView
        })}
      >
        {/* maybe later... */}
        {/*<CheckboxResults*/}
        {/*  key="all"*/}
        {/*  label={t(Keys.all)}*/}
        {/*  checked={*/}
        {/*    Object.keys(checkboxState.items[selectedContinent?.id || '']?.countries || {})*/}
        {/*      ?.length ===*/}
        {/*    selectedContinent?.countries?.filter(country => (country?.products || 0) > 0)?.length*/}
        {/*  }*/}
        {/*  onChange={checkAllCountries}*/}
        {/*  results={numberOfProductsPerContinent(selectedContinent)}*/}
        {/*  isLoading={isLoading}*/}
        {/*/>*/}
        {selectedContinent?.countries
          ?.filter(country => (country.products || 0) > 0)
          ?.map(country => (
            <CheckboxResults
              key={country.id}
              label={country.name}
              checked={
                !!country?.id &&
                !!country?.continent &&
                !!checkboxState.items[country.continent]?.countries?.[country.id]
              }
              onChange={(): Promise<void> => checkSingleCountry(country)}
              results={country.products || 0}
              isLoading={isLoading}
            />
          ))}
      </div>
      {fullSearchView && (
        <div className="search__content__submit">
          <Button color="primary" onClick={closeCollapse} disabled={isLoading}>
            <span>{`${t(Keys.apply)} (${
              calculatedProductsTotalResults !== undefined
                ? calculatedProductsTotalResults
                : productsTotalResults
            })`}</span>
            {isLoading && <Spinner />}
          </Button>
        </div>
      )}
    </>
  );
};

export default CountriesSearchTab;
