'use client';

import React, { FC, JSX, useEffect, useMemo, useRef, useState } from 'react';
import { useMediaQuery } from '@ibe/components';
import {
  FROM_SEARCH_PARAMETER,
  getProductPageBasePath,
  getResultsPageBasePath,
  MEDIAQUERY_DEFAULTS,
  searchDataSessionStorage
} from '@/Util/globals';
import { CollapseIndex } from '@/components/Search/SearchDesktopOverlay';
import { MagnoliaSite, Props } from '@/types/cms/magnolia';
import { Action, useCheckboxStateReducer } from '@/components/Search/useCheckboxStateReducer';
import { useRouter, useSearchParams } from 'next/navigation';
import { useRequests } from '@/components/Search/useRequests';
import {
  ApiContinentComponent,
  ApiDatesSearchResponseDates,
  ApiProductsCacheResponse,
  ApiTextSearchResponse,
  ApiTravelTypeComponent
} from '@ibe/api';
import ResultsList from '@/components/SearchForResults/ResultsList';
import SearchUI from '@/components/Search/SearchUI';
import {
  getCheckboxStateItemsFromSearchParamsQuery,
  getFilteredProductPackages,
  getSearchParamsItemTypes,
  getUrlWithUpdatedQueryParams,
  hasSelectedItems
} from '@/components/Search/helpers';
import { broadcastEvent } from '@/Tracking/trackingSubscriptions';
import { EventType, SearchCategory } from '@/Tracking/types';
import useConfig from '@/Hooks/useConfig';
import { useCurrentLanguage } from '@/Util/CurrentLanguageProvider';

const SearchInner: FC<{
  isInitialSearch?: boolean;
  initialData: {
    continents: ApiContinentComponent[];
    travelTypes: ApiTravelTypeComponent[];
    dates: ApiDatesSearchResponseDates[];
    productPackages: ApiProductsCacheResponse[];
    initialProductsTotalCountry: number;
    initialProductsTotalTravelType: number;
    initialProductsTotalOverall: number;
  };
  siteConfig?: MagnoliaSite;
  totalNumberOfProducts: number;
  rootNodePath: string;
  pageProps?: Props;
}> = ({
  isInitialSearch,
  initialData,
  siteConfig,
  totalNumberOfProducts,
  rootNodePath,
  pageProps
}): JSX.Element => {
  const router = useRouter();
  const searchParams = useSearchParams();
  const config = useConfig();
  const locale = useCurrentLanguage();
  const isDesktop = useMediaQuery({ type: 'min', query: MEDIAQUERY_DEFAULTS.md });
  const [inputValue, setInputValue] = useState<string>('');
  const [collapseOpen, setCollapseOpen] = useState<CollapseIndex>(0);
  const [continents, setContinents] = useState<ApiContinentComponent[]>(
    initialData.continents || []
  );
  const [travelTypes, setTravelTypes] = useState<ApiTravelTypeComponent[]>(
    initialData.travelTypes || []
  );
  const [dates, setDates] = useState<ApiDatesSearchResponseDates[]>(initialData.dates || []);
  const [productsTotalCountry, setProductsTotalCountry] = useState<number>(
    initialData.initialProductsTotalCountry
  );
  const [productsTotalTravelType, setProductsTotalTravelType] = useState<number>(
    initialData.initialProductsTotalTravelType
  );
  const [productsTotalOverall, setProductsTotalOverall] = useState<number>(
    initialData.initialProductsTotalOverall
  );
  const [textSearch, setTextSearch] = useState<Required<ApiTextSearchResponse>>({
    countries: [],
    products: []
  });
  const [productPackages, setProductPackages] = useState<ApiProductsCacheResponse[]>(
    initialData.productPackages || []
  );
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const timeout = useRef<ReturnType<typeof setTimeout> | null>(null);
  const textSearchInputRef = useRef<HTMLInputElement>(null);
  const [singleRoomOnlyRadioChecked, setSingleRoomOnlyRadioChecked] = useState<boolean>(false);
  const [hideUnavailableTripsChecked, setHideUnavailableTripsChecked] = useState<boolean>(false);
  const [checkboxState, checkboxDispatch] = useCheckboxStateReducer(setIsLoading);

  const {
    getTravelTypesResults,
    getCountriesResults,
    getDatesResults,
    getTextSearchResults
  } = useRequests(
    setIsLoading,
    setTextSearch,
    setContinents,
    setProductsTotalCountry,
    setTravelTypes,
    setProductsTotalTravelType,
    setDates,
    setProductPackages,
    setProductsTotalOverall,
    pageProps,
    siteConfig,
    isInitialSearch
  );

  useEffect(() => {
    const checkboxStateItemsFromStorage = searchDataSessionStorage.get();
    const checkboxStateItemsFromQuery =
      travelTypes.length > 0 && continents.length > 0
        ? getCheckboxStateItemsFromSearchParamsQuery(searchParams, travelTypes, continents)
        : null;
    const searchParamsItemTypes = getSearchParamsItemTypes(checkboxStateItemsFromQuery);
    if (
      (searchDataSessionStorage.exists() && !!checkboxStateItemsFromStorage) ||
      !!checkboxStateItemsFromQuery
    ) {
      checkboxDispatch({
        type: Action.REPLACE_STATE,
        payload: {
          id: '',
          type: 'none',
          name: '',
          checkboxState: {
            items: (checkboxStateItemsFromStorage || checkboxStateItemsFromQuery)!,
            callbacks:
              searchParamsItemTypes.includes('continent') &&
              !searchParamsItemTypes.includes('date') &&
              !searchParamsItemTypes.includes('travelType')
                ? [getTravelTypesResults, getDatesResults]
                : !searchParamsItemTypes.includes('continent') &&
                  searchParamsItemTypes.includes('date') &&
                  !searchParamsItemTypes.includes('travelType')
                ? [getCountriesResults, getTravelTypesResults]
                : !searchParamsItemTypes.includes('continent') &&
                  !searchParamsItemTypes.includes('date') &&
                  searchParamsItemTypes.includes('travelType')
                ? [getCountriesResults, getDatesResults]
                : [getCountriesResults, getTravelTypesResults, getDatesResults]
          }
        }
      });
      searchDataSessionStorage.clear();
    }
  }, []);

  useEffect(() => {
    if (!!timeout.current) {
      clearTimeout(timeout.current);
    }
    if (inputValue.length > 0) {
      timeout.current = setTimeout((): Promise<void> => getTextSearchResults(inputValue), 300);
    }

    return () => {
      if (!!timeout.current) {
        clearTimeout(timeout.current);
      }
    };
  }, [inputValue]);

  const filteredProductPackages = useMemo(
    (): ApiProductsCacheResponse[] =>
      getFilteredProductPackages(
        checkboxState,
        productPackages,
        singleRoomOnlyRadioChecked,
        hideUnavailableTripsChecked
      ),
    [
      productPackages,
      singleRoomOnlyRadioChecked,
      hideUnavailableTripsChecked,
      checkboxState,
      checkboxState.items,
      Object.keys(checkboxState.items).length
    ]
  );

  const handleInputChange = (value: string): void => {
    let newTextSearch = { ...textSearch };
    if (!!newTextSearch.countries && newTextSearch.countries.length > 0) {
      newTextSearch = {
        ...newTextSearch,
        countries: []
      };
    }
    if (!!newTextSearch.products && newTextSearch.products.length > 0) {
      newTextSearch = {
        ...newTextSearch,
        products: []
      };
    }
    setTextSearch(newTextSearch);
    setInputValue(value);
  };

  const closeCollapse = (): void => {
    setCollapseOpen(0);
  };

  const goToResultsPage = (): void => {
    broadcastEvent(EventType.SEARCH, config, locale, {
      search: { searchCategory: SearchCategory.TOUR },
      data: {
        pageProps,
        doBroadcastHomePageSearchEvent: true
      }
    });
    searchDataSessionStorage.set(checkboxState.items);
    const resultsPage = getResultsPageBasePath(rootNodePath, siteConfig);
    router.push(
      `${resultsPage}${getUrlWithUpdatedQueryParams(checkboxState, searchParams, undefined, [
        [FROM_SEARCH_PARAMETER, 'true']
      ])}`
    );
  };

  return (
    <>
      <SearchUI
        collapseOpen={collapseOpen > 0}
        activeIndex={collapseOpen}
        inputValue={inputValue}
        handleInputChange={handleInputChange}
        totalNumberOfProducts={totalNumberOfProducts}
        continents={continents}
        travelTypes={travelTypes}
        dates={dates}
        textSearch={textSearch}
        checkboxDispatch={checkboxDispatch}
        checkboxState={checkboxState}
        textSearchInputRef={textSearchInputRef}
        goToResultsPage={goToResultsPage}
        isLoading={isLoading}
        isDesktop={isDesktop}
        productPageBasePath={getProductPageBasePath(rootNodePath, siteConfig)}
        getCountriesResults={getCountriesResults}
        getTravelTypesResults={getTravelTypesResults}
        getDatesResults={getDatesResults}
        setCollapseOpen={setCollapseOpen}
        productsTotalCountry={productsTotalCountry}
        productsTotalTravelType={productsTotalTravelType}
        productsTotalResults={productsTotalOverall}
        hasSelectedItems={hasSelectedItems(checkboxState)}
        closeCollapse={closeCollapse}
        isInitialSearch={isInitialSearch}
        singleRoomOnlyRadioChecked={singleRoomOnlyRadioChecked}
        setSingleRoomOnlyRadioChecked={setSingleRoomOnlyRadioChecked}
        productPackages={filteredProductPackages}
        resultsPageBasePath={getResultsPageBasePath(rootNodePath, siteConfig)}
        initialProductPackages={initialData.productPackages}
      />
      {!isInitialSearch && (
        <ResultsList
          productPackages={filteredProductPackages}
          hideUnavailableTripsChecked={hideUnavailableTripsChecked}
          setHideUnavailableTripsChecked={setHideUnavailableTripsChecked}
          productPageBasePath={getProductPageBasePath(rootNodePath, siteConfig)}
          isLoading={isLoading}
          pageProps={pageProps}
        />
      )}
    </>
  );
};

export default SearchInner;
