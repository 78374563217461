'use client';

import React, { MouseEvent, ReactElement } from 'react';
import Keys from '@/Translations/generated/da/city.json.keys';
import { smoothScroll, THEMES } from '@/Util/globals';
import { useTranslation } from '@/app/i18n/client';
import { ApiCity } from '../../../api/model';
import Intro from '@/components/intro/Intro';
import sanitize from '@/Util/sanitize';
import Breadcrumb from '@/components/breadcrumb/Breadcrumb';
import { useGlobalMGLProps } from '@/Util/GlobalMGLPropsContext';
import { usePathname } from 'next/navigation';

const CityMain = ({
  city,
  breadcrumb
}: {
  city?: ApiCity;
  breadcrumb?: ReactElement;
}): ReactElement => {
  const {
    name,
    introTitleGeneral,
    introTextGeneral,
    language,
    citizens,
    foundation,
    themeIntroMain
  } = city || {};
  const { t } = useTranslation('city');

  return (
    <>
      <Intro
        theme={themeIntroMain || THEMES.blue}
        headline={introTitleGeneral ? introTitleGeneral : `${t(Keys.headline)} ${name}`}
        breadcrumb={breadcrumb}
        leftSide={
          <div className="mb-xs-3 mb-md-0">
            {!!introTextGeneral && (
              <div
                dangerouslySetInnerHTML={{
                  __html: sanitize(introTextGeneral, true)
                }}
              />
            )}
            <a
              className="city-main__container__link"
              onClick={(e: MouseEvent<HTMLAnchorElement>): void => smoothScroll('contentArea', e)}
            >
              {t(Keys.moreInformation)} {' >'}
            </a>
          </div>
        }
        rightSide={
          <div className="">
            <div className="d-sm-flex">
              {!!citizens && (
                <div className="city-main__container__inner__citizens">
                  <div className="city-main__container__inner__headline">{t(Keys.citizens)}</div>
                  <div className="city-main__container__inner__text">{citizens}</div>
                </div>
              )}
              {!!foundation && (
                <div className="city-main__container__inner__foundation">
                  <div className="city-main__container__inner__headline">{t(Keys.foundation)}</div>
                  <div className="city-main__container__inner__text">{foundation}</div>
                </div>
              )}
            </div>
            <div className="city-main__container__inner__second">
              {!!language && (
                <div className="city-main__container__inner__language">
                  <div className="city-main__container__inner__headline">{t(Keys.language)}</div>
                  <div className="city-main__container__inner__text">{language}</div>
                </div>
              )}
            </div>
          </div>
        }
      />
    </>
  );
};

export default CityMain;
