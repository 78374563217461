'use client';

import React, { FC, PropsWithChildren, useMemo, JSX, ReactElement } from 'react';
import { DefaultPageProps } from '@/types/cms/magnolia';
import ContactBar from '@/components/contactBar/ContactBar';
import Navigation from '@/components/navigation/Navigation';
import EditableArea from '@/components/magnoliaPage/EditableArea/EditableArea';
import { THEMES } from '@/Util/globals';
import Keys from '@/Translations/generated/da/country.json.keys';
import { useTranslation } from '@/app/i18n/client';
import ContentModuleDynamic from '@/components/contentModule/ContentModuleDynamic';
import { ContentNavigationType } from '@/components/contentNavigation/ContentNavigation';
import { PageComponentProps } from '@/components/magnoliaPage/componentHelper';
import CountryMain from '@/components/CountryIntros/CountryMain';
import { useGlobalMGLProps } from '@/Util/GlobalMGLPropsContext';
import { usePathname } from 'next/navigation';
import Breadcrumb from '@/components/breadcrumb/Breadcrumb';

export const CountryPageInner: FC<
  PropsWithChildren<
    DefaultPageProps &
      PageComponentProps & {
        text: string;
        tel: string;
        mail: string;
        themeContactInformation: string;
        showContactBar: boolean;
        breadcrumb?: ReactElement;
      }
  >
> = (props): JSX.Element => {
  const { t } = useTranslation('country');
  const {
    header,
    themeContactInformation,
    showContactBar,
    children,
    upperPageContent,
    pageProps,
    breadcrumb
  } = props;

  const { country } = pageProps || {};

  const {
    name,
    mainPage,
    subPages,
    themeContentArea,
    themeIntroMain,
    contentAreaTitle,
    contentArea
  } = country || {};

  const navigationItems = useMemo(
    () =>
      [
        contentArea && contentArea.length > 0
          ? {
              title: t(Keys.itinerary),
              id: 'itinerary',
              section: (
                <div>
                  <ContentModuleDynamic
                    headline={contentAreaTitle ? contentAreaTitle : name}
                    theme={themeContentArea}
                    contentRow={contentArea}
                    pageProps={pageProps}
                  />
                </div>
              )
            }
          : null
      ].filter(Boolean) as ContentNavigationType,
    [contentArea, t]
  );

  return (
    <div className="country-page">
      <div className="page__top">
        {header && <EditableArea className="page__top__header" content={header} />}
        {showContactBar && <ContactBar theme={themeContactInformation || THEMES.darkGreen} />}
      </div>
      <div className="page__center">
        {upperPageContent}
        {!!mainPage && !!subPages && (
          <Navigation className={themeIntroMain || THEMES.blue} items={[mainPage, ...subPages]} />
        )}
        <CountryMain country={country} breadcrumb={breadcrumb} />
        {navigationItems.map(navigationItem => (
          <div key={navigationItem.id} id={navigationItem.id}>
            {navigationItem.section}
          </div>
        ))}
        {children}
      </div>
    </div>
  );
};

export default CountryPageInner;
