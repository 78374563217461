import React, { ChangeEvent, FC, JSX, useEffect, useRef, useState } from 'react';
import { Input } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import classNames from 'classnames';
import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons/faMagnifyingGlass';
import { useApi } from '@ibe/components';
import { ApiWebsiteQuickSearchResultsByCategory } from '@ibe/api';
import WebsiteSearchResults from '@/components/websiteSearch/WebsiteSearchResults';
import { useDebounce } from '@/Hooks/useDebounce';
import { broadcastEvent } from '@/Tracking/trackingSubscriptions';
import { EventType, SearchCategory } from '@/Tracking/types';
import useConfig from '@/Hooks/useConfig';
import { useCurrentLanguage } from '@/Util/CurrentLanguageProvider';
import { logger } from '@/Util/globals';
import { useGlobalMGLProps } from '@/Util/GlobalMGLPropsContext';

const WebsiteSearch: FC<{
  isMounted: boolean;
  status: string;
  toggleSearch: (searchIsMounted: boolean) => void;
}> = ({ toggleSearch, status, isMounted }): JSX.Element => {
  const api = useApi();
  const config = useConfig();
  const locale = useCurrentLanguage();
  const pageProps = useGlobalMGLProps();
  const [value, setValue] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [results, setResults] = useState<ApiWebsiteQuickSearchResultsByCategory[]>([]);
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (isMounted && !!inputRef.current) {
      inputRef.current.focus();
    }
  }, [inputRef, isMounted]);

  const doRequest = useDebounce(async (): Promise<void> => {
    if (value.length > 1) {
      setIsLoading(true);
      try {
        broadcastEvent(EventType.SEARCH, config, locale, {
          search: { searchCategory: SearchCategory.WEBSITE, searchTerm: value },
          data: { pageProps }
        });
        const res = await api.websiteSearchQuick(value);
        setResults(!!res?.results ? res.results : []);
      } catch {
        logger('error')('Unable to fetch website search');
        setResults([]);
      } finally {
        setIsLoading(false);
      }
    } else {
      setResults([]);
    }
  }, 400);

  const onInputChange = async (e: ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value);
    doRequest();
  };

  const onIconClick = (): void => {
    if (!!inputRef.current) {
      inputRef.current.focus();
    }
  };

  return isMounted ? (
    <div
      className={classNames('website-search', {
        'website-search--hidden': status === 'preEnter' || status === 'exiting'
      })}
    >
      <FontAwesomeIcon
        icon={faTimes}
        className="website-search__close"
        onClick={(): void => toggleSearch(false)}
      />
      <div className="website-search__input">
        <FontAwesomeIcon
          icon={faMagnifyingGlass}
          className="website-search__input__magnify"
          onClick={onIconClick}
        />
        <Input innerRef={inputRef} value={value} onChange={onInputChange} />
        <FontAwesomeIcon
          icon={faTimes}
          className="website-search__input__reset"
          onClick={(): void => {
            setValue('');
            setResults([]);
          }}
        />
      </div>
      <WebsiteSearchResults
        results={results}
        isLoading={isLoading}
        value={value}
        pageProps={pageProps}
      />
    </div>
  ) : (
    <></>
  );
};

export default WebsiteSearch;
