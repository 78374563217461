import React, { Dispatch, FC, Fragment, JSX, SetStateAction } from 'react';
import CheckoutStore from '@/templates/checkout/CheckoutStore';
import { AllItems } from '@/components/checkout/cart/getAllItems';
import Keys from '@/Translations/generated/da/Checkout.json.keys';
import { useTranslation } from '@/app/i18n/client';
import { observer } from 'mobx-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { API_ITEM_SERVICE_CODE, getDefaultFormatPrice, logger } from '@/Util/globals';
import { faTrashCan } from '@fortawesome/free-regular-svg-icons';
import { useCurrentLanguage } from '@/Util/CurrentLanguageProvider';
import {
  ApiAddItemRequestFromJSON,
  ApiBookedItem,
  ApiHotel,
  ApiInsurance,
  ApiTransfer
} from '@ibe/api';
import { TFunction } from 'i18next';
import { getPurchasesHeadline } from '@/components/checkout/cart/AdditionalPurchases';
import { getUpgradesHeadline } from '@/components/checkout/cart/Upgrades';
import OverviewAndTotalPriceItem from '@/components/checkout/cart/OverviewAndTotalPriceItem';
import { getServiceTypeCode } from '@/components/checkout/cart/getUpgrades';
import { useApi, useWindow } from '@ibe/components';

const getPerPersonPriceString = (
  numberOfTravelers: number,
  language: string,
  t: TFunction,
  pricePerPerson?: number
): string => {
  return `${numberOfTravelers} x ${
    !!pricePerPerson ? getDefaultFormatPrice(pricePerPerson, language) : t(Keys.includedAlt)
  }`;
};

const OverviewAndTotalPrice: FC<{
  checkoutStore: CheckoutStore;
  allItems: AllItems;
  setIgnoreBusSeatValidation: Dispatch<SetStateAction<boolean>>;
}> = observer(function OverviewAndTotalPrice({
  checkoutStore,
  allItems,
  setIgnoreBusSeatValidation
}): JSX.Element {
  const language = useCurrentLanguage();
  const window = useWindow();
  const api = useApi();
  const { t } = useTranslation('Checkout');

  const { booking: { travelers } = {} } = checkoutStore;
  const {
    main,
    voucher,
    discount,
    accommodations: { hotelsAndRooms },
    upgrades: { all: upgrades },
    additionalPurchases,
    insurances
  } = allItems;

  const removeItem = async (item: ApiBookedItem): Promise<void> => {
    const componentPosition = JSON.parse(window?.atob(item.id) || '')?.componentPosition as
      | number
      | undefined;
    if (componentPosition !== undefined) {
      const cartComponent = checkoutStore.packageCart?.packageModel?.packageDetails?.[0]?.components?.find(
        component => component.externalComponentId === componentPosition.toString()
      );
      if (!!cartComponent && cartComponent.selectedItems?.[0]) {
        if (getServiceTypeCode(item) === API_ITEM_SERVICE_CODE.BU) {
          const bus = cartComponent.selectedItems?.[0] as ApiTransfer;
          const success = await checkoutStore.selectItemsInCart(
            cartComponent.id,
            [bus?.units?.[0]?.id],
            {
              [bus?.units?.[0]?.id]: ApiAddItemRequestFromJSON({
                count: 0,
                date: bus.start,
                pickupStation: bus.pickupStation,
                dropoffStation: bus.dropoffStation,
                specialRequests: [],
                notes: [],
                travelers: []
              })
            }
          );
          if (success) {
            setIgnoreBusSeatValidation(true);
          }
        } else if (
          getServiceTypeCode(item) === API_ITEM_SERVICE_CODE.HOTEL ||
          getServiceTypeCode(item) === API_ITEM_SERVICE_CODE.HOTEL_ALT1
        ) {
          try {
            const decodedParentId = JSON.parse(atob(item.idParent));
            if (!!decodedParentId?.id) {
              const decoded = JSON.parse(atob(decodedParentId.id)) as {
                unitCode: string;
                unitTypeCode: string;
              };
              if (!!decoded) {
                const { unitCode, unitTypeCode } = decoded;
                const hotelSelectableItem = (cartComponent.selectableItems as ApiHotel[])?.find(
                  selectableItem =>
                    selectableItem.rooms?.some(
                      room => room.roomCode === unitCode && room.themeCode === unitTypeCode
                    )
                );
                const rooms = hotelSelectableItem?.rooms;
                if (!!rooms && rooms.length > 0) {
                  const index = rooms.findIndex(i => i?.roomrates[0]?.totalPrice?.finalPrice === 0);
                  if (index > -1) {
                    rooms.unshift(rooms.splice(index, 1)[0]);
                  }
                  const roomRateId = rooms[0]?.roomrates?.[0]?.id;
                  if (!!roomRateId) {
                    await checkoutStore.selectItemsInCart(cartComponent.id, [roomRateId]);
                  }
                }
              }
            }
          } catch (e) {
            logger('error')('Unable to decode/parse', e);
          }
        } else {
          await checkoutStore.deselectItemsInCart(cartComponent.id, [
            cartComponent.selectedItems[0].id
          ]);
        }
      }
    }
  };

  const removeInsuranceItem = (travelerPosition: string) => async (
    item: ApiBookedItem
  ): Promise<void> => {
    const itemId = JSON.parse(window?.atob(item.idParent) || '')?.id as string | undefined;
    if (itemId !== undefined) {
      const serviceClass = JSON.parse(window?.atob(itemId) || '') as
        | { serviceCode: string; unitCode: string }
        | undefined;
      if (!!serviceClass) {
        const insuranceService = checkoutStore.insurance?.selectableItems?.[0]?.relatedServices?.find(
          relatedService =>
            (relatedService.selectableItems?.[0] as ApiInsurance)?.code ===
              serviceClass.serviceCode &&
            (relatedService.selectableItems?.[0] as ApiInsurance)?.units?.[0]?.code ===
              serviceClass.unitCode
        );
        if (!!insuranceService?.selectableItems?.[0]) {
          const insuranceItem = insuranceService.selectableItems[0] as ApiInsurance;
          const componentId = checkoutStore.insurance?.id;
          const componentUnitId = checkoutStore.insurance?.selectableItems?.[0]?.units?.[0]?.id;
          const specialRequestsFromSelectedInsurances =
            checkoutStore.packageCart?.itemsBookingOptions?.[componentUnitId || 'EMPTY']
              ?.specialRequests;
          if (!!componentId && !!componentUnitId) {
            await checkoutStore.selectItemsInCart(componentId, [componentUnitId], {
              [componentUnitId]: ApiAddItemRequestFromJSON({
                count: 1,
                date: checkoutStore?.packageCart?.packageModel?.startDate,
                specialRequests: [
                  ...(specialRequestsFromSelectedInsurances || []).filter(
                    specialRequest =>
                      specialRequest.id !== insuranceItem?.units?.[0]?.id ||
                      specialRequest.travelerPosition !== parseInt(travelerPosition || '-1', 10)
                  )
                ],
                notes: [],
                travelers: []
              })
            });
          }
        }
      }
    }
  };

  const removeDiscount = async (): Promise<void> => {
    checkoutStore.isLoading = true;
    try {
      const booking = await api.addPromoCodes(checkoutStore.booking?.id || '', []);
      if (!!booking) {
        checkoutStore.booking = booking;
      }
    } catch (err) {
      logger('error')('Unable to remove discount', err);
    } finally {
      checkoutStore.isLoading = false;
    }
  };

  const removeVoucherCode = async (): Promise<void> => {
    checkoutStore.voucherStatus = 'nothing';
    checkoutStore.isLoading = true;
    try {
      const booking = await api.addVoucherCodes(checkoutStore.booking?.id || '', []);
      if (!!booking) {
        checkoutStore.booking = booking;
      }
    } catch (err) {
      logger('error')('Unable to remove voucher', err);
    } finally {
      checkoutStore.isLoading = false;
    }
  };

  return (
    <>
      <hr />
      <div className="cart__headline">{t(Keys.price)}</div>
      <div className="checkout__data checkout__data--overview">
        {!!main && (
          <div className="checkout__data__section">
            <FontAwesomeIcon icon={faTrashCan} className="no-opacity" />
            <div>
              <div className="font-weight-bold">{t(Keys.travel)}</div>
              <div className="checkout__data__price checkout__data__price--inner">
                <div>
                  {getPerPersonPriceString(
                    travelers?.length || 0,
                    language,
                    t,
                    Object.values(main.priceByPersonId || {})[0].finalPrice
                  )}
                </div>
                <div
                  className={classNames({
                    checkout__data__included: !main?.price?.finalPrice
                  })}
                >
                  {main.price.finalPrice === 0
                    ? t(Keys.includedAlt)
                    : getDefaultFormatPrice(main.price.finalPrice, language)}
                </div>
              </div>
            </div>
          </div>
        )}
        {!!voucher && (
          <div className="checkout__data__section">
            <FontAwesomeIcon
              icon={faTrashCan}
              className="checkout__data__icon--clickable"
              onClick={removeVoucherCode}
            />
            <div>
              <div className="font-weight-bold">{t(Keys.voucher)}</div>
              <div className="checkout__data__price checkout__data__price--inner">
                <div>
                  {getPerPersonPriceString(
                    travelers?.length || 0,
                    language,
                    t,
                    voucher.absolute / (travelers?.length || 1)
                  )}
                </div>
                <div>{getDefaultFormatPrice(voucher.absolute, language)}</div>
              </div>
            </div>
          </div>
        )}
        {!!discount && (
          <div className="checkout__data__section">
            <FontAwesomeIcon
              icon={faTrashCan}
              className={classNames({
                'checkout__data__icon--clickable': !discount.promoCodes?.includes('RA'),
                'no-opacity': discount.promoCodes?.includes('RA')
              })}
              onClick={!discount.promoCodes?.includes('RA') ? removeDiscount : undefined}
            />
            <div>
              <div className="font-weight-bold">
                {discount.promoCodes?.includes('RA')
                  ? t(Keys.discountRoyalAlbatros)
                  : t(Keys.discount)}
              </div>
              <div className="checkout__data__price checkout__data__price--inner">
                <div>
                  {!discount.promoCodes?.includes('RA')
                    ? getPerPersonPriceString(
                        travelers?.length || 0,
                        language,
                        t,
                        discount.discount.finalPrice / (travelers?.length || 1)
                      )
                    : t(Keys.totalDiscount)}
                </div>
                <div>{getDefaultFormatPrice(discount.discount.finalPrice, language)}</div>
              </div>
            </div>
          </div>
        )}
        {hotelsAndRooms
          .filter(({ hotel }) => hotel?.price?.finalPrice >= 0)
          .map(({ hotel }) => (
            <OverviewAndTotalPriceItem
              key={hotel.id}
              checkoutStore={checkoutStore}
              item={hotel}
              headline={`${t(Keys.accommodation)} (${hotel.description})`}
              removeItem={removeItem}
            />
          ))}
        {upgrades.map(({ item, type }) => (
          <OverviewAndTotalPriceItem
            key={item.id}
            checkoutStore={checkoutStore}
            item={item}
            headline={getUpgradesHeadline(type, t)}
            removeItem={removeItem}
          />
        ))}
        {additionalPurchases.purchases.map(({ bookedItem, type }) => (
          <OverviewAndTotalPriceItem
            key={bookedItem.id}
            checkoutStore={checkoutStore}
            item={bookedItem}
            headline={getPurchasesHeadline(type, t)}
            removeItem={removeItem}
            showItemName
          />
        ))}
        {Object.entries(insurances).map(([traveler, insuranceItems], idx) => (
          <Fragment key={traveler}>
            {insuranceItems.map(insuranceItem => (
              <OverviewAndTotalPriceItem
                key={insuranceItem.id}
                checkoutStore={checkoutStore}
                item={insuranceItem}
                headline={t(Keys.insurance)}
                subHeadline={
                  <>
                    <div>{insuranceItem.name}</div>
                    <div>{`- ${t(Keys.insuranceParticipant, { number: idx + 1 })}`}</div>
                  </>
                }
                showFullPriceFactorOne
                removeItem={removeInsuranceItem(traveler)}
              />
            ))}
          </Fragment>
        ))}
      </div>
      {!!checkoutStore.booking && (
        <div className="cart__total-price">
          <div>{t(Keys.totalPrice)}</div>
          <div>{getDefaultFormatPrice(checkoutStore.booking.price.finalPrice, language)}</div>
        </div>
      )}
      <hr />
    </>
  );
});

export default OverviewAndTotalPrice;
