import React, { FC, JSX, useMemo } from 'react';
import { ApiWebsiteOtherPagesSearch } from '@ibe/api';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleArrowRight } from '@fortawesome/free-solid-svg-icons';
import NextLink from 'next/link';
import { useTranslation } from '@/app/i18n/client';
import Keys from '@/Translations/generated/da/Header.json.keys';

const WebsiteSearchFullOtherPagesList: FC<{
  results: ApiWebsiteOtherPagesSearch[];
  activeIndex: number;
}> = ({ results, activeIndex }): JSX.Element => {
  const { t } = useTranslation('Header');
  const tabIndex = 2;
  const createRow = (r: ApiWebsiteOtherPagesSearch) => {
    return (
      <div key={r.title} className="website-search-full__results__list--row">
        <b>{r.title}</b>
        <NextLink prefetch={false} href={r.url}>
          <span>{t(Keys.learnMore)}</span>
          <FontAwesomeIcon icon={faCircleArrowRight} />
        </NextLink>
        <p>{r.matchedText}</p>
      </div>
    );
  };

  const groupByPageType = useMemo(() => {
    const map = new Map();
    const sortedByPriority = results.sort((a, b) => {
      if (a.priority && b.priority) {
        return b.priority - a.priority;
      } else {
        return 0;
      }
    });
    sortedByPriority.forEach((item: ApiWebsiteOtherPagesSearch) => {
      const collection = map.get(item.pageType);
      if (!collection) {
        map.set(item.pageType, [item]);
      } else {
        collection.push(item);
      }
    });
    return new Map([...map.entries()].sort());
  }, [results]);

  const outputElements: JSX.Element[] = [];

  for (let [key, value] of groupByPageType) {
    outputElements.push(<h4 key={key}>{key}</h4>);
    outputElements.push(value.map((data: ApiWebsiteOtherPagesSearch) => createRow(data)));
  }

  return (
    <div
      style={activeIndex !== tabIndex ? { maxHeight: 0 } : {}}
      className="website-search-full__results__list"
    >
      {outputElements}
    </div>
  );
};

export default WebsiteSearchFullOtherPagesList;
