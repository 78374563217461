'use client';

import React, { Suspense, useCallback, useMemo } from 'react';
import Link from 'next/link';
import PageUrl from '@/Models/PageUrl';
import {
  EditableAreaOrPageChild,
  FooterNavSection,
  MagnoliaComponentNode,
  MagnoliaDamNode
} from '@/types/cms/magnolia';
import FooterLinks from '@/Layouts/Footer/FooterLinks';
import GridContainer from '@/Layouts/GridContainer';
import { checkPathCompatibility, normalizeInheritedProps, THEMES } from '@/Util/globals';
import Image from '@/components/image/Image';
import {
  ApiFooterContentItems,
  ApiNavigationItem,
  ApiNavigationItemFromJSON
} from '../../../api/model';
import sanitize from '@/Util/sanitize';
import { getNextLinkAttrs } from '@/components/linkList/Link';
import Newsletter from '@/Layouts/Footer/Newsletter';
import { useIsComponentMounted } from '@/Hooks/useIsComponentMounted';
import classNames from 'classnames';

export interface Props extends MagnoliaComponentNode, EditableAreaOrPageChild {
  imageLink?: MagnoliaDamNode;
  newsletterSection?: { headline?: string; subHeadline?: string };
  contactSection?: { addressData?: string; additionalData?: string };
  topLeft?: FooterNavSection;
  topRight?: FooterNavSection;
  bottomLeft?: FooterNavSection;
  bottomRight?: FooterNavSection;
}

const Footer = (props: Props) => {
  const {
    imageLink,
    newsletterSection,
    contactSection,
    topLeft,
    topRight,
    bottomLeft,
    bottomRight,
    pageProps
  } = normalizeInheritedProps<Props>(props);
  const isMounted = useIsComponentMounted();
  const {
    siteConfig,
    magnoliaContext: { nodePath } = { nodePath: '' },
    finalProduct,
    page: { footerLinksTopLeft, footerLinksTopRight } = {
      footerLinksTopLeft: undefined,
      footerLinksTopRight: undefined
    },
    continent,
    country,
    city,
    product,
    ship,
    travelType
  } = pageProps || {};

  const transformLinks = useCallback(
    (linkList?: FooterNavSection['links']): ApiNavigationItem[] => {
      return !!linkList
        ? [...Array(linkList['@nodes'].length).keys()]
            .filter(key => !!linkList[`links${key}`])
            .map(key => {
              const footerLink = linkList[`links${key}`];
              return ApiNavigationItemFromJSON({
                name: footerLink.title,
                linkType: footerLink.linkType,
                link: getNextLinkAttrs(footerLink, pageProps).href
              });
            })
        : [];
    },
    []
  );

  const getCountryFooterOrUndefined: ApiFooterContentItems | undefined = useMemo(() => {
    switch (nodePath) {
      case siteConfig?.parameters.countryDetailsPage:
        return country?.footerMainPage;
      case siteConfig?.parameters.countrySubPages.countryFactsPage:
        return country?.footerFactsPage;
      case siteConfig?.parameters.countrySubPages.countryEntryPage:
        return country?.footerEntryPage;
      case siteConfig?.parameters.countrySubPages.countryVideosPage:
        return country?.footerVideosPage;
      case siteConfig?.parameters.countrySubPages.countryImagesPage:
        return country?.footerImagesPage;
      case siteConfig?.parameters.countrySubPages.countrySightsPage:
        return country?.footerSightseeingPage;
      case siteConfig?.parameters.countrySubPages.countryTravelsPage:
        return country?.footerProductsPage;
      case siteConfig?.parameters.countrySubPages.countryVaccinationPage:
        return country?.footerVaccinationPage;
      case siteConfig?.parameters.countrySubPages.countryClimatePage:
        return country?.footerClimatePage;
      case siteConfig?.parameters.countrySubPages.countryMapPage:
        return country?.footerMapPage;
      default:
        return undefined;
    }
  }, []);

  const getCityFooterOrUndefined: ApiFooterContentItems | undefined = useMemo(() => {
    switch (nodePath) {
      case siteConfig?.parameters.cityDetailsPage:
        return city?.footerMainPage;
      case siteConfig?.parameters.citySubPages.cityFactsPage:
        return city?.footerFactsPage;
      case siteConfig?.parameters.citySubPages.cityVideosPage:
        return city?.footerVideosPage;
      case siteConfig?.parameters.citySubPages.cityImagesPage:
        return city?.footerImagesPage;
      case siteConfig?.parameters.citySubPages.cityTravelsPage:
        return city?.footerProductsPage;
      case siteConfig?.parameters.citySubPages.cityClimatePage:
        return city?.footerClimatePage;
      case siteConfig?.parameters.citySubPages.cityMapPage:
        return city?.footerMapPage;
      default:
        return undefined;
    }
  }, []);

  const transformedLinks: {
    topLeft: ApiNavigationItem[];
    topRight: ApiNavigationItem[];
    bottomLeft: ApiNavigationItem[];
    bottomRight: ApiNavigationItem[];
  } = useMemo(() => {
    return {
      topLeft:
        product?.footerTopLeft?.navigationItem ||
        getCityFooterOrUndefined?.footerTopLeft?.navigationItem ||
        getCountryFooterOrUndefined?.footerTopLeft?.navigationItem ||
        continent?.footerTopLeft?.navigationItem ||
        ship?.footerTopLeft?.navigationItem ||
        travelType?.footerTopLeft?.navigationItem ||
        (!!footerLinksTopLeft && transformLinks(footerLinksTopLeft.links).length > 0
          ? transformLinks(footerLinksTopLeft.links)
          : transformLinks(topLeft?.links)),
      topRight:
        product?.footerTopRight?.navigationItem ||
        getCityFooterOrUndefined?.footerTopRight?.navigationItem ||
        getCountryFooterOrUndefined?.footerTopRight?.navigationItem ||
        continent?.footerTopRight?.navigationItem ||
        ship?.footerTopRight?.navigationItem ||
        travelType?.footerTopRight?.navigationItem ||
        (!!footerLinksTopRight && transformLinks(footerLinksTopRight.links).length > 0
          ? transformLinks(footerLinksTopRight.links)
          : transformLinks(topRight?.links)),
      bottomLeft: transformLinks(bottomLeft?.links),
      bottomRight: transformLinks(bottomRight?.links)
    };
  }, [topLeft, topRight, bottomRight, bottomLeft, footerLinksTopLeft, footerLinksTopRight]);

  return (
    <Suspense>
      <GridContainer className={classNames(THEMES.darkGreen, 'footer__wrapper')}>
        <div className="footer">
          <div className="footer__container__small">
            {imageLink?.['@link'] && (
              <Link href={PageUrl.HOME} prefetch={false}>
                {isMounted && (
                  <Image
                    className="footer__logo"
                    src={imageLink?.['@link']}
                    alt={''}
                    width={214}
                    height={81}
                    useDefaultLoader
                    serverSideContext={pageProps?.albConfig.context}
                  />
                )}
              </Link>
            )}
            <h4 className="h4-albatros mb-xs-2">{newsletterSection?.headline}</h4>
            <div className="mb-xs-4">{newsletterSection?.subHeadline}</div>
            <Newsletter />
          </div>
          <div className="footer__container__large">
            <FooterLinks
              linkSection={transformedLinks.topLeft}
              headline={
                product?.footerTopLeft?.groupName ||
                getCityFooterOrUndefined?.footerTopLeft?.groupName ||
                getCountryFooterOrUndefined?.footerTopLeft?.groupName ||
                continent?.footerTopLeft?.groupName ||
                ship?.footerTopLeft?.groupName ||
                travelType?.footerTopLeft?.groupName ||
                footerLinksTopLeft?.title ||
                topLeft?.title
              }
            />
            <FooterLinks
              linkSection={transformedLinks.topRight}
              headline={
                product?.footerTopRight?.groupName ||
                getCityFooterOrUndefined?.footerTopRight?.groupName ||
                getCountryFooterOrUndefined?.footerTopRight?.groupName ||
                continent?.footerTopRight?.groupName ||
                ship?.footerTopRight?.groupName ||
                travelType?.footerTopRight?.groupName ||
                footerLinksTopRight?.title ||
                topRight?.title
              }
            />
          </div>
          <div className="footer__container__small footer__contact">
            {contactSection?.addressData && (
              <div
                dangerouslySetInnerHTML={{ __html: sanitize(contactSection.addressData, true) }}
              />
            )}
            {contactSection?.additionalData && (
              <div
                dangerouslySetInnerHTML={{ __html: sanitize(contactSection.additionalData, true) }}
              />
            )}
          </div>
          <div className="footer__container__large">
            <FooterLinks linkSection={transformedLinks.bottomLeft} headline={bottomLeft?.title} />
            <FooterLinks linkSection={transformedLinks.bottomRight} headline={bottomRight?.title} />
          </div>
        </div>
        {!!nodePath &&
          !!siteConfig &&
          !!finalProduct &&
          checkPathCompatibility(nodePath, siteConfig.parameters.productPage) && (
            <div className="footer__product-code">{finalProduct.name}</div>
          )}
      </GridContainer>
    </Suspense>
  );
};

export default Footer;
