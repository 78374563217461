import React, { JSX, ReactElement } from 'react';
import Keys from '@/Translations/generated/da/country.json.keys';
import Intro from '@/components/intro/Intro';
import { useTranslation } from '@/app/i18n';
import { THEMES } from '@/Util/globals';
import { ApiCountry } from '../../../api/model';
import sanitize from '@/Util/sanitize';
import Breadcrumb from '@/components/breadcrumb/Breadcrumb';
import { useGlobalMGLProps } from '@/Util/GlobalMGLPropsContext';
import { usePathname } from 'next/navigation';
import { getResolvedUrl } from '@/Util/magnoliaData/getRequestConfig';
import { Props } from '@/types/cms/magnolia';

const CountryFacts = async ({
  country,
  language: locale,
  breadcrumb,
  pageProps
}: {
  country?: ApiCountry;
  language: string;
  breadcrumb?: ReactElement;
  pageProps?: Props;
}): Promise<JSX.Element> => {
  const {
    introTitleFacts,
    introTextFacts,
    capitalCity,
    citizens,
    currency,
    language,
    religion,
    area,
    themeIntroFacts
  } = country || {};
  const { t } = await useTranslation(locale, 'country', {
    translationsVersion: pageProps?.translationsVersion
  });

  return (
    <Intro
      headline={introTitleFacts ? introTitleFacts : `${t(Keys.headlineFacts)} ${country?.name}`}
      breadcrumb={breadcrumb}
      leftSide={
        <div className="mb-xs-3 mb-md-0">
          {!!introTextFacts && (
            <div
              dangerouslySetInnerHTML={{
                __html: sanitize(introTextFacts, true)
              }}
            />
          )}
        </div>
      }
      rightSide={
        <>
          <div>
            <div className="d-sm-flex mb-sm-4">
              {!!citizens && (
                <div className="intro__container__item">
                  <div className="intro__container__sub-headline">{t(Keys.citizens)}</div>
                  <div className="intro__container__text">{citizens}</div>
                </div>
              )}
              {!!capitalCity && (
                <div className="intro__container__item">
                  <div className="intro__container__sub-headline">{t(Keys.capitalCity)}</div>
                  <div className="intro__container__text">{capitalCity}</div>
                </div>
              )}
            </div>
            <div className="d-sm-flex mb-sm-4">
              {!!religion && (
                <div className="intro__container__item">
                  <div className="intro__container__sub-headline">{t(Keys.religion)}</div>
                  <div className="intro__container__text">{religion}</div>
                </div>
              )}
              {!!currency && (
                <div className="intro__container__item">
                  <div className="intro__container__sub-headline">{t(Keys.currency)}</div>
                  <div className="intro__container__text">{currency}</div>
                </div>
              )}
            </div>
            <div className="d-sm-flex mb-sm-4">
              {!!language && (
                <div className="intro__container__item">
                  <div className="intro__container__sub-headline">{t(Keys.language)}</div>
                  <div className="intro__container__text">{language}</div>
                </div>
              )}
              {!!area && (
                <div className="intro__container__item">
                  <div className="intro__container__sub-headline">{t(Keys.area)}</div>
                  <div className="intro__container__text">{area}</div>
                </div>
              )}
            </div>
          </div>
        </>
      }
      theme={themeIntroFacts || THEMES.blue}
    />
  );
};

export default CountryFacts;
