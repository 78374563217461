import React, { Dispatch, FC, MutableRefObject, SetStateAction, useEffect, useState } from 'react';
import GridContainer from '@/Layouts/GridContainer';
import { observer } from 'mobx-react';
import TermsAndConditions, { ExternalLinks } from '@/components/checkout/TermsAndConditions';
import CheckoutStore from '@/templates/checkout/CheckoutStore';
import ParticipantsForm, { FormRefs } from '@/components/checkout/ParticipantsForm';
import CheckoutStep from '@/components/checkout/CheckoutStep';
import classNames from 'classnames';
import Payment from 'components/checkout/payment/Payment';
import { DisabledPaymentOptions } from '@/components/checkout/payment/PaymentContent';
import MediaCodes from '@/components/checkout/MediaCodes';
import { broadcastEvent } from '@/Tracking/trackingSubscriptions';
import { CheckoutStepName, EventType } from '@/Tracking/types';
import useConfig from '@/Hooks/useConfig';
import { useCurrentLanguage } from '@/Util/CurrentLanguageProvider';
import PromoCode from '@/components/checkout/PromoCode';
import Insurance from '@/components/checkout/insurance/Insurance';
import SuspenseFallbackComponent from '@/components/SuspenseFallbackComponent/SuspenseFallbackComponent';

const CheckoutStepParticipants: FC<{
  externalLinks: ExternalLinks;
  checkoutStore: CheckoutStore;
  participantsFormRefs: MutableRefObject<FormRefs>;
  isDesktop: boolean;
  activeSubIndex: number[];
  activeIndex: number;
  numberOfSubStepsPerStep: number[];
  index: number;
  activePaymentTab: number;
  setActivePaymentTab: Dispatch<SetStateAction<number>>;
  setActivePaymentRadio: Dispatch<SetStateAction<number>>;
  activePaymentRadio: number;
  disabledPaymentOptions: DisabledPaymentOptions;
  setIsOnlinePaymentDisabled: (disabled: boolean) => void;
  useParticipantsMock: boolean;
}> = observer(function CheckoutStepParticipants({
  externalLinks,
  checkoutStore,
  participantsFormRefs,
  isDesktop,
  activeSubIndex,
  activeIndex,
  numberOfSubStepsPerStep,
  index,
  activePaymentTab,
  setActivePaymentTab,
  activePaymentRadio,
  setActivePaymentRadio,
  disabledPaymentOptions,
  setIsOnlinePaymentDisabled,
  useParticipantsMock
}): JSX.Element {
  const config = useConfig();
  const locale = useCurrentLanguage();
  const [showInsurances, setShowInsurances] = useState<boolean>(false);
  const [insurancesLoading, setInsurancesLoading] = useState<boolean>(false);

  useEffect(() => {
    if (
      index === activeIndex &&
      !!checkoutStore.selectedPacificProduct &&
      !!checkoutStore.product
    ) {
      broadcastEvent(EventType.CHECKOUT_PROGRESS, config, locale, {
        data: {
          booking: checkoutStore.booking,
          cacheDataProduct: checkoutStore.selectedPacificProduct,
          product: checkoutStore.product
        },
        checkout: { step: index + 1, stepName: CheckoutStepName.DETAILS }
      });
    }
  }, [index, activeIndex, checkoutStore.selectedPacificProduct, checkoutStore.product]);

  return (
    <div className={classNames('mobile-slide', { 'mobile-slide--active': index === activeIndex })}>
      <div
        className="mobile-slide__inner"
        style={{
          width: isDesktop ? 'initial' : `${numberOfSubStepsPerStep[index] * 100}%`,
          transform: `translateX(${
            activeSubIndex[index] * (100 / numberOfSubStepsPerStep[index]) * -1
          }%)`
        }}
      >
        <CheckoutStep
          index={0}
          activeIndex={activeSubIndex[activeIndex]}
          numberOfItems={numberOfSubStepsPerStep[activeIndex]}
          baseClass="mobile-slide__step"
          ignore={isDesktop}
        >
          <GridContainer>
            {checkoutStore.mountParticipantsFormComponent && (
              <ParticipantsForm
                checkoutStore={checkoutStore}
                participantsFormRefs={participantsFormRefs}
                setIsOnlinePaymentDisabled={setIsOnlinePaymentDisabled}
                useParticipantsMock={useParticipantsMock}
                setShowInsurances={setShowInsurances}
                setInsurancesLoading={setInsurancesLoading}
              />
            )}
          </GridContainer>
        </CheckoutStep>
        <CheckoutStep
          index={1}
          activeIndex={activeSubIndex[activeIndex]}
          numberOfItems={numberOfSubStepsPerStep[activeIndex]}
          baseClass="mobile-slide__step"
          ignore={isDesktop}
        >
          <MediaCodes checkoutStore={checkoutStore} />
          <PromoCode checkoutStore={checkoutStore} />
          {!showInsurances && insurancesLoading && (
            <GridContainer className="insurance__loader">
              <SuspenseFallbackComponent fitToContainer spinnerOnly />
            </GridContainer>
          )}
          {!!checkoutStore.insurance &&
            showInsurances &&
            checkoutStore.mountParticipantsFormComponent && (
              <Insurance
                checkoutStore={checkoutStore}
                externalLinks={externalLinks}
                insurancesLoading={insurancesLoading}
              />
            )}
        </CheckoutStep>
        <CheckoutStep
          index={2}
          activeIndex={activeSubIndex[activeIndex]}
          numberOfItems={numberOfSubStepsPerStep[activeIndex]}
          baseClass="mobile-slide__step"
          ignore={isDesktop}
        >
          <Payment
            activeTab={activePaymentTab}
            setActiveTab={setActivePaymentTab}
            setActiveRadio={setActivePaymentRadio}
            activeRadio={activePaymentRadio}
            disabledOptions={disabledPaymentOptions}
            checkoutStore={checkoutStore}
          />
          <GridContainer className="grid-section--last-element">
            <TermsAndConditions externalLinks={externalLinks} checkoutStore={checkoutStore} />
          </GridContainer>
        </CheckoutStep>
      </div>
    </div>
  );
});

export default CheckoutStepParticipants;
