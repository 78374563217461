import { ConfigModel } from '@ibe/services';
import { Language } from '@/Translations';

export type MediaCodeBaseType = '' | 'input' | 'dropdown';

class AlbConfigModel extends ConfigModel {
  context?: string;
  sherpaSrc?: string;
  sherpaId?: string;
  trustpilotSrc?: string;
  trustpilotTemplateId?: string;
  trustpilotReviewUrl?: string;
  cookieInformationSrc?: string;
  cookieInformationGcmVersion?: string;
  cookieInformationId?: string;
  mediaCodes?: Record<string, MediaCodeBaseType | Record<string, MediaCodeBaseType>>;
  googleTagManagerId: Record<`${Language}`, string>;
}

export enum BVSrcReplacements {
  LOCALE = '<<bvLocale>>'
}

export default AlbConfigModel;
