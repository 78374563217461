'use client';

import React, { ReactElement } from 'react';
import { useTranslation } from '@/app/i18n/client';
import { ApiCity } from '../../../api/model';
import Keys from '@/Translations/generated/da/city.json.keys';
import { THEMES } from '@/Util/globals';
import Intro from '@/components/intro/Intro';
import sanitize from '@/Util/sanitize';
import Breadcrumb from '@/components/breadcrumb/Breadcrumb';
import { useGlobalMGLProps } from '@/Util/GlobalMGLPropsContext';
import { usePathname } from 'next/navigation';

const CityFacts = ({
  city,
  breadcrumb
}: {
  city?: ApiCity;
  breadcrumb?: ReactElement;
}): ReactElement => {
  const {
    language,
    citizens,
    foundation,
    introTitleFacts,
    introTextFacts,
    religion,
    currency,
    heightAboveSeaLevel,
    themeIntroFacts,
    name
  } = city || {};
  const { t } = useTranslation('city');

  return (
    <>
      <Intro
        headline={introTitleFacts ? introTitleFacts : `${t(Keys.headlineFacts)} ${name || ''}`}
        breadcrumb={breadcrumb}
        leftSide={
          <div className="mb-xs-3 mb-md-0">
            {!!introTextFacts && (
              <div
                dangerouslySetInnerHTML={{
                  __html: sanitize(introTextFacts, true)
                }}
              />
            )}
          </div>
        }
        rightSide={
          <>
            <div>
              <div className="d-sm-flex mb-sm-4">
                {!!citizens && (
                  <div className="intro__container__item">
                    <div className="intro__container__sub-headline">{t(Keys.citizens)}</div>
                    <div className="intro__container__text">{citizens}</div>
                  </div>
                )}
                {!!foundation && (
                  <div className="intro__container__item">
                    <div className="intro__container__sub-headline">{t(Keys.foundation)}</div>
                    <div className="intro__container__text">{foundation}</div>
                  </div>
                )}
              </div>
              <div className="d-sm-flex mb-sm-4">
                {!!language && (
                  <div className="intro__container__item">
                    <div className="intro__container__sub-headline">{t(Keys.language)}</div>
                    <div className="intro__container__text">{language}</div>
                  </div>
                )}
                {!!religion && (
                  <div className="intro__container__item">
                    <div className="intro__container__sub-headline">{t(Keys.religion)}</div>
                    <div className="intro__container__text">{religion}</div>
                  </div>
                )}
              </div>
              <div className="d-sm-flex mb-sm-4">
                {!!currency && (
                  <div className="intro__container__item">
                    <div className="intro__container__sub-headline">{t(Keys.currency)}</div>
                    <div className="intro__container__text">{currency}</div>
                  </div>
                )}
                {!!heightAboveSeaLevel && (
                  <div className="intro__container__item">
                    <div className="intro__container__sub-headline">
                      {t(Keys.heightAboveSeaLevel)}
                    </div>
                    <div className="intro__container__text">{heightAboveSeaLevel}</div>
                  </div>
                )}
              </div>
            </div>
          </>
        }
        theme={themeIntroFacts || THEMES.blue}
      />
    </>
  );
};

export default CityFacts;
