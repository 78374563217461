import React, { FC, useMemo } from 'react';
import { Collapse } from 'reactstrap';
import { SearchProps } from '@/components/Search/SearchDesktopOverlay';
import Button from '@/components/Button';
import Keys from '@/Translations/generated/da/search.json.keys';
import { useTranslation } from '@/app/i18n/client';
import { ApiProductsCacheResponse } from '@ibe/api';
import CheckboxResults from '@/components/Search/CheckboxResults';
import { Action } from '@/components/Search/useCheckboxStateReducer';
import { Spinner } from '@ibe/components';

type Airport = { id: string; name: string; amount: number };

const getAirports = (productPackages: ApiProductsCacheResponse[]): Airport[] => {
  return (
    productPackages?.reduce(
      (
        total: Array<{ id: string; name: string; amount: number }>,
        current: ApiProductsCacheResponse
      ) => {
        const packageAirport = current?.products?.[0]?.departureAirport;
        return !!packageAirport && !total.find(item => item.id === packageAirport)
          ? [
              ...total,
              {
                id: packageAirport,
                name: packageAirport,
                amount: 1
              }
            ]
          : total.map(item =>
              item.id === packageAirport ? { ...item, amount: item.amount + 1 } : item
            );
      },
      []
    ) || []
  );
};

const DepartureAirportTab: FC<
  Pick<
    SearchProps,
    'collapseOpen' | 'productsTotalResults' | 'checkboxState' | 'checkboxDispatch' | 'isLoading'
  > & {
    closeCollapse: () => void;
    productPackages?: ApiProductsCacheResponse[];
    initialProductPackages: ApiProductsCacheResponse[];
  }
> = ({
  collapseOpen,
  closeCollapse,
  productPackages,
  checkboxDispatch,
  checkboxState,
  isLoading,
  initialProductPackages
}): JSX.Element => {
  const { t } = useTranslation('search');

  const airports = useMemo((): { initial: Airport[]; current: Airport[] } => {
    return {
      initial: getAirports(initialProductPackages),
      current: getAirports(productPackages || [])
    };
  }, [initialProductPackages, productPackages]);

  const checkAllAirports = (): void => {
    checkboxDispatch({
      type: Action.ADD_OR_REMOVE_ALL_PER_TYPE,
      payload: {
        id: '',
        type: 'airport',
        name: '',
        items:
          airports.current.map(airport => ({ id: airport.id || '', name: airport.name || '' })) ||
          []
      }
    });
  };

  const checkSingleAirport = (id: string): void => {
    checkboxDispatch({
      type: Action.ADD_OR_REMOVE_SINGLE,
      payload: {
        id: id,
        name: id,
        type: 'airport'
      }
    });
  };

  return (
    <Collapse className="search-for-results__collapse--single" isOpen={collapseOpen}>
      <div className="search-for-results__dropdown search-for-results__airports">
        <CheckboxResults
          label={t(Keys.all)}
          checked={
            !!airports &&
            airports.current.length > 0 &&
            Object.entries(checkboxState.items).filter(([, value]) => value.type === 'airport')
              .length === airports.current.length
          }
          onChange={checkAllAirports}
          results={
            airports.current.reduce((total: number, current) => total + current.amount, 0) || 0
          }
          isLoading={isLoading}
        />
        {airports.initial.map(({ id, name }) => (
          <CheckboxResults
            key={`${id}${name}`}
            label={name}
            checked={
              !!Object.entries(checkboxState.items).find(
                ([key, value]) => value.type === 'airport' && key === id
              )
            }
            onChange={(): void => checkSingleAirport(id)}
            results={
              airports.current
                .filter(airport => airport.id === id)
                .reduce((total: number, current) => total + current.amount, 0) || 0
            }
            isLoading={isLoading}
          />
        ))}
      </div>
      <div className="search__content__submit">
        <Button color="primary" onClick={closeCollapse} disabled={isLoading}>
          <span>{`${t(Keys.apply)} (${productPackages?.length || 0})`}</span>
          {isLoading && <Spinner />}
        </Button>
      </div>
    </Collapse>
  );
};

export default DepartureAirportTab;
