/* This file was generated by running "node scripts/generateTranslations.js". */
/* Created: 2024-10-13 16:16:22 */

const Keys = {
  continentCountryListHeadline: 'continentCountryListHeadline',
  continentIntroHeadline: 'continentIntroHeadline',
  moreInformation: 'moreInformation',
  area: 'area',
  largestCities: 'largestCities',
  citizens: 'citizens',
  amount: 'amount'
}; export default Keys;