import { Dispatch, SetStateAction } from 'react';
import { MagnoliaSite, Props } from '@/types/cms/magnolia';
import { ReducerState } from '@/components/Search/useCheckboxStateReducer';
import {
  ApiContentSearchRequestFromJSON,
  ApiContinentComponent,
  ApiCountriesSearchResponse,
  ApiDatesSearchResponse,
  ApiDatesSearchResponseDates,
  ApiProductsCacheResponse,
  ApiTextSearchRequestFromJSON,
  ApiTextSearchResponse,
  ApiTravelTypeComponent,
  ApiTravelTypesSearchResponse
} from '@ibe/api';
import { useApi, useWindow } from '@ibe/components';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import {
  getCheckboxStateCountries,
  getCheckboxStateDates,
  getCheckboxStateTravelTypes,
  getUrlWithUpdatedQueryParams
} from '@/components/Search/helpers';
import { ReadonlyURLSearchParams, usePathname, useSearchParams } from 'next/navigation';
import { broadcastEvent } from '@/Tracking/trackingSubscriptions';
import { EventType, SearchCategory } from '@/Tracking/types';
import useConfig from '@/Hooks/useConfig';
import { useCurrentLanguage } from '@/Util/CurrentLanguageProvider';
import { logger } from '@/Util/globals';

dayjs.extend(utc);

const updateUrl = (
  checkboxState: ReducerState,
  searchParams: ReadonlyURLSearchParams,
  pathName: string,
  window?: Window | null
): void => {
  const newUrl = getUrlWithUpdatedQueryParams(checkboxState, searchParams, pathName);
  window?.history?.replaceState({ ...window?.history?.state, as: newUrl, url: newUrl }, '', newUrl);
};

export const useRequests = (
  setIsLoading: Dispatch<SetStateAction<boolean>>,
  setTextSearch: Dispatch<SetStateAction<Required<ApiTextSearchResponse>>>,
  setContinents: Dispatch<SetStateAction<ApiContinentComponent[]>>,
  setProductsTotalCountry: Dispatch<SetStateAction<number>>,
  setTravelTypes: Dispatch<SetStateAction<ApiTravelTypeComponent[]>>,
  setProductsTotalTravelType: Dispatch<SetStateAction<number>>,
  setDates: Dispatch<SetStateAction<ApiDatesSearchResponseDates[]>>,
  setProductPackages: Dispatch<SetStateAction<ApiProductsCacheResponse[]>>,
  setProductsTotalOverall: Dispatch<SetStateAction<number>>,
  pageProps?: Props,
  siteConfig?: MagnoliaSite,
  isInitialSearch?: boolean
): {
  getTextSearchResults: (value: string) => Promise<void>;
  getCountriesResults: (checkboxState: ReducerState, doGetProducts?: boolean) => Promise<void>;
  getTravelTypesResults: (checkboxState: ReducerState, doGetProducts?: boolean) => Promise<void>;
  getDatesResults: (checkboxState: ReducerState, doGetProducts?: boolean) => Promise<void>;
} => {
  const api = useApi();
  const config = useConfig();
  const locale = useCurrentLanguage();
  const window = useWindow();
  const searchParams = useSearchParams();
  const pathName = usePathname();

  const getTextSearchResults = async (value: string): Promise<void> => {
    if (value.length < 1 || !siteConfig) return Promise.resolve(undefined);
    setIsLoading(true);
    broadcastEvent(EventType.SEARCH, config, locale, {
      search: { searchCategory: SearchCategory.TOUR },
      data: { pageProps }
    });
    try {
      const res: ApiTextSearchResponse = await api.searchText(
        ApiTextSearchRequestFromJSON({ input: value })
      );
      if (!!res) {
        setTextSearch({ countries: res.countries || [], products: res.products || [] });
      }
    } catch (err) {
      logger('error')('Unable to fetch text search data: ', err);
    } finally {
      setIsLoading(false);
    }
  };

  const getCountriesResults = async (
    localCheckboxState: ReducerState,
    doGetProducts?: boolean
  ): Promise<void> => {
    try {
      const res: ApiCountriesSearchResponse = await api.searchCountries(
        ApiContentSearchRequestFromJSON({
          countries: getCheckboxStateCountries(localCheckboxState),
          travelTypes: getCheckboxStateTravelTypes(localCheckboxState),
          dates: getCheckboxStateDates(localCheckboxState),
          withProducts: doGetProducts
        })
      );
      if (!!res?.continents) {
        setContinents(
          res.continents.filter(
            continent => !!continent?.countries && continent?.countries.length > 0
          )
        );
        setProductsTotalCountry(res?.productsTotal || 0);
      }
      if (doGetProducts) {
        setProductPackages(res?.products || []);
        if (!isInitialSearch) {
          updateUrl(localCheckboxState, searchParams, pathName, window);
        }
      }
      if (res.productsTotalFiltered && res.products && res.products.length > 0) {
        setProductsTotalOverall(res?.productsTotalFiltered);
      }
    } catch (err) {
      logger('error')('Unable to fetch countries data: ', err);
    }
  };

  const getTravelTypesResults = async (
    localCheckboxState: ReducerState,
    doGetProducts?: boolean
  ): Promise<void> => {
    try {
      const res: ApiTravelTypesSearchResponse = await api.searchTravelTypes(
        ApiContentSearchRequestFromJSON({
          countries: getCheckboxStateCountries(localCheckboxState),
          travelTypes: getCheckboxStateTravelTypes(localCheckboxState),
          dates: getCheckboxStateDates(localCheckboxState),
          withProducts: doGetProducts
        })
      );
      if (!!res?.travelTypes) {
        setTravelTypes(res.travelTypes);
        setProductsTotalTravelType(res?.productsTotal || 0);
      }
      if (doGetProducts) {
        setProductPackages(res?.products || []);
        if (!isInitialSearch) {
          updateUrl(localCheckboxState, searchParams, pathName, window);
        }
      }
      if (res.productsTotalFiltered && res.products && res.products.length > 0) {
        setProductsTotalOverall(res?.productsTotalFiltered);
      }
    } catch (err) {
      logger('error')('Unable to fetch travelTypes data: ', err);
    }
  };

  const getDatesResults = async (
    localCheckboxState: ReducerState,
    doGetProducts?: boolean
  ): Promise<void> => {
    try {
      const res: ApiDatesSearchResponse = await api.searchDates(
        ApiContentSearchRequestFromJSON({
          countries: getCheckboxStateCountries(localCheckboxState),
          travelTypes: getCheckboxStateTravelTypes(localCheckboxState),
          dates: getCheckboxStateDates(localCheckboxState),
          withProducts: doGetProducts
        })
      );
      if (!!res?.dates) {
        setDates(res.dates);
      }
      if (doGetProducts) {
        setProductPackages(res?.products || []);
        if (!isInitialSearch) {
          updateUrl(localCheckboxState, searchParams, pathName, window);
        }
      }
      if (res.productsTotalFiltered && res.products && res.products.length > 0) {
        setProductsTotalOverall(res?.productsTotalFiltered);
      }
    } catch (err) {
      logger('error')('Unable to fetch dates data: ', err);
    }
  };

  return {
    getTravelTypesResults,
    getCountriesResults,
    getTextSearchResults,
    getDatesResults
  };
};
