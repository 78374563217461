import React, { ReactElement, useState } from 'react';
import { Carousel } from 'react-responsive-carousel';
import Image from '@/components/image/Image';
import { Next, Previous } from '@/Theme/SVG/Svgs';
import { ApiImage } from '../../../api/model';
import { Props as PageProps } from '@/types/cms/magnolia';

type Props = {
  images: ApiImage[];
  pageProps?: PageProps;
};

const customRenderThumb = (transformedImageGallery: Props['images'], pageProps?: PageProps) => () =>
  transformedImageGallery.map(item => {
    return (
      <>
        {item && (
          <span key={item.assetId} className="expanded-gallery__thumb">
            <Image
              src={item.imageLink || ''}
              alt={item.caption || ''}
              width={item.width}
              height={item.height}
              serverSideContext={pageProps?.albConfig.context}
            />
          </span>
        )}
      </>
    );
  });

const ExpandedGallery = (props: Props): ReactElement => {
  const { images, pageProps } = props;

  const [activeIndex, setActiveIndex] = useState<number>(0);

  return (
    <div className="expanded-gallery">
      <div className="expanded-gallery__label">
        <div>{images[activeIndex]?.caption}</div>
        <div>
          {activeIndex + 1} / {images.length}
        </div>
      </div>
      <Carousel
        thumbWidth={120}
        renderThumbs={customRenderThumb(images, pageProps)}
        showStatus={false}
        showIndicators={false}
        infiniteLoop
        onChange={index => setActiveIndex(index)}
        renderArrowNext={clickHandler => {
          return (
            <button
              className="nav_btn nav_btn_right expanded-gallery__button expanded-gallery__button--next"
              onClick={clickHandler}
            >
              <Next />
            </button>
          );
        }}
        renderArrowPrev={clickHandler => {
          return (
            <button
              onClick={clickHandler}
              className="nav_btn nav_btn_left expanded-gallery__button expanded-gallery__button--previous"
            >
              <Previous />
            </button>
          );
        }}
      >
        {images.map((imageGallery, index) => (
          <div key={index}>
            {imageGallery && (
              <Image
                className="expanded-gallery__image"
                src={imageGallery.imageLink || ''}
                alt={imageGallery.caption || ''}
                width={imageGallery.width}
                height={imageGallery.height}
                key={index}
                aspectRatio="default"
                serverSideContext={pageProps?.albConfig.context}
              />
            )}
          </div>
        ))}
      </Carousel>
    </div>
  );
};

export default ExpandedGallery;
