import React, { ReactElement, Dispatch, SetStateAction, MouseEvent } from 'react';
import { facBurger, facCross, facProfile, facSearch } from '@/Theme/SVG/Icons';
import Keys from '@/Translations/generated/da/Header.json.keys';
import { cssHelper, HeaderProps, ScrollMode } from '@/Layouts/Header/external';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from '@/app/i18n/client';
import { THEMES } from '@/Util/globals';

const MenuDesktop = ({
  burgerOpen,
  setBurgerOpen,
  toggleSearch,
  scrollMode,
  data
}: {
  burgerOpen: boolean;
  setBurgerOpen: Dispatch<SetStateAction<boolean>>;
  toggleSearch: (isOpen?: boolean) => void;
  scrollMode: ScrollMode;
  data: HeaderProps;
}): ReactElement => {
  const { t } = useTranslation('Header');

  return (
    <div className="burger-wrapper">
      <div
        className={
          burgerOpen
            ? 'burgernav-desktop ' + 'burgernav-desktop-scrolltop '
            : 'burgernav-desktop ' +
              cssHelper(scrollMode === 'DOWN' && 'burgernav-desktop-scrolldown ') +
              cssHelper(scrollMode === 'UP' && 'burgernav-desktop-scrollup ') +
              cssHelper(scrollMode === 'TOP' && 'burgernav-desktop-scrolltop ')
        }
      >
        <div className="d-xs-flex">
          {/* TODO: MyAlbatros*/}
          {/*<a className="burger-desktop-header-link menu" href="#">*/}
          {/*  <FontAwesomeIcon icon={facProfile} />*/}
          {/*  <span className="link-text">{t(Keys.withAlbatros)}</span>*/}
          {/*</a>*/}
          <a
            className="burger-desktop-header-link menu"
            href="#"
            onClick={(e: MouseEvent<HTMLAnchorElement>) => {
              e.preventDefault();
              toggleSearch(true);
            }}
          >
            <FontAwesomeIcon icon={facSearch} />
            <span className="link-text">{t(Keys.search)}</span>
          </a>
        </div>
        <div
          className={`menu-icon ${THEMES.green}`}
          onClick={() => {
            setBurgerOpen(!burgerOpen);
          }}
        >
          {burgerOpen && <FontAwesomeIcon className="burgernav-desktop__close" icon={facCross} />}
          {!burgerOpen && <FontAwesomeIcon icon={facBurger} />}
        </div>
      </div>
      <div className={'burgernav-desktop-content ' + cssHelper(burgerOpen && 'open')}>
        <div className="burger-desktop-topnav-link-wrapper">
          {data.navLinks.map(navLink => {
            return (
              <div className="burger-desktop-topnav-link" key={navLink.label}>
                <a
                  className="burger-content-link menu"
                  key={navLink.label}
                  href={navLink.linkProps.href}
                >
                  {navLink.label}
                </a>
              </div>
            );
          })}
        </div>
        <div className="grid burger-desktop-sidenav-wrapper">
          <div className="grid-col-7">
            {data.sidebarNavLinksLeftColumn.map(sidebarNavLinks => {
              return (
                <div className="burger-desktop-sidenav-link" key={sidebarNavLinks.label}>
                  <a
                    className="burger-content-link menu"
                    key={sidebarNavLinks.label}
                    href={sidebarNavLinks.linkProps.href}
                  >
                    {sidebarNavLinks.label}
                  </a>
                </div>
              );
            })}
          </div>
          <div className="grid-col-5">
            {data.sidebarNavLinksRightColumn.map(sidebarNavLinks => {
              return (
                <div className="burger-desktop-sidenav-link" key={sidebarNavLinks.label}>
                  <a
                    className="burger-content-link menu"
                    key={sidebarNavLinks.label}
                    href={sidebarNavLinks.linkProps.href}
                  >
                    {sidebarNavLinks.label}
                  </a>
                </div>
              );
            })}
          </div>
        </div>
        <div className="burger-footer">
          <hr />
          <div className="burger-desktop-footer-content">
            <span className="burger-desktop-footer-info">{data.open}</span> <br />
            <span className="burger-desktop-footer-info">{data.tel}</span> <br />
            <span className="burger-desktop-footer-info">{data.mail}</span> <br />
          </div>
        </div>
      </div>
    </div>
  );
};

export default MenuDesktop;
