'use client';

import React, { FC, JSX } from 'react';
import { ApiTravelType } from '../../../api/model';
import Image from '@/components/image/Image';
import { Video } from '@/components/videoGallery/Video';
import Link from 'next/link';
import { mapToApiImage } from '@/Util/globals';
import { Props } from '@/types/cms/magnolia';

const TravelTypeTeaser: FC<{
  travelType: ApiTravelType;
  pageProps?: Props;
}> = ({ travelType, pageProps }): JSX.Element => {
  return (
    <div className={`travelTypeTeaser`}>
      <div className="travelTypeTeaser__box">
        <div key={travelType.name} className="travelTypeTeaser__item">
          <div className="travelTypeTeaser__item__headline">{travelType.name}</div>
          {travelType.content === 'video' ? (
            <div className="video-gallery__video">
              <Link href={travelType.travelTypePage?.url || ''} prefetch={false}>
                <Video
                  videoSrc={travelType.heroBackgroundVideo?.videoSrc || ''}
                  placeholderImage={mapToApiImage(travelType.heroBackgroundVideo?.placeholderImage)}
                  className="video-gallery__video"
                  useLazyLoading
                />
              </Link>
            </div>
          ) : travelType.content === 'image' && !!travelType.heroBackgroundImage ? (
            <div className="">
              <Link href={travelType.travelTypePage?.url || ''} prefetch={false}>
                <Image
                  className="travelTypeTeaser__item"
                  src={travelType.heroBackgroundImage?.imageLink || ''}
                  alt={travelType.heroBackgroundImage?.caption || ''}
                  width={travelType.heroBackgroundImage?.width}
                  height={travelType.heroBackgroundImage?.height}
                  serverSideContext={pageProps?.albConfig.context}
                />
              </Link>
            </div>
          ) : (
            <></>
          )}
        </div>
      </div>
    </div>
  );
};

export default TravelTypeTeaser;
