'use client';

import React, { JSX, PropsWithChildren } from 'react';
import ContextProvider, { ApplicationProps } from '@/components/contextProviders/ContextProviders';

const MagnoliaPage = ({
  pageProps,
  isProduction,
  locale,
  children,
  albConfig,
  isLocal
}: PropsWithChildren<ApplicationProps>): JSX.Element => {
  return (
    <ContextProvider
      isProduction={isProduction}
      pageProps={pageProps}
      locale={locale}
      albConfig={albConfig}
      isLocal={isLocal}
    >
      {children}
    </ContextProvider>
  );
};

export default MagnoliaPage;
