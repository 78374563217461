'use client';

import React, { FC, JSX, useEffect } from 'react';
import { ApiBooking, ApiPackageCart, ApiProductsCacheData } from '@ibe/api';
import { MagnoliaSite, Props } from '@/types/cms/magnolia';
import { ApiAirlineLogo, ApiProduct } from '../../../api/model';
import { useApi, useWindow } from '@ibe/components';
import { usePathname, useSearchParams } from 'next/navigation';
import { PACKAGE_CART_ID_PARAMETER } from '@/Util/globals';
import { getInitialSelectedOptions } from '@/components/productSelection/optionsData';
import { useMount } from 'react-use';
import CheckoutStore from '@/templates/checkout/CheckoutStore';
import CheckoutPageInnerMarkup from '@/templates/checkout/CheckoutPageInnerMarkup';
import { ExternalLinks } from '@/components/checkout/TermsAndConditions';
import { BaseData } from '@/templates/checkout/requests';
import { broadcastEvent } from '@/Tracking/trackingSubscriptions';
import { EventType } from '@/Tracking/types';
import useConfig from '@/Hooks/useConfig';

const CheckoutPageInner: FC<{
  pageProps?: Props;
  selectedPacificProduct?: ApiProductsCacheData;
  pacificProducts: ApiProductsCacheData[];
  packageCart?: ApiPackageCart;
  booking?: ApiBooking;
  product?: ApiProduct;
  showContactBar: boolean;
  siteConfig?: MagnoliaSite | null;
  rootNodePath?: string;
  airlineLogos?: ApiAirlineLogo[];
  locale: string;
  externalLinks: ExternalLinks;
  baseData: BaseData;
  confirmationPageMode: boolean;
  packageCartId: string;
  displayUrl: string;
  useParticipantsMock: boolean;
  variantParam?: string;
}> = ({
  pageProps,
  selectedPacificProduct: selectedPacificProductFromServer,
  pacificProducts: pacificProductsFromServer,
  showContactBar,
  siteConfig,
  rootNodePath,
  product: productFromServer,
  packageCart: packageCartFromServer,
  booking: bookingFromServer,
  baseData: baseDataFromServer,
  airlineLogos,
  locale,
  externalLinks,
  confirmationPageMode,
  packageCartId,
  displayUrl,
  useParticipantsMock,
  variantParam
}): JSX.Element => {
  const api = useApi();
  const config = useConfig();
  const pathname = usePathname();
  const window = useWindow();
  const searchParams = useSearchParams();
  const checkoutStore = new CheckoutStore(api, window, pathname, siteConfig, locale);

  useMount(() => {
    if (!!packageCartFromServer) {
      checkoutStore.packageCart = packageCartFromServer;
    }
    checkoutStore.pacificProducts = pacificProductsFromServer;
    if (!!selectedPacificProductFromServer) {
      checkoutStore.selectedPacificProduct = selectedPacificProductFromServer;
    }
    if (!!productFromServer) {
      checkoutStore.product = productFromServer;
    }
    if (!!bookingFromServer) {
      checkoutStore.booking = bookingFromServer;
    }
    checkoutStore.selectedFilterOptions = getInitialSelectedOptions(
      selectedPacificProductFromServer
    );
    checkoutStore.baseData = baseDataFromServer;

    if (!!packageCartFromServer?.id) {
      const newParams = new URLSearchParams(searchParams.toString());
      newParams.set(PACKAGE_CART_ID_PARAMETER, packageCartFromServer.id);
      const newUrl = `${pathname}?${newParams.toString()}`;
      window?.history?.replaceState(
        { ...window?.history?.state, as: newUrl, url: newUrl },
        '',
        newUrl
      );
    }
  });

  useEffect(() => {
    let timer1 = setTimeout(() => {
      broadcastEvent(EventType.PAGE_VIEW, config, locale, {
        data: {
          pageProps,
          packageCart: checkoutStore.packageCart,
          cacheDataProduct: checkoutStore.selectedPacificProduct,
          product: checkoutStore.product
        }
      });
    }, 200);
    let timer2 = setTimeout(() => {
      broadcastEvent(EventType.BEGIN_CHECKOUT, config, locale, {
        data: {
          pageProps,
          packageCart: checkoutStore.packageCart,
          cacheDataProduct: checkoutStore.selectedPacificProduct,
          product: checkoutStore.product,
          booking: checkoutStore.booking
        }
      });
    }, 400);

    return () => {
      if (!!timer1) {
        clearTimeout(timer1);
      }
      if (!!timer2) {
        clearTimeout(timer2);
      }
    };
  }, []);

  return (
    <CheckoutPageInnerMarkup
      checkoutStore={checkoutStore}
      showContactBar={showContactBar}
      siteConfig={siteConfig}
      rootNodePath={rootNodePath}
      airlineLogos={airlineLogos}
      externalLinks={externalLinks}
      initialCartError={
        !packageCartFromServer?.id ||
        !packageCartFromServer?.bookingId ||
        !packageCartFromServer?.packageModel?.id
      }
      confirmationPageMode={confirmationPageMode}
      displayUrl={displayUrl}
      packageCartId={packageCartId}
      useParticipantsMock={useParticipantsMock}
      variantParam={variantParam}
      pageProps={pageProps}
    />
  );
};

export default CheckoutPageInner;
