import React, { Dispatch, FC, JSX, RefObject, SetStateAction, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Keys from '@/Translations/generated/da/search.json.keys';
import {
  facCalendarSmall,
  facCaretRight,
  facGlobeMagnifier,
  facLocation,
  facMagnifyingGlass
} from '@/Theme/SVG/Icons';
import { TFunction } from 'i18next';
import { useTranslation } from '@/app/i18n/client';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { CollapseIndex, SearchProps } from '@/components/Search/SearchDesktopOverlay';
import classNames from 'classnames';
import { Spinner } from '@ibe/components';
import sanitize from '@/Util/sanitize';

export type Field = { icon?: IconDefinition; label: string; index: CollapseIndex };

const getFields = (t: TFunction): Field[] => [
  { icon: facLocation, label: t(Keys.destinations), index: 1 },
  { icon: facGlobeMagnifier, label: t(Keys.travelTypes), index: 2 },
  { icon: facCalendarSmall, label: t(Keys.departureTime), index: 3 },
  { icon: facMagnifyingGlass, label: t(Keys.search), index: 4 }
];

let timer: ReturnType<typeof setTimeout> | null = null;

const Tabs: FC<
  Pick<SearchProps, 'goToResultsPage' | 'isLoading'> & {
    textSearchInputRef: RefObject<HTMLInputElement>;
    collapseOpen: CollapseIndex;
    setCollapseOpen: Dispatch<SetStateAction<CollapseIndex>>;
    productsTotalResults: number;
    hasSelectedItems: boolean;
  }
> = ({
  textSearchInputRef,
  collapseOpen,
  setCollapseOpen,
  productsTotalResults,
  goToResultsPage,
  hasSelectedItems,
  isLoading
}): JSX.Element => {
  const { t } = useTranslation('search');

  useEffect(() => {
    return () => {
      if (!!timer) {
        clearTimeout(timer);
      }
    };
  }, []);

  const toggleCollapse = async (index: CollapseIndex): Promise<void> => {
    setCollapseOpen(index === collapseOpen ? 0 : index);
    if (index === 1 && index !== collapseOpen) {
      timer = setTimeout((): void => textSearchInputRef?.current?.focus(), 0);
    }
  };

  const isNotSelected = (index: number): boolean => {
    return collapseOpen > 0 && collapseOpen !== index;
  };

  const handleSubmit = (): void => {
    goToResultsPage();
  };

  return (
    <>
      <div className="search__buttons">
        {getFields(t).map(({ icon, label, index }: Field) => (
          <div
            key={index}
            className={isNotSelected(index) ? 'search__buttons__selection-state' : ''}
            onClick={(): Promise<void> => toggleCollapse(index)}
          >
            {!!icon && <FontAwesomeIcon icon={icon} />}
            <span>{label}</span>
          </div>
        ))}
      </div>
      <div
        className={classNames('search__submit', { 'search__submit--active': hasSelectedItems })}
        onClick={hasSelectedItems ? handleSubmit : undefined}
      >
        <span
          dangerouslySetInnerHTML={{
            __html: sanitize(t(Keys.showResults, { amount: productsTotalResults }))
          }}
        />
        <FontAwesomeIcon icon={facCaretRight} />
        {isLoading && (
          <div className="search__submit__loading">
            <Spinner />
          </div>
        )}
      </div>
    </>
  );
};

export default Tabs;
