'use client';

import React, { FC, MouseEvent } from 'react';
import {
  ApiProductsCacheData,
  ApiProductsCacheResponseTravelTypes,
  ApiProductWithReviews
} from '@ibe/api';
import { useTranslation } from '@/app/i18n/client';
import useConfig from '@/Hooks/useConfig';
import { useCurrentLanguage } from '@/Util/CurrentLanguageProvider';
import Image from '@/components/image/Image';
import dayjs from 'dayjs';
import Keys from '@/Translations/generated/da/search.json.keys';
import { /* CircleHeart,*/ RatingStar } from '@/Theme/SVG/Svgs';
import { getDefaultFormatPrice, getLocalizedNumberFormat } from '@/Util/globals';
import { ApiImage } from '../../../api/model';
import { MAX_NUMBER_OF_SHOWN_TRAVELTYPES } from '@/components/SearchForResults/ResultsListItemContent';
import Link from 'next/link';
import classNames from 'classnames';
import { Props } from '@/types/cms/magnolia';

const ResultsListItemCalendarContent: FC<{
  productDetailsLink: string;
  hasAvailableProduct?: boolean;
  cheapestProduct?: ApiProductsCacheData;
  teaserImage?: ApiImage;
  review?: ApiProductWithReviews;
  travelTypes?: ApiProductsCacheResponseTravelTypes[];
  name?: string;
  pageProps?: Props;
}> = ({
  productDetailsLink,
  hasAvailableProduct,
  cheapestProduct,
  teaserImage,
  review,
  travelTypes,
  name,
  pageProps
}): JSX.Element => {
  const { t } = useTranslation('search');
  const config = useConfig();
  const language = useCurrentLanguage();

  return (
    <div className="results__list__cal">
      <div className="results__list__cal__image__container">
        {!!teaserImage && (
          <Image
            className="results__list__cal__image"
            src={teaserImage.imageLink || ''}
            alt={teaserImage.title || teaserImage.caption || ''}
            height={teaserImage.height || 0}
            width={teaserImage.width || 0}
            aspectRatio="4-3"
            serverSideContext={pageProps?.albConfig.context}
          />
        )}
        <div className="results__list__cal__infos">
          <div className="results__list__cal__infos__upper">
            {!!cheapestProduct?.travelStartDate && (
              <div className="results__list__cal__date">
                {dayjs(cheapestProduct.travelStartDate).format(config.displayFormatDate[language])}
              </div>
            )}
            <div className="results__list__cal__infos__small">
              {t(Keys.day, { count: cheapestProduct?.duration || 0 })}
            </div>
            {!!cheapestProduct?.departureAirport && (
              <div className="results__list__cal__infos__delimiter">|</div>
            )}
            <div className="results__list__cal__infos__small mr-xs-4">
              {cheapestProduct?.departureAirport}
            </div>
            {/* TODO */}
            {/*<CircleHeart*/}
            {/*  className="results__list__cal__favorite"*/}
            {/*  onClick={(e: MouseEvent<HTMLOrSVGElement>) => e.stopPropagation()}*/}
            {/*/>*/}
            {!!review?.averageRating && review.averageRating > -1 && (
              <div className="results__list__cal__rating">
                <RatingStar mode="full" />
                <span>
                  {getLocalizedNumberFormat(language)(parseFloat(review.averageRating.toFixed(1)))}
                </span>
              </div>
            )}
          </div>
          <div className="results__list__cal__infos__lower">
            {!!travelTypes && travelTypes?.length > 0 && (
              <div className="results__list__cal__travel-types">
                {`${(travelTypes.length > 3
                  ? travelTypes.slice(0, MAX_NUMBER_OF_SHOWN_TRAVELTYPES)
                  : travelTypes
                )
                  .map(travelType => travelType.name)
                  .join(', ')}${travelTypes.length > 3 ? ` +${travelTypes.length - 3}` : ''}`}
              </div>
            )}
            <h5>{name}</h5>
          </div>
        </div>
      </div>
      <div className="results__list__cal__price__container">
        <div className="results__list__cal__price__label">{t(Keys.from)}</div>
        <h4>{getDefaultFormatPrice(cheapestProduct?.startingPriceAmount || 0, language)}</h4>
        <div
          className={classNames('results__list__cal__price__button theme-dark-green', {
            'results__list__cal__price__button--disabled': !hasAvailableProduct
          })}
        >
          {hasAvailableProduct ? (
            <Link className="btn btn-primary" href={productDetailsLink} prefetch={false}>
              {hasAvailableProduct ? t(Keys.goToProduct) : t(Keys.soldOut)}
            </Link>
          ) : (
            <Link
              className="btn btn-primary"
              href="#"
              onClick={(e: MouseEvent<HTMLAnchorElement>): void => e.preventDefault()}
            >
              {hasAvailableProduct ? t(Keys.goToProduct) : t(Keys.soldOut)}
            </Link>
          )}
        </div>
      </div>
    </div>
  );
};

export default ResultsListItemCalendarContent;
