'use client';

import React, { Fragment, RefObject, useCallback, useEffect, useState } from 'react';
import { facDroplet, facThermometer } from '@/Theme/SVG/Icons';
import TemperatureElement from '@/components/climateTable/TemperatureElement';
import PrecipitationElement from '@/components/climateTable/PrecipitationElement';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from '@/app/i18n/client';
import Keys from '@/Translations/generated/da/ClimateTable.json.keys';
import useScrollSlide from '@/Hooks/useScrollSlide';
import { useMediaQuery } from '@ibe/components';
import { MEDIAQUERY_DEFAULTS } from '@/Util/globals';
import { ApiClimateArea, ApiClimateInfo, ApiClimateInfoFromJSON } from '../../../api/model';
import { monthsShort } from '@/components/ProductsDatesPrices/DesktopContent';
import localeData from 'dayjs/plugin/localeData';
import dayjs from 'dayjs';
import { Next, Previous } from '@/Theme/SVG/Svgs';

dayjs.extend(localeData);

export interface ClimateTableProps {
  climateArea: ApiClimateArea;
}

const ITEM_INDEX_TOLERANCE = 0.02;
let timer: ReturnType<typeof setTimeout> | null = null;

const MobileListItem = ({
  months,
  outerContainerMeasures,
  globalIdx
}: {
  months: ApiClimateInfo[];
  globalIdx: number;
  outerContainerMeasures: DOMRect | null;
}): JSX.Element => {
  const { t } = useTranslation('ClimateTable');

  return (
    <div
      className="climate-table__swipe-item__container"
      style={{
        flex: !!outerContainerMeasures?.width ? `1 0 ${outerContainerMeasures.width}px` : '0'
      }}
    >
      {months.map((month: ApiClimateInfo, idx: number) => (
        <div key={month.name} className="climate-table__swipe-item">
          <div className="climate-table__month-header">{monthsShort[idx + globalIdx]}</div>
          <TemperatureElement minTemp={month.minTemp} maxTemp={month.maxTemp} />
          <PrecipitationElement precipitation={month.precipitation} />
        </div>
      ))}
    </div>
  );
};

const ClimateTable = (props: ClimateTableProps) => {
  const { climateArea } = props;
  const { t } = useTranslation('ClimateTable');
  const isDesktop = useMediaQuery({ type: 'min', query: MEDIAQUERY_DEFAULTS.md });
  const [outerContainerMeasures, setOuterContainerMeasures] = useState<DOMRect | null>(null);

  useEffect(() => {
    return () => {
      if (!!timer) {
        clearTimeout(timer);
      }
    };
  }, []);

  const scroll = useCallback(
    (toLeft: boolean, outerContainer: RefObject<HTMLDivElement>): void => {
      if (!outerContainer.current) return;
      const containerWidth = outerContainerMeasures?.width || 1;
      const itemIndex = outerContainer.current.scrollLeft / containerWidth;
      const itemIndexRounded = Math.floor(itemIndex);
      const scrollToPosition = toLeft
        ? (itemIndexRounded -
            (itemIndex - itemIndexRounded >= 0 &&
            itemIndex - itemIndexRounded < ITEM_INDEX_TOLERANCE
              ? 1
              : 0)) *
          containerWidth
        : containerWidth *
          (itemIndexRounded +
            (Math.ceil(itemIndex) - itemIndex > 0 &&
            Math.ceil(itemIndex) - itemIndex < ITEM_INDEX_TOLERANCE
              ? 2
              : 1));
      outerContainer.current.scrollTo({
        left: scrollToPosition,
        behavior: 'smooth'
      });
    },
    [outerContainerMeasures]
  );

  const { outerContainer, Buttons } = useScrollSlide(
    (outerContainer): void => {
      const setMeasures = (): void => {
        if (!!outerContainer?.current) {
          setOuterContainerMeasures(outerContainer.current.getBoundingClientRect());
        }
      };
      setMeasures();
      timer = setTimeout(setMeasures, 500);
    },
    scroll,
    [
      { class: 'navBtn--prev', icon: <Previous /> },
      { class: 'navBtn--next', icon: <Next /> }
    ]
  );

  return (
    <div className="climate-table__container">
      {isDesktop ? (
        <>
          <div className="climate-table__header--desktop">{climateArea.headline}</div>
          <table className="climate-table climate-table--desktop">
            <thead>
              <tr>
                <th></th>
                {climateArea.climateInfos?.map((climateInfo: ApiClimateInfo, idx: number) => (
                  <th className="climate-table__header-col" key={climateInfo.name}>
                    {monthsShort[idx]}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <div>
                    <FontAwesomeIcon
                      icon={facThermometer}
                      className="climate-table__header--desktop__icon"
                    />
                  </div>
                  <div>{t(Keys.temperature)}</div>
                </td>
                {climateArea.climateInfos?.map((climateInfo: ApiClimateInfo) => (
                  <td key={climateInfo.name}>
                    <TemperatureElement
                      minTemp={climateInfo.minTemp}
                      maxTemp={climateInfo.maxTemp}
                    />
                  </td>
                ))}
              </tr>
              <tr>
                <td>
                  <div>
                    <FontAwesomeIcon
                      icon={facDroplet}
                      className="climate-table__header--desktop__icon"
                    />
                  </div>
                  <div>{t(Keys.percipitation)}</div>
                </td>
                {climateArea.climateInfos?.map((climateInfo: ApiClimateInfo) => (
                  <td key={climateInfo.name}>
                    <PrecipitationElement precipitation={climateInfo.precipitation} />
                  </td>
                ))}
              </tr>
            </tbody>
          </table>
        </>
      ) : (
        <div className="climate-table climate-table--mobile">
          <div className="climate-table__header--mobile">{climateArea.headline}</div>
          <div className="climate-table__wrapper">
            <div className="climate-table__fixed-wrapper">
              <div className="climate-table__row-header">
                <div>
                  <FontAwesomeIcon icon={facThermometer} />
                </div>
                <div className="climate-table__row-header__text">{t(Keys.temperature)}</div>
              </div>
              <div className="climate-table__row-header">
                <div>
                  <FontAwesomeIcon icon={facDroplet} />
                </div>
                <div className="climate-table__row-header__text">{t(Keys.percipitation)}</div>
              </div>
            </div>
            <div className="position-relative d-flex overflow-hidden">
              <div ref={outerContainer} className="climate-table__swipe-container">
                <div className="climate-table__swipe-wrapper">
                  {!!climateArea?.climateInfos &&
                    climateArea.climateInfos.map((climateInfo: ApiClimateInfo, idx: number) =>
                      idx % 2 === 0 ? (
                        <MobileListItem
                          key={climateInfo.name}
                          globalIdx={idx}
                          months={[
                            climateInfo,
                            ApiClimateInfoFromJSON({
                              ...(climateArea.climateInfos?.[idx + 1] || {})
                            })
                          ]}
                          outerContainerMeasures={outerContainerMeasures}
                        />
                      ) : (
                        <Fragment key={climateInfo.name}></Fragment>
                      )
                    )}
                </div>
              </div>
              {Buttons}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
export default ClimateTable;
