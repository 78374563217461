import React, { FC, JSX, useEffect, useState } from 'react';
import { Collapse } from 'reactstrap';
import CountriesSearchTab from '@/components/Search/CountriesSearchTab';
import TravelTypesSearchTab from '@/components/Search/TravelTypesSearchTab';
import { SearchForResultsProps } from '@/components/SearchForResults/Tabs';
import { CollapseIndex } from '@/components/Search/SearchDesktopOverlay';

const SearchForResultsDesktopOverlay: FC<
  SearchForResultsProps & {
    productsTotalCountry: number;
    productsTotalTravelType: number;
    closeCollapse: () => void;
    calculatedProductsTotalResults?: number;
  }
> = ({
  collapseOpen,
  activeIndex,
  continents,
  travelTypes,
  checkboxDispatch,
  checkboxState,
  getCountriesResults,
  getTravelTypesResults,
  getDatesResults,
  productsTotalTravelType,
  productsTotalCountry,
  productsTotalResults,
  closeCollapse,
  isLoading,
  calculatedProductsTotalResults
}): JSX.Element => {
  const [activeIndexFromProps, setActiveIndexFromProps] = useState<CollapseIndex>(0);

  useEffect(() => {
    let timer: ReturnType<typeof setTimeout> | null = null;
    if (activeIndex > 0) {
      setActiveIndexFromProps(activeIndex);
    } else {
      timer = setTimeout((): void => {
        setActiveIndexFromProps(0);
      }, 600);
    }

    return () => {
      if (!!timer) {
        clearTimeout(timer);
      }
    };
  }, [activeIndex]);

  return (
    <Collapse className="search-for-results__collapse" isOpen={collapseOpen}>
      {(activeIndex === 1 || activeIndexFromProps === 1) && continents.length > 0 && (
        <CountriesSearchTab
          continents={continents}
          checkboxState={checkboxState}
          checkboxDispatch={checkboxDispatch}
          getTravelTypesResults={getTravelTypesResults}
          getDatesResults={getDatesResults}
          fullSearchView
          productsTotalCountry={productsTotalCountry}
          productsTotalResults={productsTotalResults}
          closeCollapse={closeCollapse}
          isLoading={isLoading}
          calculatedProductsTotalResults={calculatedProductsTotalResults}
        />
      )}
      {(activeIndex === 2 || activeIndexFromProps === 2) && travelTypes.length > 0 && (
        <TravelTypesSearchTab
          travelTypes={travelTypes}
          getCountriesResults={getCountriesResults}
          getDatesResults={getDatesResults}
          checkboxState={checkboxState}
          checkboxDispatch={checkboxDispatch}
          fullSearchView
          productsTotalTravelType={productsTotalTravelType}
          productsTotalResults={productsTotalResults}
          closeCollapse={closeCollapse}
          isLoading={isLoading}
          calculatedProductsTotalResults={calculatedProductsTotalResults}
        />
      )}
    </Collapse>
  );
};

export default SearchForResultsDesktopOverlay;
