'use client';

import React, { JSX, ReactElement, useMemo } from 'react';
import sanitize from '@/Util/sanitize';
import Keys from '@/Translations/generated/da/country.json.keys';
import { useTranslation } from '@/app/i18n/client';
import { THEMES } from '@/Util/globals';
import ClimateTable from '@/components/climateTable/ClimateTable';
import { ApiCountry } from '../../../api/model';
import Intro from '@/components/intro/Intro';
import Breadcrumb from '@/components/breadcrumb/Breadcrumb';
import { useGlobalMGLProps } from '@/Util/GlobalMGLPropsContext';
import { usePathname } from 'next/navigation';

const CountryClimate = ({
  country,
  breadcrumb
}: {
  country?: ApiCountry;
  breadcrumb?: ReactElement;
}): JSX.Element => {
  const {
    name = undefined,
    introTitleClimate,
    introTextClimate = '',
    climateAreas = undefined,
    themeIntroClimate,
    cities
  } = country || {};

  const { t } = useTranslation('country');

  const climateAreasCollection = useMemo(() => {
    return [...(climateAreas || []), ...(cities || []).flatMap(city => city.climateAreas || [])];
  }, [climateAreas, cities]);

  return (
    <>
      <Intro
        theme={themeIntroClimate || THEMES.blue}
        headline={introTitleClimate ? introTitleClimate : `${t(Keys.headlineClimate)} ${name}`}
        breadcrumb={breadcrumb}
        singleColumn={
          <>
            <div dangerouslySetInnerHTML={{ __html: sanitize(introTextClimate, true) }} />
            {climateAreasCollection.length > 0 &&
              climateAreasCollection.map(climateArea => (
                <ClimateTable key={climateArea.headline} climateArea={climateArea} />
              ))}
          </>
        }
      />
    </>
  );
};

export default CountryClimate;
