import React, { FC, Fragment, PropsWithChildren, useEffect, useMemo, useState } from 'react';
import { useMediaQuery } from '@ibe/components';
import { MEDIAQUERY_DEFAULTS } from '@/Util/globals';
import classNames from 'classnames';
import localeData from 'dayjs/plugin/localeData';
import dayjs from 'dayjs';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { facCaretRightThin } from '@/Theme/SVG/Icons';
import { SearchProps } from '@/components/Search/SearchDesktopOverlay';
import CheckboxResults from '@/components/Search/CheckboxResults';
import RadioResults from '@/components/Search/RadioResults';
import { Action } from '@/components/Search/useCheckboxStateReducer';
import {
  createMonthId,
  getMonthsForSelectedYear,
  SELECTABLE_YEARS
} from '@/components/Search/MonthSelectorSearchTab';

dayjs.extend(localeData);

const MonthSelector: FC<
  PropsWithChildren<
    Pick<
      SearchProps,
      | 'checkboxState'
      | 'checkboxDispatch'
      | 'dates'
      | 'getCountriesResults'
      | 'getTravelTypesResults'
      | 'isLoading'
    > & {
      mobileStyleInDesktop?: boolean;
    }
  >
> = ({
  mobileStyleInDesktop,
  children,
  checkboxDispatch,
  checkboxState,
  dates,
  getCountriesResults,
  getTravelTypesResults,
  isLoading
}) => {
  let isDesktop = useMediaQuery({ type: 'min', query: MEDIAQUERY_DEFAULTS.xm });
  isDesktop = mobileStyleInDesktop === undefined ? isDesktop : !mobileStyleInDesktop;

  const currentYear = useMemo((): number => new Date().getFullYear(), []);
  const [currentSelectedYear, setCurrentSelectedYear] = useState<number | undefined>(undefined);

  useEffect(() => {
    if (isDesktop && !currentSelectedYear) {
      setCurrentSelectedYear(currentYear);
    }
  }, [isDesktop, currentYear, currentSelectedYear]);

  const handleSelectYear = (year?: number): void => {
    setCurrentSelectedYear(year);
  };

  const checkSingleMonth = async (idx: number): Promise<void> => {
    checkboxDispatch({
      type: Action.ADD_OR_REMOVE_SINGLE,
      payload: {
        id: createMonthId(idx, currentSelectedYear),
        name: `${dayjs.months()[idx]} ${currentSelectedYear}`,
        type: 'date',
        callbacks: [getCountriesResults, getTravelTypesResults]
      }
    });
  };

  return (
    <div
      className={classNames('month-selector', { 'month-selector--mobile': mobileStyleInDesktop })}
    >
      <div
        className={classNames('month-selector__inner', {
          'month-selector__inner--transformed': !!currentSelectedYear
        })}
      >
        {!isDesktop && (
          <div className="month-selector__years">
            {[...Array(SELECTABLE_YEARS).keys()]
              .filter((_, idx) =>
                getMonthsForSelectedYear(dates, currentSelectedYear, currentYear + idx).some(
                  month => month > 0
                )
              )
              .map(idx => (
                <div
                  className="month-selector__year"
                  key={idx}
                  onClick={(): void => handleSelectYear(currentYear + idx)}
                >
                  {currentYear + idx}
                </div>
              ))}
          </div>
        )}
        <div className="month-selector__months">
          <div className="month-selector__selected-year">
            <div
              className="month-selector__selected-year--mobile"
              onClick={(): void => handleSelectYear(undefined)}
            >
              <FontAwesomeIcon icon={facCaretRightThin} />
              <span>{currentSelectedYear}</span>
            </div>
            <div className="month-selector__selected-year--desktop">
              <div className="month-selector__years--desktop">
                {[...Array(SELECTABLE_YEARS).keys()].map(idx => (
                  <div
                    key={idx}
                    className={classNames('month-selector__year', {
                      'month-selector__year--selected': currentYear + idx === currentSelectedYear
                    })}
                    onClick={(): void => handleSelectYear(currentYear + idx)}
                  >
                    {currentYear + idx}
                  </div>
                ))}
              </div>
            </div>
            {isDesktop ? (
              <div className="month-selector__months--desktop">
                {dayjs
                  .months()
                  .map((month: string, idx: number) =>
                    (getMonthsForSelectedYear(dates, currentSelectedYear)[idx] || 0) > 0 ? (
                      <CheckboxResults
                        key={month}
                        label={month}
                        checked={!!checkboxState.items[createMonthId(idx, currentSelectedYear)]}
                        onChange={(): Promise<void> => checkSingleMonth(idx)}
                        results={getMonthsForSelectedYear(dates, currentSelectedYear)[idx] || 0}
                        isLoading={isLoading}
                      />
                    ) : (
                      <Fragment key={month} />
                    )
                  )}
              </div>
            ) : (
              <div className="month-selector__months--mobile search-for-results__dropdown">
                {dayjs
                  .months()
                  .map((month: string, idx: number) =>
                    (getMonthsForSelectedYear(dates, currentSelectedYear)[idx] || 0) > 0 ? (
                      <Fragment key={month}>
                        {mobileStyleInDesktop ? (
                          <CheckboxResults
                            key={month}
                            label={month}
                            checked={!!checkboxState.items[createMonthId(idx, currentSelectedYear)]}
                            onChange={(): Promise<void> => checkSingleMonth(idx)}
                            results={getMonthsForSelectedYear(dates, currentSelectedYear)[idx] || 0}
                            isLoading={isLoading}
                          />
                        ) : (
                          <RadioResults
                            key={month}
                            label={month}
                            checked={!!checkboxState.items[createMonthId(idx, currentSelectedYear)]}
                            onChange={(): Promise<void> => checkSingleMonth(idx)}
                            results={getMonthsForSelectedYear(dates, currentSelectedYear)[idx] || 0}
                            isLoading={isLoading}
                          />
                        )}
                      </Fragment>
                    ) : (
                      <Fragment key={month} />
                    )
                  )}
              </div>
            )}
          </div>
          <div>{children}</div>
        </div>
      </div>
    </div>
  );
};

export default MonthSelector;
