'use client';

import React, { FC, Ref, useMemo } from 'react';
import { ImageProps } from 'next/image';
import NextImage from 'next/image';
import {
  AspectRatio,
  getDefaultImageLoader,
  getMagnoliaFocalImageLoader,
  getNoLoader
} from '@/Util/imageLoader';
import useConfig from '@/Hooks/useConfig';
import isClient from '@/Util/globals';

export type ImageType = Omit<ImageProps, 'loader'> & {
  aspectRatio?: AspectRatio;
  useDefaultLoader?: boolean;
  noLoader?: boolean;
  ref?: Ref<HTMLImageElement | null> | undefined;
  serverSideContext?: string;
};

const Image: FC<ImageType> = (props): JSX.Element => {
  const {
    serverSideContext,
    aspectRatio = 'default',
    useDefaultLoader,
    noLoader,
    ref,
    ...imageProps
  } = props;
  const { context } = useConfig();

  const loader = useMemo(() => {
    return noLoader
      ? getNoLoader()
      : useDefaultLoader
      ? getDefaultImageLoader(context)
      : getMagnoliaFocalImageLoader(aspectRatio, context);
  }, [aspectRatio, context, useDefaultLoader, noLoader]);

  const serverSideLoader =
    serverSideContext !== undefined
      ? noLoader
        ? getNoLoader()
        : useDefaultLoader
        ? getDefaultImageLoader(serverSideContext)
        : getMagnoliaFocalImageLoader(aspectRatio, serverSideContext)
      : undefined;

  return serverSideContext !== undefined || isClient() ? (
    <NextImage {...imageProps} ref={ref} loader={serverSideLoader || loader} />
  ) : (
    <></>
  );
};

export default Image;
