'use client';

import React, { FC, useMemo } from 'react';
import {
  ApiProductsCacheData,
  ApiProductsCacheResponse,
  ApiProductsCacheResponseVariants
} from '@ibe/api';
import { VARIANT_QUERY_PROP } from '@/Util/globals';
import { ApiProduct } from '../../../api/model';
import { getCheapestProduct } from '@/components/Search/helpersProduct';
import ResultsListItemCalendarContent from '@/components/SearchForResults/ResultsListItemCalendarContent';
import { Props } from '@/types/cms/magnolia';
import { getVariantParam } from '@/components/ProductsDatesPrices/getVariantParam';

const ResultsListItemCalendar: FC<{
  product: ApiProductsCacheResponse;
  productPageBasePath: string;
  pageProps?: Props;
}> = ({ product, productPageBasePath, pageProps }): JSX.Element => {
  const {
    teaserImage,
    productTitle,
    displayUrl,
    products,
    variants,
    travelTypes,
    review
  } = product;

  const [cheapestProduct, matchingVariant] = useMemo((): [
    ApiProductsCacheData | undefined,
    ApiProductsCacheResponseVariants | undefined
  ] => {
    const foundProduct = getCheapestProduct(product);
    const foundVariant: ApiProductsCacheResponseVariants | undefined = variants?.find(
      variant => !!foundProduct && foundProduct.packageCode === variant.packageCode
    );
    return [foundProduct, foundVariant];
  }, [product, products]);

  const hasAvailableProduct = useMemo((): boolean | undefined => {
    return products?.some(product => (product.availability || 0) > 0);
  }, [product, products]);

  const productDetailsLink = useMemo((): string => {
    if (!!productPageBasePath && !!displayUrl && (cheapestProduct?.availability || 0) > 0) {
      const productVariants = variants?.map(cacheVariant => {
        return {
          name: cacheVariant.packageCode
        } as ApiProduct;
      });
      const variantParam = getVariantParam(
        cheapestProduct?.travelStartDate,
        productVariants,
        cheapestProduct?.packageCode,
        product.packageCode
      );
      return `/${productPageBasePath}/${displayUrl}${
        !!variantParam ? `?${VARIANT_QUERY_PROP}=${variantParam}` : ''
      }`;
    } else {
      return '#';
    }
  }, [productPageBasePath, displayUrl, cheapestProduct, variants, product]);

  return (
    <ResultsListItemCalendarContent
      productDetailsLink={productDetailsLink}
      hasAvailableProduct={hasAvailableProduct}
      cheapestProduct={cheapestProduct}
      teaserImage={matchingVariant?.teaserImage || teaserImage}
      review={matchingVariant?.review || review}
      travelTypes={matchingVariant?.travelTypes || travelTypes}
      name={matchingVariant?.productTitle || productTitle}
      pageProps={pageProps}
    />
  );
};

export default ResultsListItemCalendar;
