import { apis, logger } from '@/Util/globals';
import { ApiService, AppServiceServer } from '@ibe/services';
import {
  Configuration as ModelApiConfiguration,
  Middleware as ModelApiMiddleware,
  RequestContext as ModelApiRequestContext,
  ModelApi
} from '../../api/model';
import {
  FetchParams,
  Middleware as IbeApiMiddleware,
  RequestContext as IbeApiRequestContext
} from '@ibe/api';
import { fallbackCurrency, languageMapping } from '@/app/i18n/settings';
import { useGlobalMGLProps } from '@/Util/GlobalMGLPropsContext';

const config = {
  basePath: apis.base,
  fetchApi: fetch,
  username: process.env.MAGNOLIA_AUTH_USER,
  password: process.env.MAGNOLIA_AUTH_PASSWORD,
  middleware: [
    new (class implements ModelApiMiddleware {
      async pre(context: ModelApiRequestContext) {
        logger('debug')(`Fetching from model API with url PRE: ${context.url}`);
        return { ...context, init: { ...context.init, cache: 'no-store' as RequestCache } };
      }
      async post(context: ModelApiRequestContext) {
        logger('debug')(`Fetching from model API with url POST: ${context.url}`);
      }
    })()
  ]
};
export const modelApi = new ModelApi(new ModelApiConfiguration(config));

export const useModelApiClient = (): ModelApi => {
  const { magnoliaHost, magnoliaApiBase, isProduction } = useGlobalMGLProps() || {};
  return new ModelApi(
    new ModelApiConfiguration({
      basePath: `${isProduction ? magnoliaHost : ''}${magnoliaApiBase}`
    })
  );
};

export const getIbeApiServer = (locale: string, tenant?: string): ApiService => {
  return AppServiceServer.getInstance(
    locale,
    process.env.IBE_API_URL,
    tenant,
    languageMapping[locale]?.currency || fallbackCurrency
  ).api.withMiddleware(
    new (class implements IbeApiMiddleware {
      async pre(context: IbeApiRequestContext): Promise<void | FetchParams> {
        logger('debug')(
          `Fetching from IBE API with url PRE: ${context.url}, full: ${JSON.stringify(
            context.init
          )}`
        );
      }
      async post(context: ModelApiRequestContext) {
        logger('debug')(`Fetching from IBE API with url POST: ${context.url}`);
      }
    })()
  );
};

const api = {
  model: modelApi
};

export default api;
