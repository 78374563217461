'use client';

import React, { ReactElement } from 'react';
import { useTranslation } from '@/app/i18n/client';
import { ApiCity } from '../../../api/model';
import Intro from '@/components/intro/Intro';
import Keys from '@/Translations/generated/da/city.json.keys';
import { THEMES } from '@/Util/globals';
import ImageGallery from '@/components/imageGallery/ImageGallery';
import sanitize from '@/Util/sanitize';
import Breadcrumb from '@/components/breadcrumb/Breadcrumb';
import { useGlobalMGLProps } from '@/Util/GlobalMGLPropsContext';
import { usePathname } from 'next/navigation';
import { Props } from '@/types/cms/magnolia';

const CityImages = ({
  city,
  breadcrumb,
  pageProps
}: {
  city?: ApiCity;
  breadcrumb?: ReactElement;
  pageProps?: Props;
}): ReactElement => {
  const {
    name,
    introTitleImages,
    introTextImages,
    imageGallery,
    themeIntroMedia,
    themeImageGallery
  } = city || {};
  const { t } = useTranslation('city');

  return (
    <>
      <Intro
        headline={introTitleImages ? introTitleImages : `${t(Keys.headlineImages)} ${name}`}
        breadcrumb={breadcrumb}
        singleColumn={
          <div>
            {!!introTextImages && (
              <div
                dangerouslySetInnerHTML={{
                  __html: sanitize(introTextImages, true)
                }}
              />
            )}
          </div>
        }
        theme={themeIntroMedia || THEMES.blue}
      />
      {!!imageGallery && (
        <ImageGallery
          theme={themeImageGallery || THEMES.darkGreen}
          images={imageGallery}
          pageProps={pageProps}
        />
      )}
    </>
  );
};

export default CityImages;
