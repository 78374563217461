import React, { FC, JSX, useEffect, useState } from 'react';
import { SearchProps } from '@/components/Search/SearchDesktopOverlay';
// import { useTranslation } from '@/app/i18n/client';
// import Keys from '@/Translations/generated/da/search.json.keys';
import classNames from 'classnames';
import ResultsNavItem from '@/components/Search/ResultsNavItem';
import { Action } from '@/components/Search/useCheckboxStateReducer';
import RadioResults from '@/components/Search/RadioResults';
import { useTransition } from 'react-transition-state';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { facCaretRightThin, facLocationMarker } from '@/Theme/SVG/Icons';
import { ApiContinentComponent, ApiCountryComponent } from '@ibe/api';
import { continentsWithCountries } from '@/components/Search/CountriesSearchTab';

export const numberOfProductsPerContinent = (continent?: ApiContinentComponent) => {
  return (
    continent?.countries?.reduce((total, current) => {
      return total + (current.products || 0);
    }, 0) || 0
  );
};

const CountriesSearchMobile: FC<
  Pick<
    SearchProps,
    | 'continents'
    | 'checkboxState'
    | 'getTravelTypesResults'
    | 'getDatesResults'
    | 'checkboxDispatch'
    | 'isLoading'
  > & {
    productsTotalCountry: number;
  }
> = ({
  continents,
  checkboxState,
  checkboxDispatch,
  getTravelTypesResults,
  getDatesResults,
  isLoading
}): JSX.Element => {
  // const { t } = useTranslation('search');

  const [selectedContinent, setSelectedContinent] = useState<ApiContinentComponent | undefined>(
    undefined
  );
  const [
    { status: statusCountries, isMounted: isMountedCountries },
    toggleCountries
  ] = useTransition({
    timeout: 300,
    mountOnEnter: true,
    unmountOnExit: true,
    preEnter: true
  });

  useEffect(() => {
    toggleCountries(!!selectedContinent);
  }, [selectedContinent]);

  const handleContinentClick = (continent: ApiContinentComponent): void => {
    setSelectedContinent(continent);
  };

  // const checkAllCountries = async (): Promise<void> => {
  //   checkboxDispatch({
  //     type: Action.ADD_OR_REMOVE_ALL_PER_TYPE,
  //     payload: {
  //       id: selectedContinent?.id || '',
  //       type: 'continent',
  //       name: selectedContinent?.name || '',
  //       items:
  //         selectedContinent?.countries
  //           ?.filter(country => !!country?.products && country.products > 0)
  //           .map(country => ({
  //             id: country.id || '',
  //             name: country.name
  //           })) || [],
  //       callbacks: [getTravelTypesResults, getDatesResults]
  //     }
  //   });
  // };

  const checkSingleCountry = async (country: ApiCountryComponent): Promise<void> => {
    checkboxDispatch({
      type: Action.ADD_OR_REMOVE_SINGLE,
      payload: {
        id: country?.continent || '',
        type: 'continent',
        name: selectedContinent?.name || '',
        items: [{ id: country?.id || '', name: country.name }],
        callbacks: [getTravelTypesResults, getDatesResults]
      }
    });
  };

  const backToContinents = (): void => {
    setSelectedContinent(undefined);
  };

  return (
    <div className="search__content__mobile search__content__mobile--slide">
      <div
        className={classNames('search__content__mobile__inner', {
          'search__content__mobile__inner--transformed': !!selectedContinent
        })}
      >
        <div>
          {continentsWithCountries(continents).map(continent => (
            <ResultsNavItem
              key={continent.id}
              label={continent.name || ''}
              amount={numberOfProductsPerContinent(continent)}
              onClick={(): void => handleContinentClick(continent)}
            />
          ))}
        </div>
        <div>
          {isMountedCountries && (
            <div
              className={classNames('search__content__mobile__element', {
                'search__content__mobile__element--hidden':
                  statusCountries === 'preEnter' || statusCountries === 'exiting'
              })}
            >
              <div className="search__content__mobile__back" onClick={backToContinents}>
                <div>
                  <FontAwesomeIcon icon={facLocationMarker} />
                  <div>{selectedContinent?.name}</div>
                </div>
                <FontAwesomeIcon icon={facCaretRightThin} />
              </div>
              {/* maybe later...*/}
              {/*<RadioResults*/}
              {/*  key="all"*/}
              {/*  label={t(Keys.all)}*/}
              {/*  checked={*/}
              {/*    Object.keys(checkboxState.items[selectedContinent?.id || '']?.countries || {})*/}
              {/*      ?.length ===*/}
              {/*    selectedContinent?.countries?.filter(country => (country?.products || 0) > 0)*/}
              {/*      ?.length*/}
              {/*  }*/}
              {/*  onChange={checkAllCountries}*/}
              {/*  results={numberOfProductsPerContinent(selectedContinent)}*/}
              {/*  isLoading={isLoading}*/}
              {/*/>*/}
              {selectedContinent?.countries
                ?.filter(country => (country.products || 0) > 0)
                ?.map(country => (
                  <RadioResults
                    key={country.id}
                    label={country.name}
                    checked={
                      !!country?.id &&
                      !!country?.continent &&
                      !!checkboxState.items[country.continent]?.countries?.[country.id]
                    }
                    onChange={(): Promise<void> => checkSingleCountry(country)}
                    results={country.products || 0}
                    isLoading={isLoading}
                  />
                ))}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default CountriesSearchMobile;
