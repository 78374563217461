'use client';

import React, { FC, JSX, useState } from 'react';
import SearchInner from '@/components/Search/SearchInner';
import {
  ApiContentSearchRequestFromJSON,
  ApiContinentComponent,
  ApiCountriesSearchResponse,
  ApiDatesSearchResponse,
  ApiDatesSearchResponseDates,
  ApiProductsCacheResponse,
  ApiTravelTypeComponent,
  ApiTravelTypesSearchResponse
} from '@ibe/api';
import { logger } from '@/Util/globals';
import { useGlobalMGLProps } from '@/Util/GlobalMGLPropsContext';
import { useMount } from 'react-use';
import { useApi } from '@ibe/components';
import { useModelApiClient } from '@/Util/api';
import CustomSpinner from '@/components/CustomSpinner/CustomSpinner';
import classNames from 'classnames';
import { Props } from '@/types/cms/magnolia';

const Search: FC<{ isInitialSearch?: boolean; pageProps?: Props }> = ({
  isInitialSearch,
  pageProps
}): JSX.Element => {
  const api = useApi();
  const modelApiClient = useModelApiClient();
  const { siteConfig, rootNodePath = '', magnoliaContext } = useGlobalMGLProps() || {};
  const [continents, setContinents] = useState<ApiContinentComponent[]>([]);
  const [travelTypes, setTravelTypes] = useState<ApiTravelTypeComponent[]>([]);
  const [dates, setDates] = useState<ApiDatesSearchResponseDates[]>([]);
  const [productPackages, setProductPackages] = useState<ApiProductsCacheResponse[]>([]);
  const [initialProductsTotalCountry, setInitialProductsTotalCountry] = useState<number>(0);
  const [initialProductsTotalTravelType, setInitialProductsTotalTravelType] = useState<number>(0);
  const [initialProductsTotalOverall, setInitialProductsTotalOverall] = useState<number>(0);
  const [totalNumberOfProducts, setTotalNumberOfProducts] = useState<number>(0);

  useMount(
    async (): Promise<void> => {
      try {
        const promises: [
          Promise<ApiCountriesSearchResponse | undefined>,
          Promise<ApiTravelTypesSearchResponse | undefined>,
          Promise<number | undefined>,
          Promise<ApiDatesSearchResponse> | undefined
        ] = [new Promise(() => {}), new Promise(() => {}), new Promise(() => {}), undefined];

        promises[0] = api.searchCountries(
          ApiContentSearchRequestFromJSON({
            withProducts: false
          })
        );

        promises[1] = api.searchTravelTypes(
          ApiContentSearchRequestFromJSON({
            withProducts: false
          })
        );

        promises[2] = modelApiClient.getProductsCount();

        promises[3] = api.searchDates(
          ApiContentSearchRequestFromJSON({
            withProducts: !isInitialSearch
          })
        );

        const [
          countriesResponse,
          travelTypesResponse,
          totalNumberOfProductResponse,
          datesResponse
        ] = await Promise.all(promises);
        if (!!totalNumberOfProductResponse) {
          setTotalNumberOfProducts(totalNumberOfProductResponse);
        }

        setContinents(
          countriesResponse?.continents?.filter(
            continent => !!continent?.countries && continent.countries.length > 0
          ) || []
        );
        setTravelTypes(travelTypesResponse?.travelTypes || []);
        setDates(datesResponse?.dates?.filter(Boolean) || []);

        setInitialProductsTotalCountry(countriesResponse?.productsTotal || 0);
        setInitialProductsTotalTravelType(travelTypesResponse?.productsTotal || 0);
        setInitialProductsTotalOverall(
          countriesResponse?.productsTotalFiltered ||
            travelTypesResponse?.productsTotalFiltered ||
            datesResponse?.productsTotalFiltered ||
            0
        );

        setProductPackages(datesResponse?.products || []);
      } catch (err) {
        logger('error')('Unable to fetch search data: ', err);
      }
    }
  );

  return continents.length > 0 ||
    travelTypes.length > 0 ||
    dates.length > 0 ||
    productPackages.length > 0 ? (
    <SearchInner
      initialData={{
        continents,
        travelTypes,
        dates,
        productPackages,
        initialProductsTotalCountry,
        initialProductsTotalTravelType,
        initialProductsTotalOverall
      }}
      siteConfig={siteConfig}
      totalNumberOfProducts={totalNumberOfProducts}
      rootNodePath={rootNodePath}
      isInitialSearch={isInitialSearch}
      pageProps={pageProps}
    />
  ) : (
    <div
      className={classNames('search__custom-spinner', {
        search__container: isInitialSearch,
        'search-for-results': !isInitialSearch
      })}
    >
      <CustomSpinner />
    </div>
  );
};

export default Search;
