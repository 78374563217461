'use client';

import React, { Fragment, useState } from 'react';
import { Button, Collapse } from 'reactstrap';
import styles from './LeftNavi.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import Link from 'next/link';
import { getNextLinkAttrs, SwitchableLinkProps } from '../linkList/Link';
import { useGlobalMGLProps } from '@/Util/GlobalMGLPropsContext';

type Links = `links${number}`;
type SubLinks = `subLinks${number}`;
type LinkType = SwitchableLinkProps;
type Link = LinkType & { subLinks: { [key in SubLinks]: LinkType } & { '@nodes': string[] } };
type Props = {
  links: {
    [key in Links]: Link;
  } & { '@nodes': string[] };
};

const LeftNavi = (props: Props): JSX.Element => {
  const { links } = props;
  const linkCount = links['@nodes'].length;
  const [openCollapses, setOpenCollapses] = useState<Record<string, boolean>>({});

  const globalProps = useGlobalMGLProps();

  const toggleCollapse = (id: string): void => {
    setOpenCollapses(value => ({ ...value, [id]: !value[id] }));
  };
  return !!linkCount && linkCount > 0 ? (
    <div>
      {[...Array(linkCount).keys()].map(key => {
        const link = links[`links${key}` as keyof Props['links']] as Link;
        const linkAttrsParent = getNextLinkAttrs(link, globalProps);

        if (!!link) {
          const subLinksCount = (link?.subLinks?.['@nodes'] || []).length;
          return !!subLinksCount && subLinksCount > 0 ? (
            <div className={styles.leftNavi__container} key={link['@id']}>
              <Button
                className={styles.leftNavi__button}
                onClick={(): void => toggleCollapse(link['@id'])}
              >
                <span>{link.title}</span>
                <FontAwesomeIcon
                  className={`${styles.leftNavi__chevron} ${
                    openCollapses[link['@id']] ? styles.leftNavi__chevronUp : ''
                  }`}
                  icon={faChevronDown}
                />
              </Button>
              <Collapse isOpen={openCollapses[link['@id']]}>
                {[...Array(subLinksCount).keys()].map(subKey => {
                  const subLink = link.subLinks[`subLinks${subKey}` as SubLinks] as LinkType;
                  const linkAttrs = getNextLinkAttrs(subLink, globalProps);
                  return (
                    <div key={subLink['@id']}>
                      <Link className={styles.leftNavi__link} {...linkAttrs} prefetch={false}>
                        {subLink.title}
                      </Link>
                    </div>
                  );
                })}
              </Collapse>
            </div>
          ) : (
            <div className={styles.leftNavi__container} key={link['@id']}>
              <Link className={styles.leftNavi__link} {...linkAttrsParent} prefetch={false}>
                {link.title}
              </Link>
            </div>
          );
        } else {
          return <Fragment key={key} />;
        }
      })}
    </div>
  ) : (
    <></>
  );
};

export default LeftNavi;
